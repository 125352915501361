import React from "react";
import { BUTTON_TEXT, STRING_CONSTANTS } from "../../../utils/StringConstant";
import Button from "../../../global/Button";

interface DraftPopUpProps {
  onClose: any;
  discard?: any;
  draft: any;
  showDiscard?: any;
  [key: string]: any;
}

const ConfirmDraft = (props: DraftPopUpProps) => {
  const {
    onClose,
    discard,
    draft,
    showDiscard,
    title = STRING_CONSTANTS.save_listing_as_draft,
    btnTxt = BUTTON_TEXT.SAVE,
    published=false,
  } = props;

  return (
    <div className={"modal show"}>
      <div className="modal-section draft_popup">
        <div className="modal-top-part">
          <button className="close">
            <img
              src="/static/dismiss-cross-round-black.svg"
              alt=""
              onClick={onClose}
            />
          </button>
          <h5>{title}</h5>

          <div className="action_btns">
            {!published && (
              <Button
                btnTxt={btnTxt}
                btnImg="/static/savedraft.svg"
                onClick={() => draft()}
              />
            )}
            {showDiscard ? (
              <Button
                btnTxt={BUTTON_TEXT.DISCARD}
                btnImg="/static/discard.svg"
                onClick={() => discard()}
              />
            ) : null}
          </div>
        </div>
        <Button
          btnTxt={BUTTON_TEXT.CONTINUE_EDIT}
          className={"backtologin"}
          onClick={onClose}
        />
      </div>
    </div>
  );
};

export default ConfirmDraft;
