/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Button from "../../global/Button";
import { makeGetCall, makePostCall } from "../../utils/Requests";
import endpoints from "../../utils/endpoints";
import Toggle from "../../global/Toggle";
import SubscriptionPlanCard from "../../global/SubscriptionPlanCard";
import CommonLoader from "../../global/CommonLoader";
import CommonLoaderSubscription from "../../global/CommonLoaderSubscription";

import { getItemFromStore, showToast } from "../../utils";
import { useNavigate } from "react-router";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import checked from "../../assets/img/signupWelcome/checked.svg";
import bgLeft from "../../assets/img/backgroundLeft.svg";
import bgRight from "../../assets/img/backgroundRight.svg";
import backBtn from "../../assets/img/signupWelcome/backBtn.svg";
import IconF from "../../assets/img/signupWelcome/icon-f.svg";
import AddCardPopup from "../Dashboard/Popups/AddCardPopup";
import useCheckDeviceScreen from "../../hooks/useCheckScreen";
import WelcomeModal from "../Profile/WelcomeModal";
import { useApiRequest } from "../../hooks/useApiRequest";

const SubscriptionPlanSelector = ({
  isLoading,
  isExistingGuest = false,
}: any) => {
  const getUserProfile = useApiRequest();
  const [subscriptionType, setSubscriptionType] = useState([]);
  const [toggleOptions, setToggleOptions] = useState([]);
  const sessionPlan = sessionStorage.getItem("planType");
  const [currentSelection, setCurrentSelection] = useState(
    sessionPlan === "Monthly" ? "Monthly" : "Yearly"
  );
  const [activeSubscription, setActiveSubscription] = useState({});
  const [myCards, setMyCards] = useState([]);
  const [showAddCardPopup, setShowAddCardPopup] = useState(false);
  const [purchaseSubsLoading, setPurchaseSubsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isMonthSelection, setIsMonthSelection] = useState(false);
  const { isMobile } = useCheckDeviceScreen();
  const [showWelcomeMemberModal, setShowWelcomeMemberModal] = useState(false);
  const navigate = useNavigate();
  const userProfile = getItemFromStore("userProfile");
  const isProfileComplete = getItemFromStore("isProfileComplete");

  const handleToggle = () => {
    if (isMonthSelection) {
      setIsMonthSelection(false);
      setCurrentSelection("Yearly");
    } else {
      setIsMonthSelection(true);
      setCurrentSelection("Monthly");
    }
  };

  useEffect(() => {
    if (currentSelection === "Yearly") {
      setActiveSubscription(subscriptionType[0]);
    } else {
      setActiveSubscription(subscriptionType[1]);
    }
  }, [currentSelection, subscriptionType]);

  useEffect(() => {
    if (subscriptionType?.length) {
      const option = subscriptionType.map((type) => type.recurring.interval);
      setToggleOptions(option);
    }
  }, [subscriptionType]);

  useEffect(() => {
    const fetchSubscriptionData = () => {
      setLoading(true);
      makeGetCall({
        url: endpoints.get_subscription_types,
      })
        .then((res) => {
          setSubscriptionType(res.data.data);
          setLoading(false);
        })
        .catch((err) => {});
    };

    fetchSubscriptionData();
  }, []);

  useEffect(() => {
    makeGetCall({ url: endpoints.manage_card, params:{device_type:"web"} })
      .then((res) => {
        if (res.status.code === 200) {
          setMyCards(res.data);
        }
      })
      .catch((err) => {
        console.error(err, "error");
      });
  }, []);

  const handleContinue = async () => {
    const res = await getUserProfile();
    if (res) {
      navigate(ROUTE_CONSTANTS.select_member_type);
    }
  };

  const purchaseSubscription = (price_id, payment_id) => {
    const apiPayload = {
      price_id: price_id,
      payment_id: payment_id,
    };
    setPurchaseSubsLoading(true);
    makePostCall({ url: endpoints.subscription, apiPayload })
      .then((res) => {
        if (res.status.code === 200) {
          sessionStorage.removeItem("planType");
          setTimeout(() => {
            showToast(res?.status?.message, "success");
            if (isProfileComplete) {
              navigate(ROUTE_CONSTANTS.dashboard);
            } else {
              setShowWelcomeMemberModal(true);
            }
          }, 1000);
          setPurchaseSubsLoading(false);
        } else {
          setPurchaseSubsLoading(false);
        }
      })
      .catch((err) => {
        showToast(err, "error");
        setPurchaseSubsLoading(false);
      });
  };

  // useEffect(() => {
  //   if (myCards.length !== 0 && activeSubscription?.id && myCards?.[0]?.id) {
  //     if (!userProfile?.userType || userProfile?.userType === "Guest") {
  //       purchaseSubscription(activeSubscription?.id, myCards?.[0]?.id);
  //     }
  //   }
  // }, [myCards, activeSubscription?.id, myCards?.[0]?.id]);

  const checkIsSubsPurchased = () => {
    if (userProfile) {
      if (userProfile.userType === "Guest") {
        return false;
      } else {
        return true;
      }
    }
    const userData = getItemFromStore("userData");
    if (userData) {
      if (userData?.userType === "Guest") {
        return false;
      } else {
        return true;
      }
    }
    return false;
  };

  const features = [
    "365 days plan",
    "Create Unlimited Groups Activities",
    "Generate Fitness Activity for Revenue",
    "Flexible Scheduling and Booking",
    "Access to Fitness mates Events and Promotions",
    "Exclusive Membership Discounts",
  ];
  const featuresMonthly = [
    "30 days plan",
    "Create Unlimited Groups Activities",
    "Generate Fitness Activity for Revenue",
    "Flexible Scheduling and Booking",
    "Access to Fitness mates Events and Promotions",
    "Exclusive Membership Discounts",
  ];

  const FeatureList = () => (
    <div className="d-flex flex-column gap-1 align-items-start">
      {currentSelection == "Yearly"
        ? features.map((feature, index) => (
            <div
              key={index}
              className="feature-item d-flex align-items-center gap-1"
            >
              <img
                src={checked}
                alt="Yes"
                style={{ height: "20px", width: "20px" }}
              />{" "}
              {feature}
            </div>
          ))
        : featuresMonthly.map((feature, index) => (
            <div
              key={index}
              className="feature-item d-flex align-items-center gap-1"
            >
              <img
                src={checked}
                alt="Yes"
                style={{ height: "20px", width: "20px" }}
              />{" "}
              {feature}
            </div>
          ))}
    </div>
  );

  return (
    <div className="user_type_wrapper">
      <img src={bgLeft} alt="" className="background_left" />
      <img src={bgRight} alt="" className="background_right" />
      <div className="user_container">
        <div className="user_content">
          <img
            src={backBtn}
            alt=""
            className="back_btn"
            onClick={() => navigate(-1)}
            style={isExistingGuest ? { marginLeft: "45px" } : {}}
          />
          {/* heading */}
          <div className="d-flex justify-content-center flex-column">
            {/* <div className="main_heading">Membership Plan</div> */}
            <div className="main_heading">
              <img src={IconF} />
            </div>
            <div
              className="main_heading"
              style={{
                fontSize: "32px",
                fontFamily: "Poppins",
                fontWeight: "600",
                lineHeight: "48px",
                color: "#25272D",
              }}
            >
              Choose your plan
            </div>
            <div
              className="main_sub_heading special"
              style={{
                fontSize: "16px",
                fontFamily: "Poppins",
                marginBottom: "18px",
              }}
            >
              Unlock Exclusive Features and Enhance Your Experience! Subscribe
              Now for Unlimited Benefits.
            </div>
            {
              !userProfile?.hasUsedFreeTrial && 
                <div
                  className="free-trial-heading"
                  // style={{ fontSize: "20px", fontFamily: "Poppins",  }}
                >
                  All membership plans starts with 90 days free trial period.
                </div>
            }
          </div>

          <div className="plans_container">
            <div className="plans_wrapper">
              <div
                className="center-container"
                style={{
                  flexDirection: "row",
                  gap: isMobile ? "10px" : "22px",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{
                    padding: isMobile ? "10px" : "20px 16px",
                    height: isMobile ? "70px" : "118px",
                    width: isMobile ? "160px" : "258px",
                    backgroundColor: isMonthSelection ? "#FEEFE7" : "#FFFFFF",
                    border: `2px solid ${
                      isMonthSelection ? "#EE7830" : "#6C6D6F"
                    }`,
                    display: "flex",
                    gap: "5px",
                    borderRadius: "14px",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => handleToggle()}
                >
                  <div
                    style={{
                      fontSize: isMobile ? "16px" : "20px",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      lineHeight: "30px",
                      color: "#626262",
                    }}
                  >
                    Monthly
                  </div>
                  <div>
                    <span
                      style={{
                        fontSize: isMobile ? "16px" : "20px",
                        fontFamily: "Poppins",
                        fontWeight: "700",
                        lineHeight: "30px",
                        color: "#EE7830",
                      }}
                    >
                      AUD 7.99/
                    </span>
                    <span
                      style={{
                        fontSize: isMobile ? "16px" : "10px",
                        fontFamily: "Poppins",
                        fontWeight: "700",
                        lineHeight: "18px",
                        color: "#EE7830",
                      }}
                    >
                      month
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    padding: isMobile ? "10px" : "20px 16px",
                    height: isMobile ? "70px" : "118px",
                    width: isMobile ? "160px" : "258px",
                    backgroundColor: !isMonthSelection ? "#FEEFE7" : "#FFFFFF",
                    border: `2px solid ${
                      !isMonthSelection ? "#EE7830" : "#6C6D6F"
                    }`,
                    display: "flex",
                    gap: "5px",
                    borderRadius: "14px",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => handleToggle()}
                >
                  <div
                    style={{
                      fontSize: isMobile ? "16px" : "20px",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      lineHeight: "30px",
                      color: "#626262",
                    }}
                  >
                    Annually
                  </div>
                  <div>
                    <span
                      style={{
                        fontSize: isMobile ? "16px" : "20px",
                        fontFamily: "Poppins",
                        fontWeight: "700",
                        lineHeight: "30px",
                        color: "#EE7830",
                      }}
                    >
                      AUD 80/
                    </span>
                    <span
                      style={{
                        fontSize: isMobile ? "16px" : "10px",
                        fontFamily: "Poppins",
                        fontWeight: "700",
                        lineHeight: "18px",
                        color: "#EE7830",
                      }}
                    >
                      year
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="center-container">
                <Toggle
                  currentSelection={currentSelection}
                  onClick={(value: any) => {
                    // alert(value);
                    console.log("value", value)
                    setCurrentSelection(value)
                  }}
                  option2={
                    toggleOptions[0] === "year" && (
                      <span>
                        Yearly{" "}
                        {activeSubscription?.metadata?.discount && (
                          <span
                            style={{
                              color: "#EE7830",
                              fontSize: "10px",
                              fontWeight: "600",
                              lineHeight: "24px",
                            }}
                          >
                            {`${activeSubscription?.metadata?.discount || 0
                              }% Off`}
                          </span>
                        )}
                      </span>
                    )
                  }
                  option1={toggleOptions[1] === "month" && "Monthly"}
                />
              </div> */}

              {/* <SubscriptionPlanCard
                currentSelection={currentSelection}
                activeSubscription={activeSubscription}
              /> */}

              <div
                style={{
                  border: "1px solid #DCDCDC",
                  position: "relative",
                  borderRadius: "12px",
                  margin: "40px 9px 0px 9px",
                  padding: "23px",
                }}
              >
                <div className="feature_heading">Benefits Included</div>
                <FeatureList />
              </div>

              {loading && <CommonLoaderSubscription />}
              {showAddCardPopup && (
                <AddCardPopup
                  show={showAddCardPopup}
                  onHide={() => setShowAddCardPopup(false)}
                  planType={currentSelection}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          zIndex: "6",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          width: "100%",
          position: "sticky",
          bottom: "8px",
          boxShadow: "0px 0px 4px 0px #00000040",
        }}
      >
        <div
          // className="center-container"
          style={{
            maxWidth: "831px",
            paddingBottom: "14px",
            paddingTop: "17px",
          }}
        >
          <div
            // className="gray-note"
            style={{
              marginTop: "14px",
              background: "#fff",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "12px",
              fontWeight: "500",
              lineHeight: "18px",
              color: "#6C6D6F",
            }}
          >
            <span style={{ maxWidth: "813px" }}>
              Your subscription will automatically renew from your added card
              unless canceled at least 24 hours before the end of the current
              period. You will not be able to cancel the subscription once
              activated. Manage your subscriptions in Account Settings after
              purchase. Check{" "}
              <b
                className="cursor-pointer"
                onClick={() => {
                  window.open("/policies");
                }}
              >
                Terms & Conditions
              </b>{" "}
              and{" "}
              <b
                className="cursor-pointer"
                onClick={() => {
                  window.open("/policies");
                }}
              >
                Privacy Policy.
              </b>
            </span>
          </div>
          {myCards?.length !== 0 && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                btnTxt={
                  checkIsSubsPurchased()
                    ? "Subscription Purchased Successfully"
                    : userProfile?.hasUsedFreeTrial ? "Re-subscribe" : "Start free trial"
                }
                className="free-trial-btn"
                onClick={() => {
                  if (checkIsSubsPurchased()) {
                    navigate(ROUTE_CONSTANTS.select_member_type);
                  } else {
                    purchaseSubscription(
                      activeSubscription?.id,
                      myCards?.[0]?.id
                    );
                  }
                }}
                isLoading={purchaseSubsLoading}
                disabled={myCards?.length === 0}
              />
            </div>
          )}
          {myCards?.length === 0 && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                btnTxt={userProfile?.hasUsedFreeTrial ? "Re-subscribe" : "Start free trial"}
                className={"btn-member mx-auto mt-4 free-trial-btn"}
                onClick={() => {
                  setShowAddCardPopup(true);
                }}
              />
            </div>
          )}
          {/* <div
                  className="gray-note"
                  style={{ marginTop: "14px", background: "#fff" }}
                >
                  Note:{" "}
                  <span>
                    Select the plan according to your preference, as it will be
                    changed once selected.
                  </span>
                </div> */}
        </div>
      </div>
      {showWelcomeMemberModal && (
        <WelcomeModal handleContinue={handleContinue} />
      )}
    </div>
  );
};

export default SubscriptionPlanSelector;
