import {Skeleton} from "@mui/material";
import useCheckDeviceScreen from "../../../hooks/useCheckScreen";

const NotificationShimmer = () => {
  const {isMobile} = useCheckDeviceScreen();

  return (
    <div style={{display: "flex", flexDirection: "column",}}>
      <Skeleton
        animation="wave"
        variant="rounded"
        width={"100%"}
        height={70}
        sx={{borderRadius: "12px"}}
      />
    </div>
  );
};

export default NotificationShimmer;
