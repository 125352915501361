/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Button from "../../global/Button";
import SessionPopup from "./Popups/SessionPopup";
import UpdateDetailsPopup from "./Popups/UpdateDetailsPopup";
import AddCardPopup from "./Popups/AddCardPopup";
import GlobalHeader from "./GlobalHeader";
import { makeGetCall, makePostCall, makePatchCall } from "../../utils/Requests";
import endpoints from "../../utils/endpoints";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { getItemFromStore, handleVideoClick, showToast } from "../../utils";
import ApiLoader from "../../global/ApiLoader";
import {
  BlackPlusIcon,
  ContactIcon,
  DateMinus,
  DatePlus,
  EditSessionIcon,
  GroupPeopleIcon,
  IFeeIcon,
  LeftArrowStickIcon,
  LocationGreyIcon,
  PayCardIcon,
  PencilEditIcon,
  PeopleCommunityIconH,
  RightArrowIcon,
  RightArrowStickIcon,
  SessionPackageIcon,
  SpClockIcon,
  SpDateIcon,
  TriangleErrorIcon,
  VisaCardImg,
} from "../../utils/Icons/svgs";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import BookingErrorPopup from "./Popups/BookingErrorPopup";
import PaymentSuccessfullPopup from "./Popups/PaymentSuccessfullPopup";
import { LISTING_TYPE } from "../../types";
import useCheckDeviceScreen from "../../hooks/useCheckScreen";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";

const TooltipWrapper = ({ children, tooltip }) => {
  const { isMobile } = useCheckDeviceScreen();

  return (
    <OverlayTrigger
      overlay={<Tooltip>{tooltip}</Tooltip>}
      placement="right"
      trigger={isMobile ? "click" : "hover"}
    >
      <div>{children}</div>
    </OverlayTrigger>
  );
};

const BookingEvent = () => {
  const [selectedPay, setSelecetedPay] = useState<any>("credit_card");
  const [showSessionModal, setShowSessionModal] = useState(false);
  const [showUpdateDetailsModal, setShowUpdateDetailsModal] = useState(false);
  const [showAddCardPopup, setShowAddCardPopup] = useState(false);
  const [detailsData, setDetailsData] = useState<any>();
  const [myCards, setMyCards] = useState([]);
  const [reqGender, setReqGender] = useState("");
  const [reqAge, setReqAge] = useState<any>();
  const [counter, setCounter] = useState(1);
  const [paymentData, setPaymentData] = useState<any>();
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [selectedEvents, setSelectedEvents] = useState<any>();
  const [ageError, setAgeError] = useState(null);
  const [showBookingError, setShowBookingError] = useState(false);
  const [payLoading, setPayLoading] = useState(false);
  const navigate = useNavigate();
  const { event_id, booking_id } = useParams();
  const [profileDetails, setProfileDetails] = useState({
    name: null,
    email: null,
    contact: "",
    age: null,
    gender: null,
  });
  const [packageCounter, setPackageCounter] = useState(1);
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let AllEventsData = [];
  let sessionEventsData = [];

  if (selectedEvents && detailsData?.events) {
    for (let k = 0; k < detailsData?.events?.length; k++) {
      for (let l = 0; l < selectedEvents?.length; l++) {
        if (detailsData?.events[k]?.id === selectedEvents[l]?.event) {
          AllEventsData.push({
            ...detailsData?.events[k],
            countEvent: selectedEvents[l].slots,
          });
        }
      }
    }

    sessionEventsData = detailsData?.events?.slice();
  }

  let totalSlots = 0;
  if (selectedEvents) {
    for (let m = 0; m < selectedEvents?.length; m++) {
      totalSlots += Number(selectedEvents?.[m]?.slots);
    }
  }

  const handleCards = (e) => {
    setSelecetedPay(e.target.value);
  };

  const renderCardImage = (card) => {
    if (card === "visa") {
      return <VisaCardImg />;
    } else {
      return (
        <div
          style={{
            width: "auto",
            height: "24px",
            border: "1px solid #f8f9fa",
            color: "rgb(30, 49, 136)",
            padding: "2px",
            fontWeight: "bold",
          }}
        >
          {card}
        </div>
      );
    }
  };

  const handlePayment = () => {
    let apiPayload = {};

    //Validation for Age//
    if (reqAge) {
      if (detailsData?.minAge) {
        if (detailsData?.minAge > reqAge) {
          setAgeError(
            "people in the age group of " +
            detailsData?.minAge +
            " to " +
            detailsData?.maxAge +
            " years"
          );
          setShowBookingError(true);
          return;
        }
      }

      if (detailsData?.maxAge) {
        if (detailsData?.maxAge < reqAge) {
          setAgeError(
            "people in the age group of " +
            detailsData?.minAge +
            " to " +
            detailsData?.maxAge +
            " years"
          );
          setShowBookingError(true);
          return;
        }
      }
    } else {
      showToast("Please enter your age.", "error");
      return;
    }

    //Validation for gender//

    if (reqGender) {
      if (reqGender) {
        if (!detailsData?.gender.includes(reqGender)) {
          setShowBookingError(true);
          return;
        }
      }
    } else {
      showToast("Please specify your gender.", "error");
      return;
    }

    ///pay load for post api//
    if (
      detailsData?.listingType === LISTING_TYPE.S ||
      detailsData?.listingType === LISTING_TYPE.SPECIAL
    ) {
      const sel = selectedEvents?.map((obj) => {
        return { event_id: obj.event, slots: obj.slots };
      });

      apiPayload = {
        name: profileDetails?.name,
        email: profileDetails?.email,

        events: sel,
      };
    } else if (
      detailsData?.listingType === LISTING_TYPE.M ||
      detailsData?.listingType === LISTING_TYPE.C
    ) {
      apiPayload = {
        name: profileDetails?.name,
        email: profileDetails?.email,
        listing_slots: packageCounter,
      };
    }

    setPayLoading(true);

    makePostCall({
      url: endpoints.mylisting_new + `${event_id}/bookings/`,
      apiPayload,
    })
      .then((res) => {
        if (res.status.code === 200) {
          setShowPaymentPopup(true);
          setPayLoading(false);
        }
      })
      .catch((err) => {
        showToast(err, "error");
        setPayLoading(false);
      });
  };

  //payment-preview api //

  const handlePay = () => {
    //pay load for api//
    let slotsTotal;
    if (
      detailsData?.listingType === LISTING_TYPE.S ||
      detailsData?.listingType === LISTING_TYPE.SPECIAL
    ) {
      slotsTotal = totalSlots;
    } else if (
      detailsData?.listingType === LISTING_TYPE.M ||
      detailsData?.listingType === LISTING_TYPE.C
    ) {
      slotsTotal = packageCounter;
    }

    setPaymentLoading(true);

    makeGetCall({
      url:
        endpoints.mylisting_new +
        `${event_id}/payments-preview/?slots=${slotsTotal}`,
    })
      .then((res) => {
        if (res.status.code === 200) {
          setPaymentData(res.data);
        }
        setPaymentLoading(false);
      })
      .catch((err) => {
        showToast(err, "error");
        setPaymentLoading(false);
      });
  };

  /// managecard api ...

  useEffect(() => {
    makeGetCall({ url: endpoints.manage_card , params:{device_type:"web"}})
      .then((res) => {
        if (res.status.code === 200) {
          setMyCards(res.data);
        }
      })
      .catch(() => { });
  }, []);

  //intermediate booking api
  useEffect(() => {
    makeGetCall({ url: endpoints.listings_intermediate + `${booking_id}` })
      .then((res) => {
        if (res.status.code === 200) {
          setSelectedEvents(res.data.bookingData);
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  }, []);

  const changeCardDefault = (id) => {
    setIsLoading(true);
    makePatchCall({
      url: endpoints.manage_card,
      apiPayload: { source_id: id },
    })
      .then((res) => {
        if (res.status.code === 200) {
          setIsLoading(false);
          makeGetCall({ url: endpoints.manage_card, params:{device_type:"web"} })
            .then((res) => {
              if (res.status.code === 200) {
                setMyCards(res.data);
              }
            })
            .catch(() => { });
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  // mylistingnew details api  //

  useEffect(() => {
    makeGetCall({
      url: endpoints.mylisting_new + `${event_id}`,
    })
      .then((res) => {
        setDetailsData(res.data);
        const userProfileData = getItemFromStore("userProfile");
        setProfileDetails((prevData) => ({
          ...prevData,
          name: userProfileData.name,
          email: userProfileData.email,
          gender:
            userProfileData.gender === "M"
              ? "Male"
              : userProfileData.gender === "F"
                ? "Female"
                : "Prefer not to say",

          age: moment().diff(
            moment(userProfileData.dob, "YYYY-MM-DD"),
            "years"
          ),
          contact: userProfileData.mobileNumber,
        }));
        setReqAge(
          moment().diff(moment(userProfileData.dob, "YYYY-MM-DD"), "years")
        );
        setReqGender(userProfileData.gender);
      })
      .catch(() => { });
  }, [event_id]);

  ///payement api
  useEffect(() => {
    if (!detailsData?.price) {
      return;
    }

    if (
      detailsData?.listingType === LISTING_TYPE.S ||
      detailsData?.listingType === LISTING_TYPE.SPECIAL
    ) {
      if (selectedEvents) {
        handlePay();
      }
    } else if (
      detailsData?.listingType === LISTING_TYPE.M ||
      detailsData?.listingType === LISTING_TYPE.C
    ) {
      handlePay();
    }
  }, [selectedEvents, packageCounter, detailsData]);

  const handleMinusCounter = () => {
    if (packageCounter > 1) {
      setPackageCounter((prev) => prev - 1);
    }
  };
  const handlePlusCounter = () => {
    if (packageCounter < detailsData?.teamSlotsAvailable) {
      setPackageCounter((prev) => prev + 1);
    }
  };

  const radioStyle = {
    height: "20px", // Increase the radio button size
    accentColor: "black",
  };

  function isDisabled() {
    if (payLoading) {
      return true;
    }
    if (!detailsData?.price) {
      return false;
    }

    if (myCards.length < 1) {
      if (Number(detailsData?.price) === 0.0 || detailsData?.price === null) {
        return false;
      }
      return true;
    }

    return false;
  }

  return (
    <>
      {!selectedEvents && !detailsData ? (
        <div className="mt-5">
          <ApiLoader />
          <div
            className="h2 text-center mt-5"
            style={{ fontFamily: "Poppins" }}
          >
            Please wait system is processing your request
          </div>
        </div>
      ) : (
        <div className="container-fluid booking_event p-md-0">
          <div className="nav_bar bg-white container-fluid d-none d-md-block py-3 ">
            <div className="mx-auto nav_content position-relative">
              <Link to="/">
                <img src="/static/orange-logo.svg" alt="" />
              </Link>

              <span
                className="position-absolute cursor-pointer"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <LeftArrowStickIcon />
              </span>
            </div>
          </div>
          <div className="d-md-none">
            <GlobalHeader />
          </div>

          <div className="booking_container row">
            <div className="left_container col-12 col-md-5">
              <div className=" mob-heading d-md-none d-flex position-relative justify-content-center  my-3">
                <h3 className="text-center">Confirm and pay</h3>
                <span
                  className="position-absolute cursor-pointer"
                  onClick={() => navigate(-1)}
                >
                  <LeftArrowStickIcon />
                </span>
              </div>

              <div className="img_cont">
                {["mp4", "mov", "avi"].includes(
                  detailsData?.media?.split(".").splice(-1)[0].toLowerCase()
                ) ? (
                  <>
                    <video
                      style={{
                        borderRadius: "12px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                      src={detailsData?.media}
                      className="event_img"
                      autoPlay
                      playsInline
                      loop
                      muted
                      onClick={() => {
                        handleVideoClick(`bookinging_video${detailsData?.id}`);
                      }}
                      id={`booking_video${detailsData?.id}`}
                    >
                      <source src={detailsData?.media} />
                    </video>
                  </>
                ) : (
                  <img
                    src={detailsData?.media || "/static/expertise2.png"}
                    className="event_img"
                    alt=""
                  />
                )}
              </div>
              <h3 className="left_title my-3">{detailsData?.title}</h3>
              <p className="left_terms d-none d-md-block">
                By tapping below, I also agree to the
                <Link
                  style={{ cursor: "pointer", textDecoration: "none" }}
                  to="/policies"
                >
                  {" "}
                  <span>Terms of Service, Payments </span>
                </Link>
                and I acknowledge the{" "}
                <Link
                  style={{ cursor: "pointer", textDecoration: "none" }}
                  to="/policies"
                >
                  {" "}
                  <span>Privacy Policy.</span>
                </Link>
              </p>
              <div className="all-button d-none d-md-block my-4">
                <div className="all-button-top d-flex ">
                  {(detailsData?.listingType === LISTING_TYPE.S ||
                    detailsData?.listingType === LISTING_TYPE.SPECIAL) && (
                    <div className="event_btn_price">
                      <span className="event_price">{`AUD ${
                        !(
                          Number(detailsData?.price) === 0.0 ||
                          detailsData?.price === null
                        )
                          ? detailsData?.price
                          : 0
                      }`}</span>

                      <span className="event_class">
                        {!(
                          Number(detailsData?.price) === 0.0 ||
                          detailsData?.price === null
                        )
                          ? "/class"
                          : "(No Charges)"}
                      </span>
                    </div>
                  )}

                  {detailsData?.listingType === LISTING_TYPE.M && (
                    <div>
                      <div className="event_btn_price">
                        <span className="event_price">{`AUD ${
                          !(
                            Number(detailsData?.price) === 0.0 ||
                            detailsData?.price === null
                          )
                            ? detailsData?.price
                            : 0
                        }`}</span>

                        <span className="event_class">
                          {!(
                            Number(detailsData?.price) === 0.0 ||
                            detailsData?.price === null
                          )
                            ? "/package"
                            : "(No Charges)"}
                        </span>
                      </div>
                      {!(
                        Number(detailsData?.price) === 0.0 ||
                        detailsData?.price === null
                      ) ? (
                        <span className="p_txt_below">
                          {packageCounter} package included
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  )}

                  {detailsData?.listingType === LISTING_TYPE.C && (
                    <div>
                      <div
                        className="event_btn_price "
                        style={{ display: "block", textAlign: "start" }}
                      >
                        <span
                          style={{ textAlign: "start" }}
                          className="event_price "
                        >
                          {`AUD ${detailsData?.price}`}
                        </span>

                        {/* <span className="event_class">/Package</span> */}
                      </div>
                      <span className="" style={{ fontSize: "12px" }}>
                        per/month
                      </span>
                    </div>
                  )}

                  <Button
                    btnTxt={
                      !(
                        Number(detailsData?.price) === 0.0 ||
                        detailsData?.price === null
                      )
                        ? `Pay AUD ${parseFloat(paymentData?.total).toFixed(2)}`
                        : "Confirm"
                    }
                    className={"backtologin"}
                    onClick={() => {
                      handlePayment();
                    }}
                    isLoading={payLoading || paymentLoading}
                    disabled={isDisabled()}
                  />
                </div>

                <hr
                  className="my-4"
                  style={{ border: "2px solid #F1F1F1" }}
                ></hr>

                {detailsData?.isCancellationAllowed ? (
                  <div className="c-cancellation-policy">
                    <p className="c-first">Cancellation Policy</p>
                    <p className="c-second">
                      You can cancel your booking up until{" "}
                      {"{" + detailsData?.cancellationAllowedTillHours + "}"}{" "}
                      hours of your event
                    </p>
                  </div>
                ) : detailsData?.listingType !== LISTING_TYPE.C ? (
                  <div className="c-cancellation-policy">
                    <p className="c-first">Cancellation Policy</p>
                    <p className="c-second">
                      No cancellation allowed after booking
                    </p>
                  </div>
                ) : null}

                {detailsData?.listingType === LISTING_TYPE.C && (
                  <>
                    <div className="c-cancellation-policy">
                      <p className="c-first">
                        Recurring payment
                        <img
                          src="/static/greyinfo.svg"
                          alt=""
                          width="20px"
                          height="20px"
                          style={{ marginLeft: "12px" }}
                        />
                      </p>
                    </div>
                    <div className="info-box-reacc-payment">
                      <div className="info-box-cancel">
                        <span>
                          Due to the recurring nature of the payment, the
                          specified amount will be automatically deducted from
                          your account according to the chosen payment term. To
                          cease this recurring payment, please navigate to the
                          "My Team Listings" section within your profile.
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="right_container col-12 col-md-7">
              <div className=" info_wrapper ">
                <h2 className="p_title d-none d-md-block">Confirm & Pay</h2>
                {/* <div id="payment-block">
                  <Elements stripe={stripePromise} options={options}>
                    <CheckoutForm />
                  </Elements>
                </div> */}
                <div className="ep_session  d-md-flex">
                  {(detailsData?.listingType === LISTING_TYPE.S ||
                    detailsData?.listingType === LISTING_TYPE.SPECIAL) && (
                    <p>{selectedEvents?.length} Sessions Selected</p>
                  )}
                  {detailsData?.listingType === LISTING_TYPE.M && (
                    <p>
                      Sessions Included ({detailsData?.events?.length} Sessions)
                    </p>
                  )}

                  {detailsData?.listingType !== LISTING_TYPE.C ? (
                    <p
                      className="dates-btn-txt  d-flex align-items-center gap-3"
                      style={{ cursor: "pointer", fontWeight: "600" }}
                      onClick={() => setShowSessionModal(true)}
                    >
                      <p className="mb-0">view all</p>
                      <span>
                        <RightArrowStickIcon />
                      </span>
                    </p>
                  ) : (
                    ""
                  )}
                </div>

                {/* <div>
                            <p>Selected Session</p>
                            <div className="d-flex">
                                <p>Today, 22 June</p><div className="ep_dot"></div><p>2:00 - 3:00 PM</p>
                            </div>
                        </div>
                        <p className="d-md-none">Available Dates</p> */}

                {(detailsData?.listingType === LISTING_TYPE.S ||
                  detailsData?.listingType === LISTING_TYPE.SPECIAL) && (
                  <>
                    <div className="ep_date_container ">
                      {AllEventsData?.slice(0, 3)?.map((item, i) => {
                        return (
                          <div className="ep_date" key={i}>
                            <div className="ep_date_first">
                              <p className="ep_dt mb-0">
                                {moment(item?.date).format("ddd")},{" "}
                                {moment(item?.date).date()}{" "}
                                {moment(item?.date).format("MMMM")}
                              </p>
                              <p className="ep_tm mb-0">
                                {moment(item?.startTime?.substring(0, 5), [
                                  "HH:mm",
                                ]).format("hh:mmA")}
                                -
                                {moment(item.endTime?.substring(0, 5), [
                                  "HH:mm",
                                ]).format("hh:mmA")}
                              </p>
                              <p className="ep_how justify-content-between mb-0  gap-2 ">
                                <div className=" ep_first gap-2">
                                  <span>
                                    <GroupPeopleIcon />
                                  </span>
                                  <p className="mb-0">{item.countEvent}</p>
                                  {/* <p className="mb-0">{item.eventBookings?.[i]?.slotsBooked}</p> */}
                                </div>
                                <div className="ep_date_second">
                                  <p className="mb-0">
                                    {!(
                                      Number(detailsData?.price) === 0.0 ||
                                      detailsData?.price === null
                                    )
                                      ? `AUD ${detailsData?.price}`
                                      : ""}
                                  </p>
                                </div>
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <div
                      className="ep_sess_edit row gx-0 cursor-pointer my-3"
                      onClick={() => setShowSessionModal(true)}
                    >
                      <span className="col-1">
                        <EditSessionIcon />
                      </span>
                      <div className="edit_txt col-8 col-md-9">
                        Edit sessions
                      </div>
                      <span className="col-1">
                        <RightArrowIcon />
                      </span>
                    </div>
                  </>
                )}
                {detailsData?.listingType === LISTING_TYPE.M && (
                  <>
                    <div className="d-flex gap-2 overflow-auto">
                      {detailsData?.events?.slice(0, 2).map((item) => {
                        return (
                          <>
                            <div className="session_p ">
                              <div className="sp_title ">{item?.title}</div>
                              <div className="sp_address ">
                                <div className="d-flex">
                                  <LocationGreyIcon />
                                </div>
                                <p className="mb-0">{item?.address?.address}</p>
                              </div>
                              <div className="sp_date_time ">
                                <div>
                                  <SpDateIcon /> {moment(item?.date).date()}{" "}
                                  {moment(item?.date).format("MMM")},{" "}
                                  {moment(item?.date).year()}{" "}
                                </div>
                                <div>
                                  <SpClockIcon />
                                  {moment(item?.startTime?.substring(0, 5), [
                                    "HH:mm",
                                  ]).format("hh:mmA")}
                                  -{" "}
                                  {moment(item?.endTime?.substring(0, 5), [
                                    "HH:mm",
                                  ]).format("hh:mmA")}
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <div className="session_p_selected_info mt-3 ">
                      <div className="d-flex gap-3 sp_first">
                        <SessionPackageIcon />
                        Package Selected
                      </div>
                      <div className="sp_second">
                        <div className="s_p_counter">
                          <span
                            className="d-flex align-items-center cursor-pointer"
                            style={{
                              borderRadius: "100%",
                              backgroundColor: "rgba(238, 120, 48, 0.4)",
                            }}
                            onClick={() => handleMinusCounter()}
                          >
                            <DateMinus />
                          </span>
                          <span>{packageCounter}</span>
                          <div
                            className="d-flex align-items-center cursor-pointer"
                            style={{
                              borderRadius: "100%",
                              backgroundColor: "rgba(238, 120, 48, 0.4)",
                            }}
                            onClick={() => handlePlusCounter()}
                          >
                            <DatePlus />
                          </div>
                        </div>
                        <div className="s_p_amount">
                          {'AUD '}
                          {paymentData?.total
                            ? parseFloat(paymentData?.total).toFixed(2)
                            : 0}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {detailsData?.listingType === LISTING_TYPE.C && (
                  <div className="session_p_selected_info mt-3 ">
                    <div className="d-flex gap-3 sp_first">
                      <PeopleCommunityIconH
                        fill="#EE7830"
                        width="16"
                        height="16"
                      />
                      Team Member
                    </div>
                    <div className="sp_second">
                      <div className="s_p_counter">
                        <span
                          className="d-flex align-items-center cursor-pointer"
                          style={{
                            borderRadius: "100%",
                            backgroundColor: "rgba(238, 120, 48, 0.4)",
                          }}
                          onClick={() => handleMinusCounter()}
                        >
                          <DateMinus />
                        </span>
                        <span>{packageCounter}</span>
                        <div
                          className="d-flex align-items-center cursor-pointer"
                          style={{
                            borderRadius: "100%",
                            backgroundColor: "rgba(238, 120, 48, 0.4)",
                          }}
                          onClick={() => handlePlusCounter()}
                        >
                          <DatePlus />
                        </div>
                      </div>
                      <div>
                        {'AUD '}
                        {paymentData?.total
                          ? parseFloat(paymentData?.total).toFixed(2)
                          : 0}
                      </div>
                    </div>
                  </div>
                )}

                <p className="ep_contact">
                  {profileDetails.name &&
                  profileDetails.gender &&
                  profileDetails.email &&
                  profileDetails.age ? (
                    ""
                  ) : (
                    <span>
                      <TriangleErrorIcon />
                    </span>
                  )}
                  Contact Information
                </p>

                <div className=" ep_contact_info row gx-0 ">
                  <span className="col-1">
                    <ContactIcon />
                  </span>
                  <div className=" ep_user_info col-10">
                    <p className="ep_first">{profileDetails?.name}</p>
                    <div className="ep_second">
                      {profileDetails?.email ? (
                        <p className="ep_email">{profileDetails?.email}</p>
                      ) : (
                        <p className="ep_active">Email?</p>
                      )}
                      <div className="ep_dot"></div>
                      {profileDetails?.age ? (
                        <p>{profileDetails?.age}y</p>
                      ) : (
                        <p className="ep_active">Age?</p>
                      )}
                      <div className="ep_dot"></div>
                      {profileDetails?.gender ? (
                        <p className="ep_gender">{profileDetails?.gender}</p>
                      ) : (
                        <p className="ep_active">Gender?</p>
                      )}
                    </div>
                  </div>
                  <span
                    className="col-1 cursor-pointer"
                    onClick={() => setShowUpdateDetailsModal(true)}
                  >
                    <PencilEditIcon />
                  </span>
                </div>

                {paymentLoading ? (
                  <ApiLoader />
                ) : !(Number(detailsData?.price) === 0.0) ? (
                  <>
                    <div className="ep_amount">
                      <p className="ep_am_title">Your Total</p>
                      <div className="ep_am_subtotal">
                        <p className="first">Subtotal</p>
                        <p className="second">
                          AUD {parseFloat(paymentData?.subtotal).toFixed(2)}
                        </p>
                      </div>
                      <div className="ep_fee_content ">
                        <div className="d-flex gap-1 first">
                          <p className="mb-0">Booking Fee</p>

                          {/* <span>
                            <TooltipWrapper tooltip="The fee charged to clients for using the website and services">
                              <IFeeIcon />
                            </TooltipWrapper>
                          </span> */}
                        </div>
                        <p className="second">
                          AUD {parseFloat(paymentData?.platformFee).toFixed(2)}
                        </p>
                      </div>
                      <div className="ep_fee_content">
                        <div className="d-flex gap-1 first">
                          <p className="mb-0">Merchant Fee</p>

                          <span>
                            <TooltipWrapper tooltip="The merchant fee represents the transaction charge mandated by payment gateway regulations.">
                              <IFeeIcon />
                            </TooltipWrapper>
                          </span>
                        </div>
                        <p className="second">
                          AUD {parseFloat(paymentData?.merchantFee).toFixed(2)}
                        </p>
                      </div>
                      <hr className="my-3" />
                      <div className="ep_am_total">
                        <p className="first">Total Amount</p>
                        <p className="second">
                          AUD {parseFloat(paymentData?.total).toFixed(2)}
                        </p>
                      </div>
                    </div>
                    <p className="ep_note">
                      Note:{" "}
                      <span>
                        The cost of listing/event is subjective to deduction of
                        booking fee as well as merchant fee.
                      </span>
                    </p>
                  </>
                ) : null}

                <div className="ep_pay_info">
                  {!(
                    Number(detailsData?.price) === 0.0 ||
                    detailsData?.price === null
                  ) ? (
                    <>
                      <p className="pay_title">Payment Information</p>
                      <div className="p-3 ep_card_container">
                        <div className="ep_card">
                          <div className="row gx-0  align-items-center">
                            <div>
                              <span className="col-2 col-md-1">
                                <PayCardIcon />
                              </span>
                              <div className="col-9 col-md-10 pay-text ">
                                Credit or debit card
                              </div>
                            </div>

                            <input
                              className="col-1"
                              type="radio"
                              style={radioStyle}
                              value="credit_card"
                              checked={selectedPay === "credit_card"}
                              onChange={handleCards}
                            />
                          </div>
                          {selectedPay === "credit_card" && (
                            <>
                              {myCards.map((mc, index) => (
                                <div
                                  className=" ep_pay_card d-flex justify-content-between"
                                  key={index}
                                >
                                  <div className="d-flex first gap-3">
                                    <span>{renderCardImage(mc.brand)}</span>
                                    <p className="mb-0 card_num">
                                      <span>&#183; &#183; &#183; &#183;</span>{" "}
                                      {mc.last4}
                                    </p>
                                    <p className="mb-0 card_val">
                                      {mc.expMonth}/{mc.expYear}
                                    </p>
                                  </div>
                                  {mc.default ? (
                                    <div
                                      className="second"
                                      style={{ color: "#EE7830" }}
                                    >
                                      Selected
                                    </div>
                                  ) : (
                                    <div
                                      onClick={() => changeCardDefault(mc.id)}
                                      className="second"
                                      style={{ cursor: "pointer" }}
                                    >
                                      {isLoading ? (
                                        <div
                                          className="lds-ring"
                                          style={{
                                            margin: "0 auto",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <img
                                            style={{ width: "24px" }}
                                            src="/static/spinner.svg"
                                            alt="loader"
                                          />
                                        </div>
                                      ) : (
                                        "Select"
                                      )}
                                    </div>
                                  )}
                                </div>
                              ))}

                              <div
                                className="row ep_added_card gx-0  align-items-center"
                                onClick={() => setShowAddCardPopup(true)}
                              >
                                <span className="col-1">
                                  <BlackPlusIcon />
                                </span>
                                <div className="col-10">Add a new card</div>
                              </div>
                            </>
                          )}
                          <hr style={{ border: "1px solid #DDD" }}></hr>
                          <div className="row gx-0  align-items-center">
                            <span className="col-2 col-md-1">
                              <img src="/static/apple-pay.svg" alt="" />
                            </span>
                            <div className="col-9 col-md-10 pay-text ">
                              Apple Pay
                            </div>

                            <input
                              className="col-1 cursor-not-allowed"
                              type="radio"
                              style={radioStyle}
                              value="apple_pay"
                              disabled={true}
                              // checked={selectedPay === "apple_pay"}
                              // onChange={handleCards}
                            />
                          </div>

                          <hr style={{ border: "1px solid #DDD" }}></hr>
                          <div className="row gx-0  align-items-center">
                            <span className="col-2 col-md-1">
                              <img src="/static/google-pay.svg" alt="" />
                            </span>
                            <div className="col-9 col-md-10 pay-text ">
                              Google Pay
                            </div>

                            <input
                              className="col-1 cursor-not-allowed"
                              type="radio"
                              style={radioStyle}
                              value="google_pay"
                              disabled={true}
                              // checked={selectedPay === "google_pay"}
                              // onChange={handleCards}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
              <div className="all-button d-md-none my-4">
                {detailsData?.isCancellationAllowed ? (
                  <div className="c-cancellation-policy">
                    <p className="c-first">Cancellation Policy</p>
                    <p className="c-second">
                      You can cancel your booking up until{" "}
                      {"{" + detailsData?.cancellationAllowedTillHours + "}"}{" "}
                      hours of your event
                    </p>
                  </div>
                ) : (
                  <div className="c-cancellation-policy">
                    <p className="c-first">Cancellation Policy</p>
                    <p className="c-second">
                      No cancellation allowed after booking
                    </p>
                  </div>
                )}

                <div className="all-button-top d-flex ">
                  {(detailsData?.listingType === LISTING_TYPE.S ||
                    detailsData?.listingType === LISTING_TYPE.SPECIAL) && (
                    <div className="event_btn_price">
                      <span className="event_price">{`AUD ${
                        !(
                          Number(detailsData?.price) === 0.0 ||
                          detailsData?.price === null
                        )
                          ? detailsData?.price
                          : 0
                      }`}</span>

                      <span
                        className="event_class"
                        style={{ display: "flex", flexShrink: "0" }}
                      >
                        {!(
                          Number(detailsData?.price) === 0.0 ||
                          detailsData?.price === null
                        )
                          ? "/class"
                          : "(No Charges)"}
                      </span>
                    </div>
                  )}

                  {detailsData?.listingType === LISTING_TYPE.M && (
                    <div style={{ flexShrink: 0 }}>
                      <div className="event_btn_price">
                        <span className="event_price">{`AUD ${
                          !(
                            Number(detailsData?.price) === 0.0 ||
                            detailsData?.price === null
                          )
                            ? detailsData?.price
                            : 0
                        }`}</span>

                        <span className="event_class">
                          {!(
                            Number(detailsData?.price) === 0.0 ||
                            detailsData?.price === null
                          )
                            ? "/package"
                            : "(No Charges)"}
                        </span>
                      </div>
                      {!(
                        Number(detailsData?.price) === 0.0 ||
                        detailsData?.price === null
                      ) ? (
                        <span className="p_txt_below" style={{ flexShrink: 0 }}>
                          {packageCounter} package included
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  )}

                  {detailsData?.listingType === LISTING_TYPE.C && (
                    <div>
                      <div
                        className="event_btn_price "
                        style={{ display: "block", textAlign: "start" }}
                      >
                        <span
                          style={{ textAlign: "start" }}
                          className="event_price "
                        >
                          AUD {detailsData?.price}
                        </span>

                        {/* <span className="event_class">/Package</span> */}
                      </div>
                      <span className="" style={{ fontSize: "12px" }}>
                        per/month
                      </span>
                    </div>
                  )}

                  <Button
                    btnTxt={
                      !(
                        Number(detailsData?.price) === 0.0 ||
                        detailsData?.price === null
                      )
                        ? `Pay AUD ${parseFloat(paymentData?.total).toFixed(2)}`
                        : "Confirm"
                    }
                    className={"backtologin"}
                    onClick={() => {
                      handlePayment();
                    }}
                    isLoading={payLoading || paymentLoading}
                    disabled={isDisabled()}
                  />
                </div>
              </div>
            </div>
          </div>

          {showSessionModal && (
            <SessionPopup
              show={showSessionModal}
              onHide={() => setShowSessionModal(false)}
              counter={counter}
              setCounter={setCounter}
              allEvents={sessionEventsData}
              realAllEventsData={AllEventsData}
              selectedEvents={selectedEvents}
              detailsData={detailsData}
              setSelectedEvents={setSelectedEvents}
            />
          )}
          {showUpdateDetailsModal && (
            <UpdateDetailsPopup
              show={showUpdateDetailsModal}
              onHide={() => setShowUpdateDetailsModal(false)}
              profileDetails={profileDetails}
              setProfileDetails={setProfileDetails}
              setReqGender={setReqGender}
              setReqAge={setReqAge}
            />
          )}
          {/* {showGenderModal && <GenderPopup show={showGenderModal} onHide={() => setShowGenderModal(false)} setReqGender={setReqGender} />} */}
          {/* {showAgeModal && <AgePopup show={showAgeModal} onHide={() => setShowAgeModal(false)} setReqAge={setReqAge} />} */}
          {showAddCardPopup && (
            <AddCardPopup
              show={showAddCardPopup}
              onHide={() => setShowAddCardPopup(false)}
            />
          )}
          <BookingErrorPopup
            show={showBookingError}
            onHide={() => {
              setShowBookingError(false);
              setAgeError(null);
            }}
            gender={detailsData?.gender}
            ageError={ageError}
            setAgeError={setAgeError}
          />
          <PaymentSuccessfullPopup
            show={showPaymentPopup}
            onHide={() => {
              setShowPaymentPopup(false);
              navigate(`/listing-two/${event_id}`);
            }}
            isTeam={detailsData?.listingType === LISTING_TYPE.C}
            clickCheckBooking={() => {
              if (detailsData?.listingType === LISTING_TYPE.C) {
                navigate(ROUTE_CONSTANTS?.team_membership);
              } else {
                navigate(`/my-booking`);
              }
            }}
          />
        </div>
      )}
    </>
  );
};
export default BookingEvent;
