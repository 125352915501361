import React, { useState } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import moment from "moment";
import "./style.scss";
import Button from "../../../../global/Button";
import PreviewScheduleDates from "../PreviewScheduleDates";
import MeetLinkContainer from "../MeetLinkContainer";
import TimeSlotsContainer from "../TimeSlotsContainer";
import WeeklyListing from "../../WeeklySessions";
import {
  DATE_FORMAT,
  FORM_KEYS,
  INITIAL_SCHEDULE_VALUES,
  convertToFlatDates,
  WEEKLY_FORM_KEYS,
  radioData,
  eventData,
  SCHEDULE_TYPE,
} from "../../../../constants/helperScheduleDates";
import { SESSION_END_TYPE } from "../../../../types";
import { VIRTUAL_LINK_REGEX, recurFrequency } from "../../../../utils/Constants";
import { MESSAGE } from "../../../../utils/StringConstant";
import { showToast } from "../../../../utils";
import {
  getMultiDatesRecurringMonth,
  getOnetimeDates,
  getRecurringDayDates,
  getRecurringMonthDates,
  getRecurringWeekDates,
} from "../../../../constants/helperGenerateDates";
import LoadingLayer from "../../../ConnectUserProfile/LoaderLayer";

type Props = {
  virtual: boolean;
  sessionScheduleData: any;
  setSessionScheduleData: any;
};

const ScheduleDatesLayout = ({
  virtual = false,
  sessionScheduleData = null,
  setSessionScheduleData = () => null,
}: Props) => {
  const navigate = useNavigate();
  const [previewVisibility, setPreviewVisibility] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [previewData, setPreviewData] = useState<any>(null);
  const maxStep = virtual ? 3 : 2;
  const minStep = 1;
  const [currentStep, setCurrentStep] = useState<number>(minStep || 1);

  const {
    DAY_MONTH_FORMAT = "",
    DATE_MONTH_FORMAT = "",
    DDMMYYYY_FORMAT = "",
  } = DATE_FORMAT;

  const mergeDateTime = (date, time) => {
    return moment(`${date} ${time}`, DATE_FORMAT.RESP_FORMAT).toISOString();
  };

  const handleExistingTimeSlot = (newData = [], oldData = []) => {
    const updatedData = newData.map((newItem) => {
      const matchingOldItem = oldData.find((oldItem) =>
        moment(oldItem.date, DATE_FORMAT.DATE_MONTH_FORMAT).isSame(
          moment(newItem.date, DATE_FORMAT.DATE_MONTH_FORMAT),
          "day"
        )
      );

      if (matchingOldItem) {
        return {
          ...newItem,
          timeSlots: matchingOldItem.timeSlots,
        };
      }

      return newItem;
    });
    return updatedData;
  };


  const handleReverseFormat = (data = []) => {
    const formattedData = data.reduce((acc, current) => {
      let existingDate = acc.find((item) =>
        moment(item.date, DATE_FORMAT.DATE_MONTH_FORMAT).isSame(
          moment(current.date),
          "day"
        )
      );

      if (existingDate) {
        existingDate.timeSlots.push({
          startTime: mergeDateTime(current.date, current.start_time),
          endTime: mergeDateTime(current.date, current.end_time),
        });
      } else {
        // If the date doesn't exist, create a new entry for that date
        acc.push({
          date: moment(current.date).format(DATE_FORMAT.DATE_MONTH_FORMAT),
          timeSlots: [
            {
              startTime: mergeDateTime(current.date, current.start_time),
              endTime: mergeDateTime(current.date, current.end_time),
            },
          ],
        });
      }

      return acc;
    }, []);
    return formattedData;
  };

  const initialState = {
    eventDate: sessionScheduleData?.eventDate || [],
    eventType: sessionScheduleData?.eventType || SCHEDULE_TYPE.O,
    repeatInEvery: sessionScheduleData?.repeatInEvery || 1,
    repeatType: sessionScheduleData?.repeatType || SCHEDULE_TYPE.DAY,
    weekDays: sessionScheduleData?.weekDays || [],
    monthOnEvery: sessionScheduleData?.monthOnEvery || [],
    monthOnDays: sessionScheduleData?.monthOnDays || [],
    endEventType: sessionScheduleData?.endEventType || eventData[0].value,
    endAfterVal: sessionScheduleData?.endAfterVal || 1,
    endByVal: sessionScheduleData?.endByVal || "",
    payloadSchedule: sessionScheduleData?.payloadSchedule || null,
    respSchedule:
      sessionScheduleData?.payloadSchedule &&
      handleReverseFormat(sessionScheduleData?.payloadSchedule),
  };

  const {
    setError,
    clearErrors,
    setValue,
    getValues,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { ...initialState, ...INITIAL_SCHEDULE_VALUES },
    mode: "onSubmit",
  });

  const incrementStep = () => {
    if (currentStep < maxStep) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const decrementStep = () => {
    if (currentStep > minStep) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const nextTimeSlotsStep = async () => {
    const {
      eventDate = [],
      eventType = SCHEDULE_TYPE.O,
      repeatType = null,
      endEventType = "",
      endAfterVal = 1,
      repeatInEvery = 1,
      endByVal = null,
      weekDays = [],
      monthOnEvery = [],
      monthOnDays = [],
    } = getValues();
    
    let finalDates = null;
    
    if (eventDate?.length < 2 && eventDate?.length > 0) {
      if (eventType === SCHEDULE_TYPE.O) {
        finalDates = await getOnetimeDates(eventDate);
      } else if (eventType === SCHEDULE_TYPE.R) {
        const start = eventDate[0];
        const end = endByVal;
        const endsBy = endEventType;
        const occurrence = endAfterVal;
        const skip = repeatInEvery - 1;
        const weekDay = weekDays;
        const weekNumbers = monthOnEvery;
        const monthWeekDays = monthOnDays;

        if (repeatType === SCHEDULE_TYPE.DAY) {
          finalDates = await getRecurringDayDates(
            start,
            end,
            endsBy,
            occurrence,
            skip
          );
        } else if (repeatType === SCHEDULE_TYPE.MONTH) {
          finalDates = await getRecurringMonthDates(
            start,
            end,
            monthWeekDays,
            weekNumbers,
            endsBy,
            occurrence,
            skip
          );
        } else if (repeatType === SCHEDULE_TYPE.WEEK) {
          finalDates = await getRecurringWeekDates(
            start,
            end,
            weekDay,
            endsBy,
            occurrence,
            skip
          );
        }
      }
    } else if (eventDate?.length > 1) {
      // multiple case
      if (eventType === SCHEDULE_TYPE.O) {
        finalDates = await getOnetimeDates(eventDate);
      } else if (eventType === SCHEDULE_TYPE.R) {
        const dates = eventDate;
        const endDate = endByVal;
        const endsBy = endEventType;
        const occurrence = endAfterVal;
        const skip = repeatInEvery - 1;

        finalDates = await getMultiDatesRecurringMonth(
          dates,
          endDate,
          endsBy,
          occurrence,
          skip
        );
      }
    } else {
      showToast("Pick the event date(s)", "error");
      return;
    }

    if (finalDates?.length > 0) {
      const newSlots = await finalDates?.map((dateObj, dateIndex) => {
        return {
          date: dateObj?.date
            ? dateObj?.date
            : moment().format(DATE_MONTH_FORMAT),
          timeSlots:
            dateIndex === 0
              ? [
                  {
                    startTime: null,
                    endTime: null,
                  },
                ]
              : [],
        };
      });

      if (newSlots?.length) {
        const filterData = handleExistingTimeSlot(
          newSlots,
          watch("respSchedule")
        );

        setValue(FORM_KEYS.TEMP_DATE_TIME_SLOTS, filterData);
        setTimeout(() => {
          incrementStep();
        }, 0);
      }
    } else {
      showToast("The end date must be greater than the start date to create a valid time range.", "error");
    }
    setTimeout(() => {
      setLoading(false);
    }, 0);
  };

  const handleBackNavigate = (step) => {
    switch (step) {
      case 1:
        navigate(-1);
        break;
      default:
        decrementStep();
        break;
    }
  };

  const finalSubmitData = (data) => {
    const {
      eventDate = [],
      eventType = SCHEDULE_TYPE.O,
      repeatType = null,
      endEventType = "",
      endAfterVal = 1,
      repeatInEvery = 1,
      endByVal = null,
      weekDays = [],
      monthOnEvery = [],
      monthOnDays = [],
    } = getValues();

    const formattedEvents = data.map((event) => ({
      date: moment(event.date, DATE_FORMAT.DATE_MONTH_FORMAT).format(
        DATE_FORMAT.YYYYMMDD_FORMAT
      ),
      start_time: moment(event.startTime).format(DATE_FORMAT.TZ_TIME),
      end_time: moment(event.endTime).format(DATE_FORMAT.TZ_TIME),
      ...(virtual && event?.virtualLink && { virtual_link: event.virtualLink }),
    }));
    

    setSessionScheduleData({
      eventDate: eventDate || [],
      eventType: eventType || SCHEDULE_TYPE.O,
      repeatInEvery: repeatInEvery || 1,
      repeatType: repeatType || SCHEDULE_TYPE.DAY,
      weekDays: weekDays || [],
      monthOnEvery: monthOnEvery || [],
      monthOnDays: monthOnDays || [],
      endEventType: endEventType || "",
      endAfterVal:
        endEventType === SESSION_END_TYPE.END_AFTER ? endAfterVal : null,
      endByVal: endEventType === SESSION_END_TYPE.END_BY_DATE ?  endByVal : "",
      timeSlotsData: [],
      finalScheduleData: formattedEvents,
      payloadSchedule: formattedEvents || null,
      respSchedule: handleReverseFormat(formattedEvents),
    });

    handleBackNavigate(1);
  };

  const handleSave = () => {
    if (currentStep === 3) {
      const allLinksData = watch(FORM_KEYS.DATE_TIME_SLOTS);
      const filterVal = allLinksData?.filter((val) => !val?.virtualLink);

      if (!filterVal || filterVal?.length > 0) {
        showToast("Please add meeting links to all the event slots.", "error");
      } else {
        const httpsUrl = allLinksData?.filter((val) => {
          let regex = /^(https?:\/\/)/;
          return val?.virtualLink && !regex.test(val?.virtualLink);
        });
        if (!httpsUrl || httpsUrl?.length > 0) {
          showToast(
            "Please use the prefix before the URL i.e., “https://”",
            "error"
          );
        } else {
          const filterVal = allLinksData?.filter((val) => {
            
            return !VIRTUAL_LINK_REGEX.test(val?.virtualLink);
          });
          if (!filterVal || filterVal?.length > 0) {
            showToast(
              MESSAGE.WEB_URLS_REQUIRED,
              "error"
            );
          } else {
            finalSubmitData(allLinksData);
          }
        }
      }
    } else {
      const allLinksData = watch(FORM_KEYS.DATE_TIME_SLOTS);
      finalSubmitData(allLinksData);
    }
  };

  const checkTimeValidations = (newSlots) => {
    for (let i = 0; i < newSlots.length; i++) {
      const timeSlots = newSlots[i]?.timeSlots;
      timeSlots.sort(
        (a, b) =>
          new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
      );

      for (let j = 0; j < timeSlots.length; j++) {
        const startTime = new Date(timeSlots[j].startTime).getTime();
        const endTime = new Date(timeSlots[j].endTime).getTime();

        // Check if end time is less than start time
        if (endTime <= startTime) {
          setError(`tempDateTimeSlots.${i}.${j}`, {
            message: "End time must be after start time",
          });
          showToast("End time must be after start time", "error");
          return true; // Invalid time range found
        }

        // Check for overlapping with the next time slot
        if (j < timeSlots.length - 1) {
          const nextStartTime = new Date(timeSlots[j + 1].startTime).getTime();
          if (endTime > nextStartTime) {
            setError(`tempDateTimeSlots.${i}.${j}`, {
              message: "Overlapped with next time slot",
            });
            setError(`tempDateTimeSlots.${i}.${j + 1}`, {
              message: "Overlapped with previous time slot",
            });
            showToast("Time slots are overlapping", "error");
            return true; // Overlap found
          }
        }
      }
    }
    return false; // No invalid ranges or overlaps
  };

  const addVirtualLink = (originalArray, newArray) => {
    return newArray.map((newItem) => {
      // Find matching item in the original array
      const match = originalArray.find(
        (origItem) =>
          moment(origItem.date).isSame(moment(newItem.startTime), "day") &&
          moment(`${origItem.date}T${origItem.start_time}`).isSame(
            moment(newItem.startTime)
          ) &&
          moment(`${origItem.date}T${origItem.end_time}`).isSame(
            moment(newItem.endTime)
          )
      );

      return match ? { ...newItem, virtualLink: match.virtual_link } : newItem;
    });
  };

  const handleFinalSlotsSubmit = async (newSlots) => {
    if (!checkTimeValidations(newSlots)) {
      clearErrors();
      const flatDates = await convertToFlatDates(newSlots);

      const mappedVirtualObject = addVirtualLink(
        sessionScheduleData?.payloadSchedule || [],
        flatDates
      );
      setValue(
        FORM_KEYS.DATE_TIME_SLOTS,
        virtual ? mappedVirtualObject : flatDates
      );

      if (virtual) {
        setTimeout(() => {
          incrementStep();
        }, 0);
      } else {
        handleSave();
      }
    }
  };

  const handleClosePreviewVisibility = () => {
    setPreviewVisibility(false);
  };

  const handlePreview = async () => {
    const tempData = watch(FORM_KEYS.TEMP_DATE_TIME_SLOTS);
    // if (!Boolean(checkIncompleteData())) {
      const flatDates = await convertToFlatDates(tempData);
      setPreviewData(flatDates);

      setTimeout(() => {
        setPreviewVisibility(true);
      }, 0);
    // }
  };

  const handleNext = () => {
    switch (currentStep) {
      case 1:
        setLoading(true);
        nextTimeSlotsStep();
        break;
      case 2:
        handleFinalSlotsSubmit(watch(FORM_KEYS.TEMP_DATE_TIME_SLOTS));
        break;
      case 3:
        break;
      default:
        break;
    }
  };

  const checkIncompleteData = () => {
    const allSlots = watch(FORM_KEYS.TEMP_DATE_TIME_SLOTS);
    for (const schedule of allSlots) {
      if (!schedule.date || schedule.date.trim() === "") {
        return true; // Date is empty
      }

      // if (!Array.isArray(schedule.timeSlots) || schedule.timeSlots.length < 1) {
      //   return true;
      // }

      for (const slot of schedule.timeSlots) {
        if (!slot.startTime || !slot.endTime) {
          return true;
        }
      }
    }
    const isAllEmpty = allSlots?.filter((slot) => slot?.timeSlots?.length > 0);

    if (isAllEmpty?.length === 0) {
      return true;
    }

    return false;
  };

  const checkIncompleteDataForWeekly = () => {
    const checkRecursValues = () => {
      switch (watch(WEEKLY_FORM_KEYS.REPEAT_TYPE)) {
        case recurFrequency[1]:
          if (watch(WEEKLY_FORM_KEYS.WEEKDAYS).length > 0) {
            return false;
          } else {
            return true;
          }
        case recurFrequency[2]:
          if (
            watch(WEEKLY_FORM_KEYS.MONTH_ON_EVERY).length > 0 &&
            watch(WEEKLY_FORM_KEYS.MONTH_ON_DAYS).length > 0
          ) {
            return false;
          } else {
            return true;
          }
        default:
          return false;
      }
    };

    const checkEndValues = () => {
      switch (watch(WEEKLY_FORM_KEYS.END_EVENT_TYPE)) {
        case eventData[0]?.value:
          if (watch(WEEKLY_FORM_KEYS.END_AFTER_VAL)) {
            return false;
          } else {
            return true;
          }
        case eventData[1]?.value:
          if (watch(WEEKLY_FORM_KEYS.END_BY_VAL)) {
            return false;
          } else {
            return true;
          }
        default:
          return true;
      }
    };

    const checkEventDateSelected = () => {
      if (watch(WEEKLY_FORM_KEYS.EVENT_DATE)?.length > 0) {
        return false;
      } else {
        return true;
      }
    };

    const checkForEventType = () => {
      switch (watch(WEEKLY_FORM_KEYS.EVENT_TYPE)) {
        case radioData[1].value:
          if (checkEventDateSelected() === false) {
            if (watch(WEEKLY_FORM_KEYS.EVENT_DATE)?.length > 1) {
              return checkEndValues();
            } else {
              if (checkRecursValues() === false && checkEndValues() === false) {
                return false;
              } else return true;
            }
          } else {
            return true;
          }
        case radioData[0].value:
          return checkEventDateSelected();
        default:
          return true;
      }
    };

    return checkForEventType();
  };

  return (
    <Box className="schedule_dates_layout">
      {loading && <LoadingLayer isCustom/> }
      {/* body */}
      <Box className="schedule_body_wrapper">
        {/* heading */}
        <Box className="schedule_header_wrapper">
          <Box
            className="schedule_back_arrow_container"
            onClick={() => handleBackNavigate(currentStep)}
          >
            <img
              src="/static/arrow/backArrowGrey.svg"
              alt=""
              className="arrow_icon_back"
            />
            <Box className="back_btn_arrow_text">
              {currentStep === 1 ? "Back to listing" : "Back"}
            </Box>
          </Box>
          <Box className="schedule_header_data">
            {currentStep === 1 ? (
              <>
                <Box className="schedule_header_heading">
                  Choose the dates for your event
                </Box>
                <Box className="schedule_header_subheading">
                  Please select the days you'd like your listing to run
                </Box>
              </>
            ) : currentStep === 2 ? (
              <>
                <Box className="schedule_header_heading">Select Time Slots</Box>
                <Box className="schedule_header_subheading">
                  Pick the ideal time slots to ensure your events run smoothly
                  and fit participants' schedules.
                </Box>
              </>
            ) : (
              <>
                <Box className="schedule_header_heading">Add meet links</Box>
                <Box className="schedule_header_subheading">
                  Add meeting links to your virtual events to ensure events run
                  smoothly.
                </Box>
              </>
            )}
          </Box>
          <Box className="schedule_steps_container">
            <Box className="web_steps">{`Step ${currentStep} of ${maxStep}`}</Box>
            <Box className="responsive_steps">{`${currentStep}/${maxStep}`}</Box>
          </Box>
        </Box>
        {/* content */}
        <Box className="schedule_content_wrapper">
          {previewVisibility && (
            <PreviewScheduleDates
              open={previewVisibility}
              onClose={handleClosePreviewVisibility}
              data={previewData}
            />
          )}
          {currentStep === 1 ? (
            <Box className="schedule_content_inner">
              <WeeklyListing
                setValue={setValue}
                getValues={getValues}
                watch={watch}
              />
            </Box>
          ) : currentStep === 2 ? (
            <Box className="schedule_content_time">
              <TimeSlotsContainer
                setValue={setValue}
                getValues={getValues}
                watch={watch}
                errors={errors}
                clearErrors={clearErrors}
              />
            </Box>
          ) : (
            <Box className="schedule_content_meet_link">
              <MeetLinkContainer
                setValue={setValue}
                getValues={getValues}
                watch={watch}
              />
            </Box>
          )}
        </Box>
      </Box>

      {/* footer */}
      <Box className="schedule_footer_wrapper">
        <Box className="schedule_footer_action">
          {currentStep === 1 ? (
            <>
              <Box
                className="cancel_scheduling_btn"
                onClick={() => handleBackNavigate(1)}
              >
                Cancel <span className="non_resp_btn_text">Scheduling</span>
              </Box>
              <Box className="add_time_slots_btn_container">
                <Button
                  className="add_time_slots_btn"
                  btnTxt="Next: Add Time Slots"
                  disabled={Boolean(checkIncompleteDataForWeekly())}
                  onClick={handleNext}
                />
              </Box>
            </>
          ) : currentStep === 2 ? (
            <>
              {virtual ? (
                <Box
                  className="cancel_scheduling_btn"
                  onClick={() => handleBackNavigate(1)}
                >
                  Cancel <span className="non_resp_btn_text">Scheduling</span>
                </Box>
              ) : (
                <Box className="cancel_scheduling_btn" onClick={handlePreview}>
                  Preview{" "}
                  <img
                    style={{ marginLeft: "6px" }}
                    src="/static/listing/previewEye.svg"
                    alt=""
                  />
                </Box>
              )}

              <Box className="add_time_slots_btn_container">
                <Button
                  className="add_time_slots_btn"
                  disabled={Boolean(checkIncompleteData())}
                  btnTxt={!virtual ? "Save" : "Next: Add meeting links"}
                  onClick={handleNext}
                />
              </Box>
            </>
          ) : (
            <>
              <Box className="cancel_scheduling_btn" onClick={handlePreview}>
                Preview{" "}
                <img
                  style={{ marginLeft: "6px" }}
                  src="/static/listing/previewEye.svg"
                  alt=""
                />
              </Box>
              <Box className="add_time_slots_btn_container">
                <Button
                  className="add_time_slots_btn"
                  btnTxt="Save"
                  onClick={handleSave}
                />
              </Box>
            </>
          )}
        </Box>
        <Box className="schedule_footer_info">
          Cancelling now will erase all scheduling progress.
        </Box>
      </Box>
    </Box>
  );
};

export default ScheduleDatesLayout;
