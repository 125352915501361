import React, { useMemo } from "react";
import { apiConstants } from "../../../utils/Constants";
import { STRING_CONSTANTS } from "../../../utils/StringConstant";
import { LISTING_TYPE, USER_TYPE } from "../../../types";
import InfoMessage from "../InfoMessage";
import CancelEventField from "../CancelEventField";

const CancellationSection = React.memo(
  ({
    userType,
    watch,
    handleSetValue,
    isBookingInfo,
    setIsBookingInfo,
  }: {
    userType: string;
    watch: (field: string) => any;
    handleSetValue: (field: string, value: any) => void;
    isBookingInfo: boolean;
    setIsBookingInfo: React.Dispatch<React.SetStateAction<boolean>>;
  }) => {
    const isGuestUser = userType === USER_TYPE.GUEST;
    const isListingTypeC = watch(apiConstants.listingType) === LISTING_TYPE.C;

    const shouldShowCancellationSection = useMemo(() => {
      return !isGuestUser && !isListingTypeC;
    }, [isGuestUser, isListingTypeC]);

    return shouldShowCancellationSection ? (
      <div style={{ marginTop: "28px" }}>
        <div className="main-heaidng" >
          <h6>Allow booking to cancel</h6>
          <img
            src="/static/info.svg"
            alt=""
            onClick={() => setIsBookingInfo((prev) => !prev)}
          />
        </div>
        <CancelEventField watch={watch} handleSetValue={handleSetValue} />
        {isBookingInfo && (
          <InfoMessage
            message={STRING_CONSTANTS.booking_info}
            style={{ marginTop: "12px" }}
          />
        )}
      </div>
    ) : null;
  }
);

export default CancellationSection;
