import React, { useState } from "react";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import "./style.scss";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Link, useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import FooterPartnerPopup from "../FooterPopup/FooterPartnersPopup";
import useCheckDeviceScreen from "../../hooks/useCheckScreen";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import { scrollToTop } from "../../utils";

const FooterLink = ({ to, label, modalType, handleOpenModal }: any) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (to) {
      navigate(to);
      scrollToTop();
    } else if (modalType) {
      handleOpenModal(modalType);
    }
  };

  return <li onClick={handleClick}>{label}</li>;
};

const StickyFooter = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { isMobile } = useCheckDeviceScreen();
  const isSticky = true;

  const navigate = useNavigate();
  const [showAffiliate, setShowAffiliate] = useState(false);
  const [modalData, setModalData] = useState("affiliate");

  const handleOpenModal = (modalType) => {
    setModalData(modalType);
    setShowAffiliate(true);
  };
  const handleCloseModal = () => {
    setShowAffiliate(false);
  };

  const toggleFooter = () => {
    setIsExpanded(!isExpanded);
  };

  const footerLinks = [
    //  { label: "Home", path: "/" },
    { label: "Terms & Conditions", path: "/policies?activeTab=terms" },
    { label: "Privacy Policy", path: "/policies?activeTab=privacy" },
  ];

  const socialMediaLinks = [
    {
      name: "Twitter",
      url: "https://twitter.com/fitnessmatesau",
      icon: "/static/twitter-white.svg",
    },
    {
      name: "TikTok",
      url: "https://www.tiktok.com/@fitnessmatesau",
      icon: "/static/tiktok-white.svg",
    },
    {
      name: "Instagram",
      url: "https://instagram.com/fitnessmatesau",
      icon: "/static/insta-white.svg",
    },
    {
      name: "YouTube",
      url: "https://www.youtube.com/@fitnessmatesau",
      icon: "/static/youtube-white.svg",
    },
    {
      name: "Facebook",
      url: "https://facebook.com/fitnessmatesau",
      icon: "/static/facebook-white.svg",
    },
    {
      name: "LinkedIn",
      url: "https://www.linkedin.com/company/fitness-mates",
      icon: "/static/linkedin-white.svg",
    },
  ];

  const footerModal = [
    { label: "Affiliate Program", modalType: "affiliate" },
    { label: "Become an Ambassador", modalType: "program" },
    { label: "Become an Affiliate", modalType: "affiliate" },
  ];

  return (
    <Box>
      <Box className="sticky_footer_bar">
        <Box className="sticky_footer_bar_data">
          <Box className="footer_copyright">
            © 2024 Fitness mates Pty Ltd. All rights reserved.
          </Box>
          <Box className="footer_sticky_link">
            {footerLinks.map((link, index) => (
              <React.Fragment key={index}>
                <Link className="modified_link_effect_footer" to={link.path}>
                  {link.label}
                </Link>
                {index < footerLinks.length - 1 && <Box>•</Box>}
              </React.Fragment>
            ))}
          </Box>
        </Box>
        <Box className="footer_bar_right">
          <a
            className="modified_link_effect_footer"
            href="mailto:info@fitnessmates.com"
          >
            info@fitnessmates.com
          </a>

          {/* <Box>1300 683 892</Box>
          <Box>Sydney, Australia</Box> */}
          <IconButton onClick={toggleFooter} aria-label="expand_icon">
            <ExpandLessIcon
              sx={{
                fontSize: "16px",
                color: "#000",
              }}
            />
          </IconButton>
        </Box>
      </Box>

      <Modal
        open={isExpanded}
        onClose={() => setIsExpanded(false)}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black background
            backdropFilter: "blur(6px)", // Blur effect
          },
        }}
      >
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            backgroundColor: "#25272D",
            color: "#FFFFFF",
            padding: "0",
            boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.2)",
            borderRadius: "8px 8px 0 0",
            transition: "transform 0.3s ease-in-out",
            transform: isExpanded ? "translateY(0)" : "translateY(100%)",
          }}
        >
          <Box className="modal_sticky_footer_data">
            <Box className="sticky_foot_list">
              <Box className="sticky_footer_heading footer_quick">
                Quick Links
              </Box>
              <ul>
                <FooterLink to={ROUTE_CONSTANTS.landing} label="Home" />
                <FooterLink to={ROUTE_CONSTANTS.about_us} label="About us" />
                <FooterLink to={ROUTE_CONSTANTS.promotion} label="Promotions" />
                <FooterLink
                  to={ROUTE_CONSTANTS.connect}
                  label="Meet New Mates"
                />
                <FooterLink
                  modalType="carrier"
                  label="Careers"
                  handleOpenModal={handleOpenModal}
                />
              </ul>
            </Box>

            <Box className="sticky_foot_list footer_partner">
              <Box className="sticky_footer_heading">Partners</Box>
              <ul>
                {footerModal.map((item, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      setIsExpanded(false);
                      handleOpenModal(item.modalType);
                    }}
                  >
                    {item.label}
                  </li>
                ))}
              </ul>
            </Box>

            <Box className="sticky_foot_list footer_locate">
              <Box className="sticky_footer_heading">Locate us</Box>
              <Box className="locate_outer_container">
                <Box className="icon_locate_container">
                  <img src="/static/outline-email.svg" alt="" />
                  <a href="mailto:info@fitnessmates.com">
                    info@fitnessmates.com
                  </a>
                </Box>
                <Box className="icon_locate_container">
                  <img src="/static/outline-call.svg" alt="" />
                  <Box>1300 683 892</Box>
                </Box>
                <Box className="icon_locate_container">
                  <img src="/static/outline-location.svg" alt="" />
                  <Box>Sydney, Australia</Box>
                </Box>
              </Box>
            </Box>

            <Box className="sticky_foot_list footer_follow">
              <Box className="sticky_footer_heading">Follow us</Box>
              <Box className="social_icon_container">
                {socialMediaLinks.map((social, index) => (
                  <IconButton
                    key={index}
                    onClick={() => window.open(social.url, "_blank")}
                  >
                    <img src={social.icon} alt={social.name} />
                  </IconButton>
                ))}
              </Box>
              <Box className="store_icon_container">
                <img
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open(
                      "https://apps.apple.com/in/app/fitness-mates-pty-ltd/id6449068527",
                      "_blank"
                    );
                  }}
                  src="/static/app-store-whole.svg"
                  alt=""
                />
                <img
                  src="/static/play-store-whole.svg"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.fitnessmates.mobile.app",
                      "_blank"
                    );
                  }}
                  alt=""
                />
              </Box>
            </Box>

            <Box className="bottom_sticky_footer_data">
              <Box className="footer_sticky_line" />

              <Box className="sticky_footer_bottom">
                <Box>© 2024 Fitness mates Pty Ltd. All rights reserved.</Box>

                <Box className="sticky_footer_bottom_right">
                  {footerLinks.map((link, index) => (
                    <React.Fragment key={index}>
                      <Link
                        className="modified_link_effect_footer"
                        to={link.path}
                      >
                        {link.label}
                      </Link>
                      {index < footerLinks.length - 1 && <Box>|</Box>}
                    </React.Fragment>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className="close_sticky_footer">
            <IconButton
              onClick={() => setIsExpanded(false)}
              aria-label="close sticky footer"
            >
              <CancelIcon
                sx={{
                  fontSize: "40px",
                  color: "#fff",
                }}
              />
            </IconButton>
          </Box>
        </Box>
      </Modal>
      {showAffiliate && (
        <FooterPartnerPopup
          show={showAffiliate}
          onHide={handleCloseModal}
          modalType={modalData}
        />
      )}
    </Box>
  );
};

export default StickyFooter;
