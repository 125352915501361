import React, { Fragment } from "react";
import "./style.container.scss";
import EventListingCard from "../EventListingCard";
import EventShimmer from "../../Reusable/Shimmer/EventShimmer";
import LoadOnScroll from "../../Reusable/LoadOnScroll";
type Props = {
  [key: string]: any;
};

const EventsContainer = (props: Props) => {
  const { listing = [], handleEventActionCallback = () => null , fetchingMoreData=false , nextUrl=false , onLoadMore } = props;
  const renderEventsShimmer = () => Array.from({ length: 12 }).map((_, index) =>  <EventShimmer key={"000"+index} /> ) ;
  if (listing?.length < 1) {
    return (
      <div className="no_data_container">
        <img className="no_data_img" src="/static/connect/noData.svg" alt="" />
      </div>
    );
  } else {
    return (
      <div className="event_container_outer">
        {listing?.map((data, index) => (
          <Fragment key={"event_card" + index}>
            <EventListingCard
              data={data}
              handleEventActionCallback={handleEventActionCallback}
            />
          </Fragment>
        ))}
        {nextUrl && !fetchingMoreData && <LoadOnScroll nextUrl={nextUrl} onLoadMore={onLoadMore} loaderComponent={false} />}
        {fetchingMoreData && renderEventsShimmer()}
      </div>
    );
  }
};

export default React.memo(EventsContainer);
