import Skeleton from "@mui/material/Skeleton";

const ConnectFlaggedUserCardShimmer = () => {
  return (
    <div className="flagged-card-wrapper">
      <div className="user-detail-wraper">
        <div className="user-img-wrapper">
          <Skeleton variant="circular" width={80} height={80} />

          {/* <img src={IconFlaggedUSer} alt="flag" className="flag-style" /> */}
        </div>
        <div className="name-interest-wraper">
          <div style={{display: "flex", alignItems: "center"}}>
            <div className="name-wrap">
              <Skeleton animation="wave" height={23.5} width={124} />
            </div>
            <div className="role-wrapper">
              <div className="user-role" style={{border:"none"}}>
                <Skeleton variant="rounded" width={65}  />
              </div>
            </div>
          </div>

          <div className="text-style">
            <Skeleton variant="rounded" width={101} height={18} />
            <span>&#8226;</span>
            <Skeleton variant="rounded" width={101} height={18} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectFlaggedUserCardShimmer;
