import React, { useMemo } from "react";
import { PRICING_TYPE, apiConstants } from "../../../utils/Constants";
import { LISTING_TYPE, USER_TYPE } from "../../../types";

interface FieldListingTypeProps {
  watch?: (field: string) => any;
  handleSetValue?: (field: string, value: any) => void;
  userType?: any;
}

const ListingFreePaidType: React.FC<FieldListingTypeProps> = React.memo(
  ({ watch, handleSetValue, userType }) => {
    const pricingType = watch(apiConstants.listing_price_type);
    const isGuestUser = userType === USER_TYPE.GUEST;
    const listingType = watch(apiConstants.listingType);

    const handleFreeListingClick = () => {
      handleSetValue(apiConstants.listing_price_type, PRICING_TYPE.FREE);
      handleSetValue(apiConstants.listing_cost, 0);
    };

    const handlePaidListingClick = () => {
      handleSetValue(apiConstants.listing_price_type, PRICING_TYPE.PAID);
      handleSetValue(apiConstants.listing_cost, '');
    };

    const listingPaidTypeContent = useMemo(() => {
      return (
        <>
          <div className="listing_type_radio">
            <img
              src={
                pricingType === PRICING_TYPE.FREE
                  ? "/static/radiocheck.svg"
                  : "/static/radiouncheck.svg"
              }
              alt=""
              onClick={handleFreeListingClick}
            />
            <label
              className={
                pricingType === PRICING_TYPE.FREE
                  ? "listing_ype_label active"
                  : "listing_ype_label"
              }
              onClick={handleFreeListingClick}
              style={{ cursor: "pointer" }}
            >
              Free
            </label>
          </div>
          <div className="listing_type_radio mt-1">
            <img
              src={
                pricingType === PRICING_TYPE.PAID
                  ? "/static/radiocheck.svg"
                  : "/static/radiouncheck.svg"
              }
              alt=""
              onClick={handlePaidListingClick}
            />
            <label
              className={
                pricingType === PRICING_TYPE.PAID
                  ? "listing_ype_label active"
                  : "listing_ype_label"
              }
              onClick={handlePaidListingClick}
              style={{ cursor: "pointer" }}
            >
              Paid
            </label>
          </div>
        </>
      );
    }, [pricingType, handleSetValue]);

    if (listingType === LISTING_TYPE.C || isGuestUser) {
      return null;
    }

    return (
      <>
        <div
          className="listing_type_container mb-3"
          style={{ marginTop: "28px" }}
        >
          <div className="listing_type_heading">
            Do you want to create free listing or paid?
          </div>
          <div>{listingPaidTypeContent}</div>
        </div>
      </>
    );
  }
);

export default ListingFreePaidType;
