import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import ApiLoader from "../../global/ApiLoader";
import { getItemFromStore, setLocalStorage, showToast } from "../../utils";
import endpoints from "../../utils/endpoints";
import {
  makeGetCall,
  makeNextPageCall,
  makePutCall,
} from "../../utils/Requests";
import { NOTIFICATION_TYPE } from "../../types";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import GlobalHeader2 from "../Dashboard/GlobalHeader2";
import LoadOnScroll from "../Reusable/LoadOnScroll";
import NotificationShimmer from "../Reusable/Shimmer/NotificationShimmer";

const Notification = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [notificationCount, setNotificationCount] = useState<any>(null);
  const [listing, setListing] = useState<any>([]);
  const [nextUrl, setNextUrl] = useState<any>(null);
   const lastFetchedUrl = useRef(null);
   const [fetchingMoreData, setFetchingMoreData] = useState(false);

  const getListingData = () => {
    setIsLoading(true);
    makeGetCall({ url: endpoints.notifications })
      .then((res) => {
        if (res?.results) {
          setListing(res?.results);
          setNextUrl(res?.next || null);
        } else {
          showToast(res.status.message, "error");
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 0);
      })
      .catch((err) => {
        setIsLoading(false);
        showToast(err, "error");
      });
  };

  const handleClick = (itemId, notificationType, dataId) => {
    switch (notificationType) {
      case NOTIFICATION_TYPE.TNC:
        navigate(ROUTE_CONSTANTS.policies + "?activeTab=privacy");
        break;
      case NOTIFICATION_TYPE.PTU:
        navigate(ROUTE_CONSTANTS.policies + "?activeTab=privacy");
        break;
      case NOTIFICATION_TYPE.PPU:
        navigate(ROUTE_CONSTANTS.policies);
        break;
      case NOTIFICATION_TYPE.PFS:
        navigate(`/our-mates/${dataId}`);
        break;
      case NOTIFICATION_TYPE.TM:
        navigate(ROUTE_CONSTANTS.team_membership);
        break;
      case NOTIFICATION_TYPE.CB:
        navigate(ROUTE_CONSTANTS.my_booking + "?activeTab=Cancelled");
        break;
      case NOTIFICATION_TYPE.MCL:
        navigate(ROUTE_CONSTANTS.events + `/${dataId}`);
        break;
      case NOTIFICATION_TYPE.WL:
        navigate(ROUTE_CONSTANTS.events + `/${dataId}`);
        break;
      case NOTIFICATION_TYPE.CDN:
        navigate(ROUTE_CONSTANTS.chat + `?chatId=${dataId}`);
        break;
      case NOTIFICATION_TYPE.CGN:
        navigate(ROUTE_CONSTANTS.chat + `?chatId=${dataId}`);
        break;
      default:
        break;
    }
    makeGetCall({ url: endpoints.readNotification + `${itemId}/` }).then(
      (res) => {
        if (res.status.code === 200) {
          const updatedListing = listing.map((item) => {
            if (item.id === itemId) {
              return { ...item, isRead: true };
            }
            return item;
          });

          setListing(updatedListing);
          updateNotificationCount();
        }
      }
    );
  };


  const updateNotificationCount = () => {
    makeGetCall({ url: endpoints.profile })
      .then((res) => {
        if (res.status.code === 200) {
          let data = {
            ...res.data?.userDetail,
            ...res.data?.businessDetail?.[0],
          };
          setLocalStorage("userProfile", data);
          setNotificationCount(data?.unreadNotificationCount);
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };

  const markAllAsRead = () => {
    setIsLoading(true);

    // Making the PUT request to mark all notifications as read
    makePutCall({ url: endpoints.mark_all_read })
      .then((res) => {
        if (res.status.code === 200) {
          // Update the local state to mark all notifications as read
          const updatedListing = listing.map((item: any) => ({
            ...item,
            isRead: true,
          }));
          setListing(updatedListing);
          updateNotificationCount();
          showToast(res.status.message, "success");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        showToast(err, "error");
      });
  };

  useEffect(() => {
    getListingData();
    setNotificationCount(
      getItemFromStore("userProfile")?.unreadNotificationCount
    );
  }, []);
  
  const handleLoadMore = () => {
    if (nextUrl) {
      if (nextUrl === lastFetchedUrl.current) {
        // If the nextUrl is the same as the last fetched one, return early
        return;
      }
      // Update the last fetched URL
      lastFetchedUrl.current = nextUrl;

      setFetchingMoreData(true);
      makeNextPageCall({ url: nextUrl })
        .then((res) => {
          if (res?.next) {
            setNextUrl(res.next);
          } else {
            setNextUrl(null);
          }
          setListing((prev) => [...prev, ...res.results]);
          setFetchingMoreData(false);
        })
        .catch((err) => {
          showToast(err, "error");
        });
    }
  };

  const renderEventsShimmer = () =>
    Array.from({ length: 8 }).map((_, index) => <NotificationShimmer />);

  return (
    <>
      <GlobalHeader2 />
      <div className="notification-page-wrap">
        <div className="content-wrap">
          <div className="top-section">
            <div className="heading-wrapper">
              <div className="heading">Notifications ({notificationCount})</div>
              <div
                className="heading-right"
                onClick={markAllAsRead}
                style={{ cursor: "pointer" }}
              >
                Mark all as read
              </div>
            </div>
          </div>

          {listing?.length === 0 && !isLoading ? (
            <div className="empty-data">
              
            </div>
          ) : listing?.length === 0 && isLoading ? (
            <div className="d-flex flex-column gap-3">
              {renderEventsShimmer()}
            </div>
          ) : (
            <div className="notifaction-box-wrap">
              {listing?.map((item: any, index: number) => (
                <div
                  className="notifaction-box"
                  key={item.id}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleClick(item.id, item.notificationType, item.dataId);
                  }}
                >
                  {!item.isRead ? (
                    <img
                      alt=""
                      src="/static/unreaddot.svg"
                      className="active-dot"
                    />
                  ) : null}
                  <div className="title">{item?.title}</div>
                  <div className="notification-time">
                    {moment(item?.createdAt).format("MMMM D, YYYY [at] h:mma")}
                  </div>
                </div>
              ))}
              {nextUrl && !fetchingMoreData && (
                <LoadOnScroll
                  nextUrl={nextUrl}
                  onLoadMore={handleLoadMore}
                  loaderComponent={false}
                />
              )}
              {fetchingMoreData && renderEventsShimmer()}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Notification;
