import {useEffect, useRef, useState} from "react";
import SearchFilter from "../ConnectComponents/SearchFilter";
import NoConnections from "./NoConnections";
import {makeGetCall, makeNextPageCall} from "../../../utils/Requests";
import {apiConstants} from "../../../utils/Constants";
import {showToast} from "../../../utils";
import ConnectUserCard from "../ConnectComponents/ConnectUserCard";
import {CONNECTION_TYPE} from "../ConnectConstants";
import ConnectMapContainer from "../ConnectComponents/ConnectMapContainer";
import useCheckDeviceScreen from "../../../hooks/useCheckScreen";
import NoSearchedFilter from "../ConnectComponents/NoSearchedFilter";
import {getGeolocation} from "../../../constants/helperConnectUser";
import ConnectUserCardShimmer from "../../Reusable/Shimmer/ConnectUserCardShimmer";
import LoadOnScroll from "../../Reusable/LoadOnScroll";

const ConnectConnections = () => {
  const [search, setSearch] = useState("");
  const [connectedConnectionsList, setConnectedConnectionsList] = useState([]);
  const [totalConnections, setTotalConnections] = useState(0);
  const [showMap, setShowMap] = useState(false);
  const [limit] = useState<number>(12);
  const [isLoading, setIsLoading] = useState(true);
  const [serachData, setSearchData] = useState<any>();
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [count, setCount] = useState(0);
  const {isMobile} = useCheckDeviceScreen();
  const [userCurrentLocation, setUserCurrentLocation] = useState({
    lat: 0,
    long: 0,
  });
  const [offset, setOffset] = useState<number>(0);
  const [nextUrl, setNextUrl] = useState<any>(null);
  const [fetchingMoreData, setFetchingMoreData] = useState(false);
  const lastFetchedUrl = useRef(null);

  const handleClearClick = () => {
    setSearch("");
    setIsLoading(true);
    setIsSearchMode(false);
    setNextUrl(null);
  };

  const handleSuggestionSearch = (value: any) => {
    setSearch(value);
  };

  const handleSearchedSeeAllClick = () => {
    setIsLoading(true);
    getConnectedConnections();
    setIsSearchMode(true);
  };
  const handleViewToggle = () => {
    setShowMap((prev) => !prev);
  };

  const renderConnectUserCardShimmer = (length = 12) =>
    Array.from({length: length}).map((_, index) => (
      <ConnectUserCardShimmer key={"000" + index} />
    ));

  const handleLoadMore = () => {
    if (nextUrl) {
      if (nextUrl === lastFetchedUrl.current) {
        // If the nextUrl is the same as the last fetched one, return early
        return;
      }
      // Update the last fetched URL
      lastFetchedUrl.current = nextUrl;

      setFetchingMoreData(true);
      makeNextPageCall({url: nextUrl})
        .then((res) => {
          if (res?.next) {
            setNextUrl(res.next);
          } else {
            setNextUrl(null);
          }

          setConnectedConnectionsList((prev) => [...prev, ...res.results]);

          setFetchingMoreData(false);
        })
        .catch((err) => {
          showToast(err, "error");
        });
    }
  };
  const getSearchedConnectedConnections = (
    url = apiConstants.get_my_connections,
    limit = 8
  ) => {
    const params = {limit, offset};
    makeGetCall({
      url,
      params: {...params, search: search},
    })
      .then((res) => {
        const data = res.results;
        if (data) {
          setSearchData(data);
        }
        setNextUrl(null);
        lastFetchedUrl.current = null;
        setIsLoading(false);
      })
      .catch((err: any) => {
        showToast(err, "error");
      });
  };
  const getConnectedConnections = async (
    url = apiConstants.get_my_connections
  ) => {
    const userLocationData: any = await getGeolocation();
    let params = {
      limit,
      offset,
    };

    if (userLocationData?.currentLat && userLocationData?.currentLng) {
      params[
        "point"
      ] = `${userLocationData?.currentLng},${userLocationData?.currentLat}`;
    }
    await makeGetCall({
      url,
      params: search ? {...params, search: search} : params,
    })
      .then((res) => {
        const data = res.results;
        if (data) {
          setConnectedConnectionsList(data);
        }
        if (res?.next) {
          setNextUrl(res.next);
        } else {
          setNextUrl(null);
        }
        if (isSearchMode) {
          setCount(res?.count);
        }
        if (!isSearchMode) {
          setTotalConnections(res?.count);
        }
        setIsLoading(false);
      })
      .catch((err: any) => {
        showToast(err, "error");
      });
  };

  useEffect(() => {
    // getConnectedConnections();
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserCurrentLocation({
            lat: position.coords.latitude,
            long: position.coords.longitude,
          });
        },
        (err) => {
          showToast(
            "We kindly ask for your location to enhance your app experience; however, we regret to inform you that without this information, we are unable to proceed further.",
            "error",
            "connect"
          );
        }
      );
    } else {
      showToast(
        "It looks like geolocation is not available in your browser.",
        "error"
      );
    }
  }, []);

  useEffect(() => {
    if (search !== "") {
      setIsSearchMode(true);
      getSearchedConnectedConnections();
    }
    if (search === "") {
      setIsSearchMode(false);
      getConnectedConnections();
    }
  }, [search]);

  return (
    <div>
      {!isMobile && (
        <div className="connect-page-heading">
          {`My connections(${totalConnections})`}
        </div>
      )}
      {(connectedConnectionsList?.length !== 0 || isSearchMode) && (
        <SearchFilter
          showMap={showMap}
          handleViewToggle={handleViewToggle}
          handleSearchChange={handleSuggestionSearch}
          serachData={serachData}
          handleSeeAll={handleSearchedSeeAllClick}
          handleClearSearch={handleClearClick}
        />
      )}
      {isSearchMode && (
        <div
          style={{
            fontSize: "14px",
            fontFamily: "Poppins",
            lineHeight: "24px",
            fontWeight: "500",
            margin: "21px 0px 12px 0px",
          }}
        >{`Search results (${count})`}</div>
      )}
      {!showMap ? (
        connectedConnectionsList?.length === 0 && !isLoading ? (
          isSearchMode ? (
            <NoSearchedFilter searchTerm={search} />
          ) : (
            <NoConnections />
          )
        ) : (
          <div>
            <div className="content-title"></div>
            <div className="content-style">
              {!isLoading &&
                connectedConnectionsList.map((connection) => (
                  <ConnectUserCard
                    connectionData={connection}
                    key={connection.id}
                    mode={CONNECTION_TYPE.CONNECTION}
                    isScrollable={false}
                  />
                ))}
              {fetchingMoreData && nextUrl && renderConnectUserCardShimmer()}
              {isLoading && renderConnectUserCardShimmer(8)}
              {!isLoading && !fetchingMoreData && nextUrl && (
                <LoadOnScroll
                  nextUrl={nextUrl}
                  onLoadMore={handleLoadMore}
                  loaderComponent={false}
                />
              )}
            </div>
          </div>
        )
      ) : (
        <div className="inner-contet">
          <div className="content-title"></div>
          <ConnectMapContainer
            eventMap={connectedConnectionsList}
            latitude={userCurrentLocation.lat}
            longitude={userCurrentLocation.long}
            mode={CONNECTION_TYPE.CONNECTION}
          />
        </div>
      )}
    </div>
  );
};

export default ConnectConnections;
