import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { LISTING_TYPE } from "../../types";
import GlobalHeader from "../Dashboard/GlobalHeader";
import Button from "../../global/Button";
import BookingCard from "./BookingCard";
import SlotCard from "./SlotCard";
import { makeGetCall, makeNextPageCall } from "../../utils/Requests";
import { downloadFile, formatAmount, showToast } from "../../utils";
import endpoints from "../../utils/endpoints";
import ApiLoader from "../../global/ApiLoader";
import moment from "moment";
import TimeIcon from "../../assets/img/icons/TimeIcon";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import SlotCardTwo from "./SlotCardTwo";
import InfiniteScroll from "react-infinite-scroll-component";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { SpDateIcon } from "../../utils/Icons/svgs";
import GooglePlacesSearchBarFilterSidebar from "../../global/GooglePlacesSearchBarFilterSidebar";
import GlobalHeader2 from "../Dashboard/GlobalHeader2";
import MyTeamBookings from "./MyTeamBookings";
import GlobalFooter from "../Dashboard/GlobalFooter";
import Pagination from "../ConnectUserProfile/Pagination";
import LoadingLayer from "../ConnectUserProfile/LoaderLayer";
import ExportShortIcon from "../../assets/img/icons/ExportShortIcon.svg";
import useCheckDeviceScreen from "../../hooks/useCheckScreen";
import EarningsModal from "../EarningsModal";
import { Table } from "react-bootstrap";
import apiConfig from "../../utils/endpoints";

export default function EarnBooking() {
  const navigate = useNavigate();
  const { listing_id } = useParams();
  const [showCsvPopup, setShowCsvPopup] = useState<boolean>(false);
  const [bookingDetails, setBookingDetails] = useState<any>({});
  const [listingType, setListingType] = useState<any>("S");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [eventDetails, setEventDetails] = useState<any>(null);
  const [attendeeDetails, setAttendeeDetails] = useState([]);
  const [isViewAll, setIsViewAll] = useState(false);
  const [allEvents, setAllEvents] = useState([]);
  const [moreNextUrl, setMoreNextUrl] = useState<any>(null);
  const [location, setLocation] = useState<any>(null);
  const [teamListingData, setTeamListingData] = useState<any>();
  const [showEarningsModal, setShowEarningsModal] = useState<boolean>(false);
  const [isFeeInfoShown, setIsFeeInfoShown] = useState<boolean>(false);
  const [commisionAmounts, setCommisionAmounts] = useState<any>(null);

  const [bookings, setBookings] = useState([]);
  const [clickedEventDetail, setClickedEventDetail] = useState(null);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const {isMobile } = useCheckDeviceScreen();
  const fetchMoreData = () => {
    if (!moreNextUrl) return;
    makeNextPageCall({ url: moreNextUrl }).then((res) => {
      const tempData = [...attendeeDetails, ...res.results];
      setMoreNextUrl(res?.next);
      setAttendeeDetails(tempData as never);
    });
  };

  const fetchEarningsList = (page = 0) => {
    setLoading(true);
    makeGetCall({
      url: `${
        endpoints.mylisting_new
      }earning-events/?listing_id=${listing_id}&offset=${page * 12}&limit=12`,
    })
      .then((res) => {
        setTotalPage(Math.ceil((res?.count || 0) / 12));
        setBookings(res?.results);
        setCurrentPage(page);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };

  useEffect(() => {
    setIsLoading(true);
    setLoading(true);

    makeGetCall({
      url: `${endpoints.mylisting_new}${listing_id}/booking-details/?version=v2`,
    })
      .then((res) => {
        if (res.status.code === 200) {
          setBookingDetails(res.data);
          setListingType(res.data.listingType);
          setIsLoading(false);
          if (
            res?.data?.listingType === LISTING_TYPE.S ||
            res?.data?.listingType === LISTING_TYPE.SPECIAL
          ) {
            fetchEarningsList();
          } else {
            setBookings(res.data.bookings);
            setLoading(false);
          }
        } else {
          showToast(res?.status?.message, "error");
        }
      })
      .catch((err) => showToast(err, "error"));
  }, [listing_id]);

  useEffect(() => {
    makeGetCall({ url: apiConfig.commission_amounts })
      .then((res) => {
        if (res.status.code === 200) {
          setCommisionAmounts(res.results);
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  }, []);

  useEffect(() => {
    if (eventDetails) {
      setIsLoading(true);
      setLoading(true);
      makeGetCall({
        url: `${endpoints.mylisting_new}${eventDetails.id}/view-attendees/`,
      })
        .then((res) => {
          if (res.status.code === 200) {
            setAttendeeDetails(res.results);
            setMoreNextUrl(res?.next);
            setIsLoading(false);
            setLoading(false);
          } else {
            showToast(res?.status?.message, "error");
            setIsLoading(false);
            setLoading(false);
          }
        })
        .catch((err) => {
          showToast(err, "error");
          setIsLoading(false);
          setLoading(false);
        });
    }
  }, [eventDetails]);

  const downloadCsv = () => {
    makeGetCall({
      url: `/listings/${listing_id}/earnings-csv/`,
    })
      .then((res) => {
        downloadFile(res);
        setShowCsvPopup(false);
        if (res.status.code === 200) {
          setShowCsvPopup(false);
        } else {
          showToast(res?.status?.message, "error");
        }
      })
      .catch((err) => showToast(err, "error"));
  };

  const getListingDetails = () => {
    makeGetCall({
      url: endpoints.mylisting_new + `${listing_id}`,
    })
      .then((res) => {
        setAllEvents(res.data);
        setTeamListingData(res.data);
        setAllEvents(res?.data);
        setTeamListingData(res?.data);
        // setListingType(res.data.listingType);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);

        showToast(err.message, "error");
      });
  }

  useEffect(() => {
    getListingDetails();
  }, [listing_id]);

  return (
    <>
      <GlobalHeader2 />
      <div className="user-profile create_listing">
        <div className="earn-booking-wrap">
          {loading && <LoadingLayer />}
          <div className="booking-title-box">
            {/* <img
              src="/static/leftarrow.svg"
              alt=""
              className="back_arrow"
              onClick={() => {
                if (eventDetails) {
                  setEventDetails(null);
                } else {
                  setEventDetails(null);
                  navigate(-1);
                }
              }}
            /> */}
            {/* <div className="main-title">Bookings</div> */}
            <div />
            {/* {!eventDetails ? (
              <img
                alt=""
                src="/static/document-right.svg"
                className="document-download"
                onClick={() => setShowCsvPopup(true)}
              />
            ) : (
              <div />
            )} */}
          </div>
          {isLoading ? (
            <ApiLoader />
          ) : eventDetails ? (
            <>
              <div className="event-booking-detail-wrap">
                <div className="title-wrap">
                  <div className="title">{eventDetails.title}</div>
                  <div className="chat-btn" style={{ cursor: "auto" }}>
                    <div style={{ display: "inline-flex", gap: "24px" }}>
                      {listingType === LISTING_TYPE.S ||
                      listingType === LISTING_TYPE.SPECIAL ? null : (
                        <span
                          onClick={() =>
                            navigate(
                              ROUTE_CONSTANTS.chat +
                                `?chatId=${bookingDetails.chatRoom}`
                            )
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                          >
                            <path
                              d="M16 6C21.5228 6 26 10.4772 26 16C26 21.5228 21.5228 26 16 26C14.3817 26 12.8178 25.6146 11.4129 24.888L7.58704 25.9553C6.92212 26.141 6.23258 25.7525 6.04691 25.0876C5.98546 24.8676 5.98549 24.6349 6.04695 24.4151L7.11461 20.5922C6.38637 19.186 6 17.6203 6 16C6 10.4772 10.4772 6 16 6ZM16 7.5C11.3056 7.5 7.5 11.3056 7.5 16C7.5 17.4696 7.87277 18.8834 8.57303 20.1375L8.72368 20.4072L7.61096 24.3914L11.5976 23.2792L11.8671 23.4295C13.1201 24.1281 14.5322 24.5 16 24.5C20.6944 24.5 24.5 20.6944 24.5 16C24.5 11.3056 20.6944 7.5 16 7.5ZM12.75 17H17.2483C17.6625 17 17.9983 17.3358 17.9983 17.75C17.9983 18.1297 17.7161 18.4435 17.35 18.4932L17.2483 18.5H12.75C12.3358 18.5 12 18.1642 12 17.75C12 17.3703 12.2822 17.0565 12.6482 17.0068L12.75 17H17.2483H12.75ZM12.75 13.5L19.2545 13.5C19.6687 13.5 20.0045 13.8358 20.0045 14.25C20.0045 14.6297 19.7223 14.9435 19.3563 14.9932L19.2545 15H12.75C12.3358 15 12 14.6642 12 14.25C12 13.8703 12.2822 13.5565 12.6482 13.5068L12.75 13.5L19.2545 13.5L12.75 13.5Z"
                              fill="#8F939A"
                            />
                          </svg>
                        </span>
                      )}

                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowCsvPopup(true)}
                      >
                        <img src={isMobile ? ExportShortIcon : "/static/exportbtn.svg" } alt="" />
                      </span>
                    </div>
                  </div>
                  {<img alt="" src="/static/session34.svg" />}
                  <div>
                    {bookingDetails.listingEventType === "V" ? (
                      <img alt="" src="/static/virtualweb.svg" />
                    ) : (
                      <img alt="" src="/static/physicalweb.svg" />
                    )}
                  </div>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "16px" }}
                >
                  <div className="date">
                    {moment(eventDetails.date).format("dddd")},
                    {moment(eventDetails.date).format("Do MMMM")}
                  </div>
                  <div className="content-items">
                    <TimeIcon />
                    <span className="sub-title">
                      {moment(eventDetails?.startTime?.substring(0, 5), [
                        "HH:mm",
                      ]).format("hh:mmA")}{" "}
                      -{" "}
                      {moment(eventDetails?.endTime?.substring(0, 5), [
                        "HH:mm",
                      ]).format("hh:mmA")}
                    </span>
                  </div>
                </div>
                <div className="sub-title-wrap">
                  <div className="subtitle">
                    <img alt="" src="/static/total-earn-tag.svg" />

                    <div>Total Bookings:</div>
                    <div>
                      {eventDetails.totalBooking || (
                        <div className="spot-text">No Booking</div>
                      )}
                    </div>
                  </div>
                  <div className="subtitle">
                    <div>
                      <img alt="" src="/static/money-earn-tag.svg" />
                    </div>
                    <div>Total Earnings:</div>
                    <div className="yellow-money">
                      AUD {eventDetails?.earnings || 0}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="copy-link-box-booking-details">
                    <div className="orange-box virtual_spacing">
                      {eventDetails?.listingEventType === "V"
                        ? "Meet link"
                        : "Map Location"}
                    </div>
                    <div className="link-box virtual_spacing">
                      <div className="link_text">
                        {eventDetails?.listingEventType === "V"
                          ? eventDetails?.virtualLink
                          : `https://www.google.com/maps/place/
                    ${Number(eventDetails.address.latitude)},
                    ${Number(eventDetails.address.longitude)}
                   `}
                      </div>
                      <div>
                        <CopyToClipboard
                          onCopy={() =>
                            showToast("copied to clipboard", "success")
                          }
                          text={
                            eventDetails?.listingEventType === "V"
                              ? eventDetails.virtualLink
                              : `https://www.google.com/maps/place/
                    ${Number(eventDetails.address.latitude)},
                    ${Number(eventDetails.address.longitude)}
                   `
                          }
                        >
                          <img alt="" src="/static/copyblue.svg" />
                        </CopyToClipboard>
                      </div>
                    </div>
                  </div>

                  {/* <CopyToClipboard
                    onCopy={() => showToast("copied to clipboard", "success")}
                    text={
                      eventDetails?.listingEventType === "V"
                        ? eventDetails.virtualLink
                        : `https://www.google.com/maps/place/
                    ${Number(eventDetails.address.latitude)},
                    ${Number(eventDetails.address.longitude)}
                   `
                    }
                  >
                    <div
                      className="copy-link-box-booking-details"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="orange-box">
                        {eventDetails?.listingEventType === "V"
                          ? "Meet link"
                          : "Map Location"}
                      </div>
                      <div className="copy-box">
                        <span>
                          {eventDetails?.listingEventType === "V"
                            ? eventDetails?.virtualLink
                            : "Google map location"}
                        </span>
                        <span className="copy-btn">
                          <img alt="" src="/static/copyblue.svg" />
                        </span>
                      </div>
                    </div>
                  </CopyToClipboard> */}
                </div>
              </div>
              <div className="my-4 earning-composition-wrap">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 style={{ fontWeight: 600 }} className="mb-0">
                    Earnings composition
                  </h5>
                </div>
                <div className="d-flex flex-column gap-2 my-3">
                  <div className="earnings-amount-wrap">
                    <div>Total amount paid</div>
                    <div style={{ fontWeight: 600 }}>AUD {clickedEventDetail?.eventAmountPaid.toFixed(2)}</div>
                  </div>
                  <div className="earnings-amount-wrap">
                    <div>Platform Fee</div>
                    <div style={{ fontWeight: 600 }}>AUD {clickedEventDetail?.eventPlatformFee.toFixed(2)}</div>
                  </div>
                  <div className="earnings-amount-wrap">
                    <div>Merchant Fee</div>
                    <div style={{ fontWeight: 600 }}>AUD {clickedEventDetail?.eventBookingFee.toFixed(2)}</div>
                  </div>
                </div>
                <hr className="my-2" style={{ color: "#DDDDDD", opacity: 1 }} />
                <div>
                  <div className="earnings-amount-wrap">
                    <div style={{ fontWeight: 600 }}>Total Earnings</div>
                    <div style={{ fontWeight: 700 }}>AUD {clickedEventDetail?.earnings.toFixed(2)}</div>
                  </div>
                </div>
              </div>
              <div className="info-box-booking-head-earning mb-3 ">
                <img
                  alt=""
                  src="/static/info-sm.svg"
                  width="16px"
                  height="16px"
                />
                <span style={{ maxWidth: "721px", fontSize: "12px" }}>
                  <span
                    style={{
                      color: "black",
                      fontWeight: 500,
                      textDecoration: "underline",
                    }}
                    onClick={() => setIsFeeInfoShown(!isFeeInfoShown)}
                    className="cursor-pointer"
                  >
                    Click here{" "}
                  </span>
                  to view the amount deducted per participant.
                </span>
              </div>
              {isFeeInfoShown ? (
                <div className="earning-box-wrap my-earning-table">
                  <Table responsive borderless bsPrefix="pay-table">
                    <thead className="pay-t-header">
                      <tr>
                        <th className="first">Number of participants</th>
                        <th className="second">
                          Platform fixed Fee per booking per person
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {commisionAmounts?.map((item, index) => {
                        return (
                          <tr>
                            <td>
                              {item.minimumSlots}{" "}
                              {item.minimumSlots === item.maximumSlots
                                ? ""
                                : item.maximumSlots === null
                                ? "plus"
                                : `to ${item.maximumSlots}`}
                            </td>
                            <td>AUD {item.commissionAmount}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              ) : (
                ""
              )}
              {eventDetails?.eventBookings ? (
                <div className="slot-list-wrap">
                  <InfiniteScroll
                    dataLength={attendeeDetails?.length}
                    next={fetchMoreData}
                    hasMore={moreNextUrl ? true : false}
                    loader={<ApiLoader />}
                  >
                    {attendeeDetails?.map((item) => (
                      <SlotCard data={item} key={item?.id} />
                    ))}
                  </InfiniteScroll>
                </div>
              ) : (
                <div style={{ margin: "60px auto", textAlign: "center" }}>
                  <img alt="" src="/static/nobooking.svg" />
                </div>
              )}
            </>
          ) : isViewAll ? (
            <div>
              <>
                <div className="event-booking-detail-wrap">
                  <div className="title-wrap">
                    <div className="title">{bookingDetails.title}</div>
                    <img alt="" src="/static/package34.svg" />
                    <div>
                      {bookingDetails.listingEventType === "V" ? (
                        <img alt="" src="/static/virtualweb.svg" />
                      ) : (
                        <img alt="" src="/static/physicalweb.svg" />
                      )}
                    </div>
                  </div>

                  <div className="total-desc">
                    Total Events Included:{" "}
                    <span>{allEvents?.events?.length}</span>
                  </div>
                </div>

                {allEvents?.events?.length > 0 ? (
                  <div className="total-list-wrap">
                    <InfiniteScroll
                      dataLength={attendeeDetails?.length}
                      next={fetchMoreData}
                      hasMore={moreNextUrl ? true : false}
                      loader={<ApiLoader />}
                    >
                      <div className="session-card-earning-details">
                        {allEvents.events.map((item, index) => (
                          <div
                            key={index}
                            className="session-card-earning-wrap"
                          >
                            <div className="title-box">
                              <h5 className="col-9">{item?.title}</h5>
                              <div className="number-circle">{++index}</div>
                            </div>
                            {item.listingEventType === "P" ? (
                              <div className="address-box">
                                <img src="/static/location-shape.svg" alt="" />
                                <p style={{ width: "60%" }}>
                                  {item?.address?.address}
                                </p>
                                <h4
                                  className="open-map"
                                  onClick={() => {
                                    setLocation(item?.address?.location);
                                  }}
                                >
                                  Open Maps
                                </h4>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  fontSize: "14px",
                                  lineHeight: "24px",
                                  fontFamily: "Poppins",
                                  color: "#25272D",
                                }}
                              >
                                <img
                                  src="/static/createListingIcon/linkGrey.svg"
                                  alt=""
                                  style={{ marginRight: "4px" }}
                                />
                                <span>
                                  <a
                                    target="_blank"
                                    style={{
                                      color: "#25272D",
                                    }}
                                    href={item.virtualLink || ""}
                                    rel="noreferrer"
                                  >
                                    Meet Link
                                  </a>
                                  <span
                                    style={{
                                      color: "#0099FF",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <CopyToClipboard
                                      onCopy={() =>
                                        showToast(
                                          "copied to clipboard",
                                          "success"
                                        )
                                      }
                                      text={item.virtualLink}
                                    >
                                      <span>
                                        <img
                                          style={{
                                            marginRight: "4px",
                                            marginLeft: "6px",
                                          }}
                                          src="/static/createListingIcon/copyBlue.svg"
                                          alt=""
                                        />
                                        <span>Copy</span>
                                      </span>
                                    </CopyToClipboard>
                                  </span>
                                </span>
                              </div>
                            )}
                            <div className="date-time-box ">
                              <span>
                                <SpDateIcon />
                                {moment(item?.date).date()}{" "}
                                {moment(item?.date).format("MMM")},{" "}
                                {moment(item?.date).year()}{" "}
                              </span>
                              <span>
                                <img
                                  src="/static/clock.svg"
                                  alt="clock"
                                  width={16}
                                  height={16}
                                />
                                {moment(item?.startTime?.substring(0, 5), [
                                  "HH:mm",
                                ]).format("hh:mmA")}{" "}
                                -{" "}
                                {moment(item?.endTime?.substring(0, 5), [
                                  "HH:mm",
                                ]).format("hh:mmA")}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </InfiniteScroll>
                  </div>
                ) : (
                  <div style={{ margin: "60px auto", textAlign: "center" }}>
                    <img alt="" src="/static/nobooking.svg" />
                  </div>
                )}
              </>
            </div>
          ) : (
            <>
              {listingType === LISTING_TYPE.S ||
              listingType === LISTING_TYPE.SPECIAL ? (
                <>
                  <div className="booking-details-box">
                    <div className="title-wrap">
                      <div className="title">{bookingDetails.title}</div>
                      <div className="chat-btn" style={{ cursor: "auto" }}>
                        <div style={{ display: "inline-flex", gap: "24px" }}>
                          {/* <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (bookingDetails?.chatRoom) {
                                navigate(
                                  ROUTE_CONSTANTS.chat +
                                    `?chatId=${bookingDetails.chatRoom}`
                                );
                              } else {
                                navigate(
                                  ROUTE_CONSTANTS.chat +
                                    `?id=${bookingDetails.listing}`
                                );
                              }
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                            >
                              <path
                                d="M16 6C21.5228 6 26 10.4772 26 16C26 21.5228 21.5228 26 16 26C14.3817 26 12.8178 25.6146 11.4129 24.888L7.58704 25.9553C6.92212 26.141 6.23258 25.7525 6.04691 25.0876C5.98546 24.8676 5.98549 24.6349 6.04695 24.4151L7.11461 20.5922C6.38637 19.186 6 17.6203 6 16C6 10.4772 10.4772 6 16 6ZM16 7.5C11.3056 7.5 7.5 11.3056 7.5 16C7.5 17.4696 7.87277 18.8834 8.57303 20.1375L8.72368 20.4072L7.61096 24.3914L11.5976 23.2792L11.8671 23.4295C13.1201 24.1281 14.5322 24.5 16 24.5C20.6944 24.5 24.5 20.6944 24.5 16C24.5 11.3056 20.6944 7.5 16 7.5ZM12.75 17H17.2483C17.6625 17 17.9983 17.3358 17.9983 17.75C17.9983 18.1297 17.7161 18.4435 17.35 18.4932L17.2483 18.5H12.75C12.3358 18.5 12 18.1642 12 17.75C12 17.3703 12.2822 17.0565 12.6482 17.0068L12.75 17H17.2483H12.75ZM12.75 13.5L19.2545 13.5C19.6687 13.5 20.0045 13.8358 20.0045 14.25C20.0045 14.6297 19.7223 14.9435 19.3563 14.9932L19.2545 15H12.75C12.3358 15 12 14.6642 12 14.25C12 13.8703 12.2822 13.5565 12.6482 13.5068L12.75 13.5L19.2545 13.5L12.75 13.5Z"
                                fill="#8F939A"
                              />
                            </svg>
                          </span> */}
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowCsvPopup(true)}
                          >
                            <img src={isMobile ? ExportShortIcon : "/static/exportbtn.svg" } alt="" />
                          </span>
                        </div>
                      </div>

                      <img alt="" src="/static/session34.svg" />
                      <div>
                        {bookingDetails.listingEventType === "V" ? (
                          <img alt="" src="/static/virtualweb.svg" />
                        ) : (
                          <img alt="" src="/static/physicalweb.svg" />
                        )}
                      </div>
                    </div>
                    <div className="sub-title-wrap">
                      <div className="subtitle">
                        <img alt="" src="/static/total-earn-tag.svg" />

                        <div>Total Sessions:</div>
                        <div>{bookingDetails?.totalSession}</div>
                      </div>
                      <div className="subtitle">
                        <div>
                          <img alt="" src="/static/money-earn-tag.svg" />
                        </div>
                        <div>Total Earnings:</div>
                        <div className="yellow-money">
                          AUD {bookingDetails?.totalEarnings || 0}
                        </div>
                      </div>
                    </div>
                    <div className="info-box-booking-head-earning mb-3">
                      <img
                        alt=""
                        src="/static/info-sm.svg"
                        width="16px"
                        height="16px"
                      />
                      <span className="d-flex flex-column gap-1">
                        <div
                          style={{
                            color: "black",
                            fontWeight: 500,
                            lineHeight: 1,
                          }}
                        >
                          Platform Fee: {bookingDetails?.totalPlatformFee?.toFixed(2)} AUD
                        </div>
                        <span>
                          <span style={{ color: "black", fontWeight: 500 }}>
                            Note:{" "}
                          </span>
                          Your earnings are subjective to deduction of platform
                          fee as well as merchant fee, in order to gain more
                          knowledge
                          <span
                            style={{
                              color: "#ee762f",
                              textDecoration: "underline",
                              fontWeight: 600,
                            }}
                            className="cursor-pointer"
                            onClick={() => setShowEarningsModal(true)}
                          >
                            {" "}
                            Click here.
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>

                  {isLoading ? (
                    <ApiLoader />
                  ) : bookings?.length > 0 ? (
                    <>
                      <div className="booking-list-wrap">
                        {bookings.map((i) => (
                          <BookingCard
                            key={i.id}
                            id={i.id}
                            chatRoom={i?.chatRoom || null}
                            date={i.date}
                            slotsAvailable={i.slotsAvailable}
                            totalSlots={i.totalSlots}
                            earnings={i.earnings}
                            eventBookings={i.eventBookings}
                            earningDetailClick={() => {
                              setEventDetails(i);
                              const filteredData = bookingDetails?.bookings.find((item) => (i?.id === item?.id))
                              setClickedEventDetail(filteredData)
                            }}
                            bookersImage={i.bookersImage}
                            address={i.address}
                            listingEventType={i.listingEventType}
                            virtualLink={i.virtualLink}
                            startTime={i.startTime}
                            endTime={i.endTime}
                          />
                        ))}
                      </div>
                      <div className="pagination_container_single_earning">
                        <Pagination
                          currentPage={currentPage}
                          pageCount={totalPage}
                          handlePageClick={(event) =>
                            fetchEarningsList(event.selected)
                          }
                        />
                      </div>
                    </>
                  ) : (
                    <div style={{ margin: "60px auto", textAlign: "center" }}>
                      <img alt="" src="/static/nobooking.svg" />
                    </div>
                  )}

                  {/* <div style={{ maxWidth: "546px", margin: "0 auto" }}>
                    <Button
                      btnTxt="Back to the screen"
                      className={"backtologin"}
                      onClick={() => navigate(-1)}
                    />
                  </div> */}
                </>
              ) : listingType === LISTING_TYPE.M ? (
                <>
                  <div className="booking-details-box">
                    <div className="title-wrap">
                      <div className="title">{bookingDetails?.title}</div>

                      <div className="chat-btn" style={{ cursor: "auto" }}>
                        <div style={{ display: "inline-flex", gap: "24px" }}>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (bookingDetails?.chatRoom) {
                                navigate(
                                  ROUTE_CONSTANTS.chat +
                                    `?chatId=${bookingDetails.chatRoom}`
                                );
                              } else {
                                navigate(
                                  ROUTE_CONSTANTS.chat +
                                    `?id=${bookingDetails.listing}`
                                );
                              }
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                            >
                              <path
                                d="M16 6C21.5228 6 26 10.4772 26 16C26 21.5228 21.5228 26 16 26C14.3817 26 12.8178 25.6146 11.4129 24.888L7.58704 25.9553C6.92212 26.141 6.23258 25.7525 6.04691 25.0876C5.98546 24.8676 5.98549 24.6349 6.04695 24.4151L7.11461 20.5922C6.38637 19.186 6 17.6203 6 16C6 10.4772 10.4772 6 16 6ZM16 7.5C11.3056 7.5 7.5 11.3056 7.5 16C7.5 17.4696 7.87277 18.8834 8.57303 20.1375L8.72368 20.4072L7.61096 24.3914L11.5976 23.2792L11.8671 23.4295C13.1201 24.1281 14.5322 24.5 16 24.5C20.6944 24.5 24.5 20.6944 24.5 16C24.5 11.3056 20.6944 7.5 16 7.5ZM12.75 17H17.2483C17.6625 17 17.9983 17.3358 17.9983 17.75C17.9983 18.1297 17.7161 18.4435 17.35 18.4932L17.2483 18.5H12.75C12.3358 18.5 12 18.1642 12 17.75C12 17.3703 12.2822 17.0565 12.6482 17.0068L12.75 17H17.2483H12.75ZM12.75 13.5L19.2545 13.5C19.6687 13.5 20.0045 13.8358 20.0045 14.25C20.0045 14.6297 19.7223 14.9435 19.3563 14.9932L19.2545 15H12.75C12.3358 15 12 14.6642 12 14.25C12 13.8703 12.2822 13.5565 12.6482 13.5068L12.75 13.5L19.2545 13.5L12.75 13.5Z"
                                fill="#8F939A"
                              />
                            </svg>
                          </span>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setShowCsvPopup(true);
                            }}
                          >
                            <img src={isMobile ? ExportShortIcon : "/static/exportbtn.svg" } alt="" />
                          </span>
                        </div>
                      </div>

                      <img alt="" src="/static/package34.svg" />

                      <div>
                        {bookingDetails.listingEventType === "V" ? (
                          <img alt="" src="/static/virtualweb.svg" />
                        ) : (
                          <img alt="" src="/static/physicalweb.svg" />
                        )}
                      </div>
                    </div>
                    <div className="sub-title-wrap">
                      <div className="subtitle">
                        <img alt="" src="/static/multiple-earn-tag.svg" />
                        <div>Total Bookings:</div>
                        <div>{`${bookingDetails?.totalBookings}`}</div>
                        out of {bookingDetails?.maxParticipants} spots
                      </div>

                      <div className="subtitle">
                        <div>
                          <img alt="" src="/static/money-earn-tag.svg" />
                        </div>
                        <div>Total Earnings:</div>
                        <div className="yellow-money">
                          AUD {bookingDetails?.totalEarnings || 0}
                        </div>
                      </div>
                    </div>
                    <div className="sub-title-wrap">
                      <div className="subtitle">
                        <img alt="" src="/static/total-earn-tag.svg" />
                        <div>Total No. of Events:</div>
                        <div className="orange-text-number">
                          {/* {bookingDetails?.maxParticipants -
                            bookingDetails?.totalBookings} */}
                          {bookingDetails.totalSession}{" "}
                          <span
                            className="underline-view-all"
                            onClick={() => setIsViewAll(true)}
                          >
                            View all
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="info-box-booking-head-earning mb-3">
                      <img
                        alt=""
                        src="/static/info-sm.svg"
                        width="16px"
                        height="16px"
                      />
                      <span className="d-flex flex-column gap-1">
                        <div
                          style={{
                            color: "black",
                            fontWeight: 500,
                            lineHeight: 1,
                          }}
                        >
                          Platform Fee: {bookingDetails?.totalPlatformFee?.toFixed(2)} AUD
                        </div>
                        <span>
                          <span style={{ color: "black", fontWeight: 500 }}>
                            Note:{" "}
                          </span>
                          Your earnings are subjective to deduction of platform
                          fee as well as merchant fee, in order to gain more
                          knowledge
                          <span
                            style={{
                              color: "#ee762f",
                              textDecoration: "underline",
                              fontWeight: 600,
                            }}
                            className="cursor-pointer"
                            onClick={() => setShowEarningsModal(true)}
                          >
                            {" "}
                            Click here.
                          </span>
                        </span>
                      </span>
                    </div>

                    {isLoading ? (
                      <ApiLoader />
                    ) : bookings.length > 0 ? (
                      <div className="slot-list-wrap">
                        {bookings.map((item) => (
                          <SlotCardTwo data={item} key={item.bookedBy.id} />
                        ))}
                      </div>
                    ) : (
                      <div style={{ margin: "60px auto", textAlign: "center" }}>
                        <img alt="" src="/static/nobooking.svg" />
                      </div>
                    )}

                    {/* <div style={{ maxWidth: "546px", margin: "0 auto" }}>
                      <Button
                        btnTxt="Back to the screen"
                        className={"backtologin"}
                        onClick={() => navigate(-1)}
                      />
                    </div> */}
                  </div>
                </>
              ) : listingType === LISTING_TYPE.C ||
                teamListingData?.listingType === LISTING_TYPE.C ? (
                <MyTeamBookings
                  teamListingId={listing_id}
                  teamData={teamListingData}
                  handleUpdate={getListingDetails}
                />
              ) : /*  <div className="booking-details-box">
                  <div className="title-wrap">
                    <div className="title">{bookingDetails?.title}</div>
                    <div className="chat-btn" style={{ cursor: "auto" }}>
                      <div style={{ display: "inline-flex", gap: "24px" }}>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (bookingDetails?.chatRoom) {
                              navigate(
                                ROUTE_CONSTANTS.chat +
                                  `?chatId=${bookingDetails.chatRoom}`
                              );
                            } else {
                              navigate(
                                ROUTE_CONSTANTS.chat +
                                  `?id=${bookingDetails.listing}`
                              );
                            }
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                          >
                            <path
                              d="M16 6C21.5228 6 26 10.4772 26 16C26 21.5228 21.5228 26 16 26C14.3817 26 12.8178 25.6146 11.4129 24.888L7.58704 25.9553C6.92212 26.141 6.23258 25.7525 6.04691 25.0876C5.98546 24.8676 5.98549 24.6349 6.04695 24.4151L7.11461 20.5922C6.38637 19.186 6 17.6203 6 16C6 10.4772 10.4772 6 16 6ZM16 7.5C11.3056 7.5 7.5 11.3056 7.5 16C7.5 17.4696 7.87277 18.8834 8.57303 20.1375L8.72368 20.4072L7.61096 24.3914L11.5976 23.2792L11.8671 23.4295C13.1201 24.1281 14.5322 24.5 16 24.5C20.6944 24.5 24.5 20.6944 24.5 16C24.5 11.3056 20.6944 7.5 16 7.5ZM12.75 17H17.2483C17.6625 17 17.9983 17.3358 17.9983 17.75C17.9983 18.1297 17.7161 18.4435 17.35 18.4932L17.2483 18.5H12.75C12.3358 18.5 12 18.1642 12 17.75C12 17.3703 12.2822 17.0565 12.6482 17.0068L12.75 17H17.2483H12.75ZM12.75 13.5L19.2545 13.5C19.6687 13.5 20.0045 13.8358 20.0045 14.25C20.0045 14.6297 19.7223 14.9435 19.3563 14.9932L19.2545 15H12.75C12.3358 15 12 14.6642 12 14.25C12 13.8703 12.2822 13.5565 12.6482 13.5068L12.75 13.5L19.2545 13.5L12.75 13.5Z"
                              fill="#8F939A"
                            />
                          </svg>
                        </span>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowCsvPopup(true)}
                        >
                          <img src="/static/exportbtn.svg" alt="" />
                        </span>
                      </div>
                    </div>
                    <div className="list-chip">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          d="M4.82143 4.61111C5.10165 4.2718 5.52557 4.05556 6 4.05556C6.47443 4.05556 6.89835 4.2718 7.17857 4.61111C7.39672 4.87526 7.52778 5.21399 7.52778 5.58333C7.52778 6.41407 6.86474 7.08994 6.03899 7.11062C6.02603 7.11095 6.01303 7.11111 6 7.11111C5.98697 7.11111 5.97397 7.11095 5.96101 7.11062C5.13526 7.08994 4.47222 6.41407 4.47222 5.58333C4.47222 5.21399 4.60328 4.87526 4.82143 4.61111ZM8.08333 5.58333C8.08333 5.2322 7.99646 4.90135 7.84304 4.61111H10.1667C10.6269 4.61111 11 4.98421 11 5.44444V5.72222C11 6.60412 10.3302 7.5066 9.15841 7.82472C8.92111 7.39904 8.4664 7.11111 7.94444 7.11111H7.41641C7.82662 6.73063 8.08333 6.18696 8.08333 5.58333ZM7.94444 7.66667C8.18446 7.66667 8.40078 7.76814 8.55284 7.93052C8.69236 8.07951 8.77778 8.27978 8.77778 8.5V8.77778C8.77778 9.87302 7.74472 11 6 11C4.25528 11 3.22222 9.87302 3.22222 8.77778V8.5C3.22222 8.27978 3.30764 8.07951 3.44716 7.93052C3.59922 7.76814 3.81554 7.66667 4.05556 7.66667H7.94444ZM1 5.72222C1 6.60412 1.6698 7.5066 2.84159 7.82472C3.07889 7.39904 3.5336 7.11111 4.05556 7.11111H4.58359C4.17338 6.73063 3.91667 6.18696 3.91667 5.58333C3.91667 5.2322 4.00354 4.90135 4.15696 4.61111H1.83333C1.3731 4.61111 1 4.98421 1 5.44444V5.72222ZM5.30556 2.52778C5.30556 1.68401 4.62155 1 3.77778 1C2.93401 1 2.25 1.68401 2.25 2.52778C2.25 3.37155 2.93401 4.05556 3.77778 4.05556C4.62155 4.05556 5.30556 3.37155 5.30556 2.52778ZM9.75 2.52778C9.75 1.68401 9.06599 1 8.22222 1C7.37845 1 6.69444 1.68401 6.69444 2.52778C6.69444 3.37155 7.37845 4.05556 8.22222 4.05556C9.06599 4.05556 9.75 3.37155 9.75 2.52778Z"
                          fill="url(#paint0_linear_3359_67303)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_3359_67303"
                            x1="-0.820513"
                            y1="-0.486967"
                            x2="3.60218"
                            y2="18.7746"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#EE7830" />
                            <stop offset="1" stop-color="#EE762F" />
                          </linearGradient>
                        </defs>
                      </svg>
                      Team
                    </div>
                    <div>
                      {bookingDetails.listingEventType === "V" ? (
                        <img alt="" src="/static/virtualweb.svg" />
                      ) : (
                        <img alt="" src="/static/physicalweb.svg" />
                      )}
                    </div>
                  </div>
                  <div className="sub-title-wrap">
                    <div className="subtitle">
                      <img alt="" src="/static/multiple-earn-tag.svg" />
                      <div>Total Bookings:</div>
                      {bookingDetails?.totalBookings ? (
                        <div>{bookingDetails?.totalBookings} </div>
                      ) : (
                        <div className="spot-text">No Booking</div>
                      )}
                    </div>
                    <div className="subtitle">
                      <div>
                        <img alt="" src="/static/money-earn-tag.svg" />
                      </div>
                      <div>Total Earnings:</div>
                      <div className="yellow-money">
                        $
                        {bookingDetails?.totalEarnings
                          ? formatAmount(bookingDetails?.totalEarnings) || 0
                          : 0}
                      </div>
                    </div>
                  </div>
                  {isLoading ? (
                    <ApiLoader />
                  ) : bookings.length > 0 ? (
                    <div className="slot-list-wrap">
                      {bookings.map((item, index) => (
                        <SlotCardTwo data={item} key={index} />
                      ))}
                    </div>
                  ) : (
                    <div style={{ margin: "60px auto", textAlign: "center" }}>
                      <img alt="" src="/static/nobooking.svg" />
                    </div>
                  )}
                  <div style={{ maxWidth: "546px", margin: "0 auto" }}>
                    <Button
                      btnTxt="Back to the screen"
                      className={"backtologin"}
                      onClick={() => navigate(-1)}
                    />
                  </div>
                </div> */
              null}
            </>
          )}
        </div>
        {showCsvPopup ? (
          <div className={"modal show"}>
            <div className="modal-section log_out_modal csv-modal-wrap">
              <div className="close-btn-csv">
                <img
                  src="/static/cross-black.svg"
                  onClick={() => {
                    setShowCsvPopup(false);
                  }}
                  className="close-btn hide-large"
                  alt=""
                />
              </div>
              <div className="modal-top-part">
                <div>
                  <img alt="" src="/static/file-type-excel.svg" />
                </div>

                <h5>Export Your Earnings</h5>
                <p>
                  Effortlessly transfer your earnings to an Excel spreadsheet
                  for easy organization and analysis.
                </p>
              </div>
              <div className="button_option">
                <Button
                  btnTxt="Download as Excel(.csv)"
                  className={"backtologin"}
                  onClick={downloadCsv}
                />
                <Button
                  btnTxt="Not now"
                  className={"backtologin notnow"}
                  onClick={() => setShowCsvPopup(false)}
                />
              </div>
            </div>
          </div>
        ) : null}
        {location ? (
          <div className="fixed-sidebar-left">
            <div className="sidebar-box" style={{ maxWidth: "50%" }}>
              <img
                src="/static/cross-black.svg"
                className="close-btn hide-large"
                alt=""
                onClick={() => {
                  setLocation(null);
                }}
              />
              <GooglePlacesSearchBarFilterSidebar location={location} />
            </div>
          </div>
        ) : null}
      </div>
      <EarningsModal
        show={showEarningsModal}
        handleClose={() => setShowEarningsModal(false)}
        totalAmount={bookingDetails?.totalAmountPaid?.toFixed(2)}
        platformFee={bookingDetails?.totalPlatformFee?.toFixed(2)}
        bookingFee={bookingDetails?.totalBookingFee?.toFixed(2)}
        totalEarnings={bookingDetails?.totalEarnings?.toFixed(2)}
      />

      {/* <GlobalFooter /> */}
    </>
  );
}
