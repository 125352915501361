import React, { useEffect, useRef } from "react";

const LoadOnScroll = ({ nextUrl, onLoadMore, loaderComponent }) => {
  const loadMoreRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && nextUrl) {
          onLoadMore(); // Trigger data loading
        }
      },
      { threshold: 1.0 } // Fully visible in the viewport
    );

    const currentElement = loadMoreRef.current;
    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, [nextUrl, onLoadMore]);

  return (
    <div ref={loadMoreRef}>
      {loaderComponent || (
        <div>
         {/*  <img
            style={{ width: "50px" }}
            src="/static/spinner.svg"
            alt="loader"
          />
          <div>Loading...</div> */}
        </div>
      )}
    </div>
  );
};

export default React.memo(LoadOnScroll);
