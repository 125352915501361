/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Button from "../../../global/Button";
import InputField from "../../../global/InputField";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  dateFormatter,
  getItemFromStore,
  handleLocation,
  isObjEmpty,
  showToast,
} from "../../../utils";
import { apiConstants } from "../../../utils/Constants";
import endpoints from "../../../utils/endpoints";
import { makeGetCall, makePatchCall } from "../../../utils/Requests";
import { STRING_CONSTANTS } from "../../../utils/StringConstant";
import { PersonalDetailsValidationGuest } from "../../Profile/ProfileValidations";

import DashboardWrapper from "../../Dashboard/DashboardWrapper";
import MobileInput from "../../../global/MobileInput";
import { USER_TYPE } from "../../../types";
import useCheckDeviceScreen from "../../../hooks/useCheckScreen";
import GooglePlacesSearchBar from "../../../global/GooglePlaceSelector";
import GooglePlacesSearchLocation from "../../../global/GooglePlacesSearchLocation";
import PhoneNumberInput from "../../PhoneNumberInput/PhoneNumberInput";
import { useAppSelector } from "../../../store/store";
import PrimaryButton from "../../Button/PrimaryButton";

const BusinessDetails = () => {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors, dirtyFields },
    reset,
  } = useForm({
    mode: "onSubmit",
  });
  const navigate = useNavigate();
  const [userUpdate, setUserUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUserProfileChange, setIsUserProfileChange] = useState(false);
  const [abnResponse, setAbnResponse] = useState<any>("");
  const [countryCode, setCountryCode] = useState<any>("");
  const { isMobile } = useCheckDeviceScreen();

  const [bussLocation, setBussLocation] = useState(
    watch(apiConstants.address) || null
  );

  const updateprofile = (formData: any) => {
    setIsLoading(true);
    makePatchCall({
      url: endpoints.profile,
      apiPayload: formData,
      content_type: "multipart/form-data",
    })
      .then((res) => {
        if (res.status.code === 200) {
          showToast(res.status.message, "success");
          setUserUpdate(true);
          setIsUserProfileChange(!isUserProfileChange);
        } else {
          showToast(
            res.status.message || STRING_CONSTANTS.wrong_data_message,
            "error"
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        showToast(err, "error");
        setIsLoading(false);
      });
  };

  const formData = new FormData();
  const updatedValues: any = {};

  const checkNumberIsAdded = () => {
    const numberWithoutCountryCode = getValues(
      apiConstants.businessContactNumber
    ).startsWith(countryCode?.dialCode)
      ? getValues(apiConstants.businessContactNumber)?.slice(
          countryCode?.dialCode?.length
        )
      : getValues(apiConstants.businessContactNumber);
    return numberWithoutCountryCode;
  };

  const handleNext = () => {
    if (
      getItemFromStore("userProfile")?.userType !== USER_TYPE.GUEST &&
      watch(apiConstants.address) &&
      watch(apiConstants.address) !== bussLocation
    ) {
      showToast("Please select business address from the suggestion.", "error");
      return;
    }
    handleSubmit(onSubmit)();
  };

  const onSubmit = async (data: any) => {
    if (dirtyFields?.abn && getValues(apiConstants.abn)) {
      let isValid = false
      const resp: any =  await makeGetCall({
        url: `auth/${getValues(apiConstants.abn)}/validate-abn/`,
      })
        .then((res) => res)
        .catch((err) => {
          showToast(err, "error");
        });

        if (resp.status.code === 200) {
          if (resp.data.AbnStatus === "Active") {
            isValid = true;
            // setAbnResponse(res.data);
            // setValue(apiConstants.abnDetails, res.data);
          } else {
            showToast(STRING_CONSTANTS.invalid_abn, "error");
            isValid = false;
          }
        } else {
          showToast(STRING_CONSTANTS.invalid_abn, "error");
          isValid = false;
        }

        if(!isValid){
          return false
        }
    }

      Object.keys(dirtyFields).map((item: any) => {
        updatedValues[item] = getValues(item);
        if (item === "dob" && getValues(apiConstants.dob) !== null) {
          formData.append(
            apiConstants.dob,
            dateFormatter(data.dob, "YYYY-MM-DD")
          );
        } else if (item === "businessContactNumber") {
          if (checkNumberIsAdded()) {
            formData.append(
              apiConstants.businessContactNumber,
              `+${data?.businessContactNumber}`
            );
          } else {
            formData.append(apiConstants.businessContactNumber, "");
          }
        } else if (
          item === "businessContactEmail" &&
          getValues(apiConstants.businessContactNumber)
        ) {
          formData.append(
            apiConstants.businessContactEmail,
            getValues(apiConstants.businessContactEmail)
          );
        } else if (item === "address" && getValues(apiConstants.address)) {
          formData.append(
            apiConstants.address,
            getValues(apiConstants.address)
          );
        } else if (item === "abn") {
          formData.append(
            apiConstants.abn,
            getValues(apiConstants.abn).replaceAll(" ", "")
          );
          if (watch(apiConstants?.abnDetails)) {
            formData.append(
              "businessContactName",
              watch(apiConstants.abnDetails)?.EntityName
            );
            formData.append(
              "businessAddress",
              `${watch(apiConstants.abnDetails)?.AddressState} ${
                watch(apiConstants.abnDetails)?.AddressPostcode
              }`
            );
          }
        } else if (watch(apiConstants.tradingName)) {
          formData.append("tradingName", `${watch(apiConstants.tradingName)} `);
        } else {
          if (getValues(item) !== null) formData.append(item, getValues(item));
        }
      });

    if (!isObjEmpty(dirtyFields)) {
      updateprofile(formData);
    }
  };

  // Check if all fields are empty

  return (
    <DashboardWrapper
      watch={watch}
      userUpdate={userUpdate}
      reset={reset}
      showTab={true}
      isUserProfileChange={isUserProfileChange}
      setBussLocation={setBussLocation}
    >
      <div className="personal-details-wrapper">
        <div className="profile_box">
          <div className="title">
            Business Details
            <img
              src="/static/backarrow.svg"
              alt=""
              className="back_arrow d-md-none"
              onClick={() => navigate(-1)}
            />
          </div>
        </div>
        <div className="details-box">
          {/* <div className="detail-title">Business Details</div> */}
          <InputField
            type="text"
            className={"floating_input"}
            name={apiConstants.tradingName}
            control={control}
            errors={errors}
            inputLabel={"Trading Name"}
            placeholder={"Add your trading name"}
            setValue={setValue}
          />
          <MobileInput
            name={apiConstants.abn}
            watch={watch}
            setValue={setValue}
            className={"floating_input"}
            control={control}
            inputLabel={"ABN Number (11 Digits)"}
            placeholder={"Add your ABN number"}
            mask={"99999999999"}
            errors={errors}
          />
          {watch(apiConstants.abn) && abnResponse?.AbnStatus === "Active" && (
            <div className="api_response mb_24">
              <div className="wrapper">
                <>
                  <label>Business Name</label>
                  <p className="mb-0">{abnResponse?.EntityName}</p>
                  {isMobile && <label>Address</label>}
                  <p className="mb-0">{abnResponse.AddressState}</p>
                  <span>
                    `Registered on` {abnResponse.AbnStatusEffectiveFrom}
                  </span>
                </>
              </div>
            </div>
          )}
          {/* <MobileInput
            name={apiConstants.businessContactNumber}
            watch={watch}
            setValue={setValue}
            control={control}
            className={"mobile"}
            /> */}
          <PhoneNumberInput
            name={apiConstants.businessContactNumber}
            watch={watch}
            setValue={setValue}
            setCountryCode={setCountryCode}
            parentClass="react-tel-input"
            label={"Contact Number"}
            errors={errors}
          />
          <InputField
            type="email"
            name={apiConstants.businessContactEmail}
            className={"floating_input"}
            control={control}
            errors={errors}
            inputLabel={"Contact Email"}
            placeholder={"Add your email address"}
            setValue={setValue}
          />

          {getItemFromStore("userProfile")?.userType !== USER_TYPE.GUEST ? (
            <GooglePlacesSearchLocation
              hideMap={true}
              onChangeLocation={(
                lat: string,
                lng: string,
                address: string,
                address_components: any
              ) => {
                let location = handleLocation(
                  lat,
                  lng,
                  address,
                  address_components
                );
                setBussLocation(address);
                setValue(apiConstants.address, address, { shouldDirty: true });
                setValue(apiConstants.lat, lat);
                setValue(apiConstants.lng, lng);
                setValue(apiConstants.postCode, location.zipcode);
                setValue(apiConstants.state, location.state);
                setValue(apiConstants.city, location.locality);
                setValue(apiConstants.country, location.country);
              }}
              setValue={setValue}
              handleLocation={handleLocation}
              control={control}
              errors={errors}
              name={apiConstants.address }
              className={"floating_input"}
              label={"Business Address"}
              placeholder="Address"
            />
          ) : null}

          <div className="continue_btn">
            <div className="main-section-form-button">
              <div className="main-section-form"></div>
              <PrimaryButton
                disabled={Object.keys(dirtyFields)?.length < 1}
                onClick={handleNext}
                loading={isLoading}
              >
                Save
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default BusinessDetails;
