import moment from "moment";
import { Fragment } from "react";
import { Accordion } from "react-bootstrap";
import Button from "../../global/Button";

const BlockDetailsCard = (props) => {
  const {
    onCancelPayCut,
    isActive,
    listingBlock: { name, numberOfListing, pricePerListing },
    totalPayment,
    transactionData,
  } = props;

  return (
    <div className="block-details-card-wrap">
      <div className="card-top-content">
        <div className="boxtitle">{name}</div>
        <div className="listings-count">{numberOfListing} listings</div>
      </div>
      <div className="price-detail-box-wrap">
        <div className="price-detail-box">
          <div className="left">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M3.5 4C2.67157 4 2 4.67157 2 5.5V12.5C2 13.3284 2.67157 14 3.5 14H14.5C15.3284 14 16 13.3284 16 12.5V5.5C16 4.67157 15.3284 4 14.5 4H3.5ZM6 5V6C6 7.10457 5.10457 8 4 8L3 8V7H4C4.55228 7 5 6.55228 5 6V5H6ZM9 10.75C8.0335 10.75 7.25 9.9665 7.25 9C7.25 8.0335 8.0335 7.25 9 7.25C9.9665 7.25 10.75 8.0335 10.75 9C10.75 9.9665 9.9665 10.75 9 10.75ZM3 11V10H4C5.10457 10 6 10.8954 6 12V13H5V12C5 11.4477 4.55228 11 4 11H3ZM14 11C13.4477 11 13 11.4477 13 12V13H12V12C12 10.8954 12.8954 10 14 10H15V11H14ZM14 7H15V8L14 8C12.8954 8 12 7.10457 12 6V5H13V6C13 6.55228 13.4477 7 14 7ZM17.0001 12.5C17.0001 13.8807 15.8808 15 14.5001 15H4.08545C4.29137 15.5826 4.84699 16 5.5001 16H14.5001C16.4331 16 18.0001 14.433 18.0001 12.5V7.49997C18.0001 6.84686 17.5827 6.29125 17.0001 6.08533V12.5Z"
                  fill="#8F939A"
                />
              </svg>
            </span>
            <span>Block Amount</span>
          </div>
          <div className="right">{`AUD ${pricePerListing}`}</div>
        </div>
        <div className="price-detail-box">
          <div className="left">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M6.71681 3.48438H6.06017C4.09024 3.48438 3.43359 4.65977 3.43359 6.11095V6.76759V15.9606C3.43359 16.5056 4.05084 16.8142 4.48422 16.4859L5.60708 15.6454C5.86974 15.4484 6.23746 15.4747 6.47385 15.7111L7.56388 16.8077C7.81997 17.0638 8.24022 17.0638 8.49632 16.8077L9.59948 15.7045C9.8293 15.4747 10.197 15.4484 10.4531 15.6454L11.576 16.4859C12.0094 16.8077 12.6266 16.499 12.6266 15.9606V4.79766C12.6266 4.07535 13.2176 3.48438 13.9399 3.48438H6.71681ZM9.50755 11.1999H6.55265C6.28343 11.1999 6.06017 10.9767 6.06017 10.7075C6.06017 10.4382 6.28343 10.215 6.55265 10.215H9.50755C9.77677 10.215 10 10.4382 10 10.7075C10 10.9767 9.77677 11.1999 9.50755 11.1999ZM10 8.57336H6.06017C5.79094 8.57336 5.56768 8.3501 5.56768 8.08088C5.56768 7.81166 5.79094 7.5884 6.06017 7.5884H10C10.2693 7.5884 10.4925 7.81166 10.4925 8.08088C10.4925 8.3501 10.2693 8.57336 10 8.57336Z"
                  fill="#8F939A"
                />
                <path
                  d="M13.9468 3.48438V4.46934C14.3802 4.46934 14.7939 4.64663 15.0959 4.94212C15.4111 5.26388 15.5818 5.67756 15.5818 6.11095V7.70003C15.5818 8.18594 15.3652 8.4092 14.8727 8.4092H13.6119V4.80423C13.6119 4.62037 13.7629 4.46934 13.9468 4.46934V3.48438ZM13.9468 3.48438C13.2179 3.48438 12.627 4.07535 12.627 4.80423V9.39417H14.8727C15.9102 9.39417 16.5668 8.73752 16.5668 7.70003V6.11095C16.5668 5.38864 16.2713 4.732 15.7985 4.25265C15.3192 3.77986 14.6691 3.49094 13.9468 3.48438C13.9534 3.48438 13.9468 3.48438 13.9468 3.48438Z"
                  fill="#8F939A"
                />
              </svg>
            </span>
            <span>Total amount paid</span>
          </div>
          <div className="right">{`AUD ${totalPayment.totalPayment}`}</div>
        </div>
      </div>
      <div className="transaction-btn-box">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Payment Transactions</Accordion.Header>
            <Accordion.Body>
              {transactionData?.map((item) => {
                return (
                  <Fragment key={item.id}>
                    <div className="transactions-info-wrap">
                      <div className="left">
                        <img alt="" src="/static/trans-arrow.svg" />
                        <div>
                          <div>Paid for month</div>
                          <div className="date">
                            {moment(item.createdAt).format("DD MMM, YYYY")}
                          </div>
                        </div>
                      </div>
                      <div className="right">{`AUD ${item.grossAmount}`}</div>
                    </div>
                    <div className="divider"></div>
                  </Fragment>
                );
              })}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="auto-paycut-btn-box">
        <Button
          disabled={!isActive}
          btnTxt={isActive ? "Cancel auto paycut" : "Auto pay cancelled"}
          className={` ${
            isActive ? "auto-paycut-btn" : "auto-paycut-btn-disabled"
          }`}
          onClick={onCancelPayCut}
        />
      </div>
    </div>
  );
};

export default BlockDetailsCard;
