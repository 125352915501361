import React from "react";
import { Link } from "react-router-dom";
import useCheckDeviceScreen from "../../hooks/useCheckScreen";
import { LISTING_TYPE } from "../../types";

const ListingCard = ({ active, listing }: any) => {
  const { isMobile } = useCheckDeviceScreen();
  const sessionSlot = () => {
    let slot = 0;
    if (listing?.listingType === LISTING_TYPE.S || listing?.listingType === LISTING_TYPE.SPECIAL) {
      slot = listing?.maxParticipants - listing?.events?.[0]?.slotsAvailable
    } else {
      slot = listing?.maxParticipants - listing?.teamSlotsAvailable;
    }
    return slot + "/";
  };

  console.log(listing, "---");

  return (
    <div className="profile-listing-card">
      <Link to={`/listing-two/${listing.id}`} style={{ textDecoration: "none" }}>
        {listing.isSpecialListing ? (
          <img
            src="/static/fitfest.svg"
            style={{ position: "absolute", left: -8, top: 55 }}
            alt=""
            className="tag"
          />
        ) : listing.listingType === LISTING_TYPE.M ? (
          <img
            src="/static/package-label.svg"
            style={{ position: "absolute", left: -8, top: 55 }}
            alt=""
            className="tag"
          />
        ) : listing.listingType === LISTING_TYPE.C ? (
          <img
            src="/static/team-label.svg"
            style={{ position: "absolute", left: -8, top: 55 }}
            alt=""
            className="tag"
          />
        ) : listing.listingType === LISTING_TYPE.S ? (
          <img
            src="/static/session-label.svg"
            style={{ position: "absolute", left: -8, top: 55 }}
            alt=""
            className="tag"
          />
        ) : null}

        <div>
          {["mp4", "mov", "avi"].includes(
            listing?.media?.split(".").splice(-1)[0].toLowerCase()
          ) ? (
            <>
              <div>
                <video
                  src={listing?.media}
                  autoPlay
                  playsInline
                  loop
                  muted
                  style={{
                    cursor: "pointer",
                    width: isMobile ? "100%" : "100%",
                    height: "161px",
                    objectFit: "cover",
                    verticalAlign: "middle",
                    borderRadius: "12px 12px 0px 0px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <source src={listing?.media} />
                </video>
              </div>
            </>
          ) : (
            <div>
              <img
                style={{
                  width: isMobile ? "100%" : "100%",
                  height: "161px",
                  borderRadius: "12px 12px 0px 0px",
                  objectFit: "cover",
                }}
                src={listing?.media || "/static/dummyimg.svg"}
                alt=""
              />
            </div>
          )}
        </div>

        <div
          className="card-body"
          style={{ backgroundColor: "#ffffff", border: "1px solid #f3f3f3" }}
        >
          <div className="km-data">
            {listing.distance && (
              <div className="distance">{listing.distance} km away...</div>
            )}
            <div className="pricing">
              AUD {listing?.price > 0 ? listing?.price : "FREE"}
            </div>
          </div>
          <div className="heading">{listing?.title}</div>
          <div className="calendar">
            <div className="date">
              <img src="/static/calendar.svg" alt="" />{" "}
              {listing?.events ? listing?.events[0]?.date : ""}
            </div>
            <div className="flex-row">
              <img src="/static/people-community.svg" alt="" />{" "}
              <div className="target">
                <span style={{ color: "#8f939a" }}>{sessionSlot()}</span>
                <span className="set-target" style={{ color: "#25272d" }}>
                  {listing?.maxParticipants}
                </span>
              </div>
            </div>
          </div>

          <div className={`${active ? "status-button" : "status-button-red"}`}>
            <img
              alt=""
              style={{ marginRight: 4 }}
              src={`${!active
                ? "/static/ListingInactive.svg"
                : "/static/ListingActive.svg"
                }`}
            />
            {active ? "Active" : "Inactive"}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ListingCard;
