import React, { Fragment, useState } from "react";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from "@mui/material";
import dayjs from "dayjs";
import DialogSlide from "../DialogSlide";
import PrimaryButton from "../Button/PrimaryButton";
import PrimaryTextField from "../TextField/PrimaryTextField";
import ReviewComponent from "../ReviewComponent";
import { makeDeleteCall, makePatchCall } from "../../utils/Requests";
import apiConfig from "../../utils/endpoints";
import { showToast } from "../../utils";
import LoadingLayer from "../ConnectUserProfile/LoaderLayer";
import "./style.scss";

interface ReviewProps {
  [key: string]: any;
}

const CustomDialogTitle = styled(DialogTitle)({
  fontFamily: "Poppins",
  fontSize: "20px",
  fontWeight: 600,
  lineHeight: "44px",
  textAlign: "center",
  color: "#25272D",
  borderBottom: "1px solid #6C6D6F",
  padding: "20px 56px",
  position: "relative",
  display: "flex",
  alignItems: "center",
  "& .title_text": {
    paddingLeft: "44px",
    width: "calc(100% - 44px)",
  },
  "& .close_modal_icon": {
    cursor: "pointer",
  },
  ".close_modal_resp_icon": {
    display: "none",
    cursor: "pointer",
  },
  "@media screen and (max-width:768px)": {
    padding: "20px",
    "& .title_text": {
      paddingLeft: "0px",
      paddingRight: "24px",
      width: "calc(100% - 24px)",
    },
    ".close_modal_icon": {
      display: "none",
    },
    ".close_modal_resp_icon": {
      display: "flex",
    },
  },
});

const AddReviewModal = ({ data, onClose, open = false }: ReviewProps) => {
  const [reviewDetails, setReviewDetails] = useState<string | null>(null);
  const [rating, setRating] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(false);

  const startTime = dayjs(
    data?.eventDate + data?.startTime,
    "YYYY-MM-DD HH:mm:ss"
  );
  const endTime = dayjs(data?.eventDate + data?.endTime, "YYYY-MM-DD HH:mm:ss");

  const formattedStart = startTime.format("h:mma");
  const formattedEnd = endTime.format("h:mma");

  const eventTime = `${formattedStart} - ${formattedEnd}`;

  const handleAddReview = () => {
    setLoading(true);
    const apiPayload = {
      star: rating,
      review: reviewDetails?.trim(),
    };

    makePatchCall({ url: apiConfig.review + data?.id, apiPayload: apiPayload })
      .then((res) => {
        showToast("Review added success", "success");
        onClose();
        setLoading(false);
      })
      .catch((err) => {
        onClose();
        setLoading(false);
      });
  };
  const handleCloseReview = () => {
    setLoading(true);
    makeDeleteCall({ url: `${apiConfig?.review}${data?.id}/` })
      .then(async (res) => {
        onClose();
        setLoading(false);
      })
      .catch((err) => {
        console.error("err: ", err);
        onClose();
        setLoading(false);
      });
  };

  return (
    <DialogSlide open={open} onClose={handleCloseReview} disableBackdropClick>
      <Fragment>
        {isLoading && <LoadingLayer />}
        <CustomDialogTitle>
          <img
            className="close_modal_resp_icon"
            src="/static/arrow/back.svg"
            alt=""
            onClick={handleCloseReview}
          />
          <div className="title_text">{"Submit a review"}</div>
          <img
            className="close_modal_icon"
            src="/static/close/closePrimary.svg"
            alt=""
            onClick={handleCloseReview}
          />
        </CustomDialogTitle>
        <DialogContent className="custom_dialog_content">
          <Box className="event_wrap_review">
            <Box className="event_review_border">
              <Box className="event_review_media">
                <img
                  src={
                    data?.listingMedia?.photos[0] || "/static/connect/avatar"
                  }
                  alt=""
                />
              </Box>
              <Box className="event_details_wrap">
                <div className="event_title_review">{data?.title}</div>
                <div className="event_date_review">
                  {`${dayjs(data?.eventDate).format("Do MMMM")} ${eventTime}`}
                </div>
              </Box>
            </Box>
          </Box>
          <Box className="add_review_section">
            <Box className="add_review_title" mb={{ xs: 1, md: 1 }}>
              How would you rate your experience?
            </Box>
            <Box className="add_review_subtitle" mb={{ xs: 1, md: 1 }}>
              Reviews are shared to admins & listers.
            </Box>
            <Box mb={{ xs: 1.5, md: 1.5 }}>
              <ReviewComponent reviewState={[rating, setRating]} />
            </Box>
            <Box className="add_review_title" mb={{ xs: 1.5, md: 1.5 }}>
              Write a review?
            </Box>

            <Box className="event_details_textfield_wrap" mb={{ xs: 3, sm: 0 }}>
              <PrimaryTextField
                label={"Details"}
                placeholder="Start typing"
                rows={2}
                value={reviewDetails}
                onChange={(e) => setReviewDetails(e.target.value)}
                wordLimit={300}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            padding: "38px",
          }}
        >
          <PrimaryButton
            fullWidth
            maxWidth="350px"
            onClick={handleAddReview}
            disabled={rating < 1}
          >
            Submit Review
          </PrimaryButton>
        </DialogActions>
      </Fragment>
    </DialogSlide>
  );
};

export default React.memo(AddReviewModal);
