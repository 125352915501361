import { useState } from "react";
import moment from "moment";
import Button from "../../../global/Button";
import { Link } from "react-router-dom";
import { showToast } from "../../../utils";
import Mail from "../../../assets/img/icons/Mail";
import Call from "../../../assets/img/icons/Call";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { makePatchCall } from "../../../utils/Requests";
import endpoints from "../../../utils/endpoints";
import GlobalHeader from "../../Dashboard/GlobalHeader";
import { useLocation } from "react-router";
import GlobalHeader2 from "../../Dashboard/GlobalHeader2";
import ReadMore from "../../ConnectUserProfile/ReadMore";

const Table = ({ columns = [], rows = [] }) => {
  return (
    <div className="custom_table">
      <div className="custom_table-row heading ">
        {columns?.map((item, index) => (
          <div
            key={index + "table"}
            className={`custom_table-header ${
              item?.align === "center" ? "text-center" : "text-start "
            }`}
          >
            {item?.label}
          </div>
        ))}
      </div>

      {rows.map((rowData, index) => {
        return (
          <div className="custom_table-row" key={"rows" + index}>
            {columns.map((colData, idx) => {
              return (
                <div
                  className={`custom_table-cell ${
                    colData?.highlight ? "highlight" : ""
                  }
                  ${
                    colData?.align === "center" ? "text-center" : "text-start "
                  }`}
                  style={
                    colData?.maxWidth
                      ? {
                          maxWidth: colData?.maxWidth,
                          whiteSpace: "break-spaces",
                        }
                      : {
                          whiteSpace: "break-spaces",
                        }
                  }
                >
                  {colData?.id === "description" ? (
                    <ReadMore data={rowData[colData?.id] || "-"} count={100} />
                  ) : (
                    rowData[colData?.id]
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

const TableResponsive = ({ rows = [] }) => {
  return (
    <div className="responsive_table">
      {rows.map((rowData, index) => {
        return (
          <div key={"rows_resp" + index}>
            <div className="container_data">
              <div className="data_name">{rowData?.name}</div>
              <div className="d-flex align-items-center gap-2">
                <div className="text_light">{rowData?.gender}</div>
                <div className="separator" />
                <div className="text_light">{rowData?.age}</div>
              </div>
            </div>
            <div className="container_data">
              <div className="d-flex align-items-center gap-2">
                <Mail />
                <span className="text_light">{rowData?.email}</span>
              </div>
              <div className="d-flex align-items-center gap-2">
                <Call />
                <span className="text_light">{rowData?.phone}</span>
              </div>
            </div>
            <div
              style={{
                fontSize: "14px",
              }}
            >
              Description:{" "}
              <span style={{ color: "#8f939a" }}>
                <ReadMore data={rowData?.description || "-"} />
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const tableHeader = [
  {
    id: "name",
    label: "Name",
    highlight: true,
  },
  { id: "age", label: "Age", align: "center" },
  { id: "gender", label: "Gender", align: "center" },
  { id: "email", label: "Email" },
  { id: "phone", label: "Phone Number" },
  { id: "description", label: "Description", maxWidth: "250px" },
];

export default function TeamListingDetails() {
  const location = useLocation();
  const bookingDetails = location.state;
  const eventsDetails =
    bookingDetails?.events?.length > 0
      ? bookingDetails?.events[0]?.address
      : null;
  const [showPaycutPopup, setShowPaycutPopup] = useState(false);
  const [listingId, setListingId] = useState(null);

  const handleNext = () => {
    const formData = new FormData();
    formData.append("status", "C");
    makePatchCall({
      url: `${endpoints.team_listing}${listingId}/`,
      apiPayload: formData,
      content_type: "multipart/form-data",
    })
      .then((res) => {
        setListingId(null);
        setShowPaycutPopup(false);
        showToast(res.status.message, "success");
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };

  return (
    <>
      <GlobalHeader2 />
      <div className="booking_details_wrapper_new">
        <div className="main-section-new">
          <div className="booking_details_container">
            <div className="details_container">
              <div className="gap-3">
                <div className="d-inline">
                  <div className="sub_heading_container">
                    <span>{bookingDetails?.listingData.title}</span>
                    <span className="container_session">
                      <img alt="" src="/static/teamsweb.svg" />
                      <img
                        alt=""
                        src={
                          bookingDetails?.listingData?.listingEventType === "P"
                            ? "/static/physicalweb.svg"
                            : "/static/virtualweb.svg"
                        }
                      />
                    </span>
                  </div>
                </div>
                <div className="info-box-booking-head">
                  <img
                    alt=""
                    src="/static/info.svg"
                    width="16px"
                    height="16px"
                  />
                  <span>
                    Please note that this page includes all the details
                    regarding your request.
                  </span>
                </div>
                <div className="details_description">
                  <hr />
                  <div className="summary_container">
                    <div className="summary_heading">Summary</div>
                    <div className="summary_desc">
                      <div className="summary_details">Status</div>
                      <div
                        className="summary_count"
                        style={{
                          color:
                            bookingDetails?.status === "R"
                              ? "#EE762F"
                              : bookingDetails?.status === "D"
                              ? "#FC5555"
                              : "#29CC6A",
                        }}
                      >
                        {bookingDetails?.status === "R"
                          ? "Pending"
                          : bookingDetails?.status === "D"
                          ? "Rejected"
                          : "Accepted"}
                      </div>
                    </div>
                    <div className="summary_desc">
                      <div className="summary_details">
                        Requested Date & Time
                      </div>
                      <div className="summary_count">
                        {moment(bookingDetails?.createdAt).format(
                          "Do MMM YYYY, h:mm A"
                        )}
                      </div>
                    </div>
                    {bookingDetails?.status !== "R" ? (
                      <div className="summary_desc">
                        <div className="summary_details">
                          {bookingDetails?.status === "D"
                            ? "Rejected Date & Time"
                            : " Accepted Date & Time"}
                        </div>
                        <div className="summary_count">
                          {moment(bookingDetails?.modifiedAt).format(
                            "Do MMM YYYY, h:mm A"
                          )}
                        </div>
                      </div>
                    ) : null}
                    <hr />
                  </div>
                  {bookingDetails?.status === "A" && (
                    <>
                      <div className="summary_container">
                        <div className="copy-link-box-booking-details">
                          <div className="orange-box virtual_spacing">
                            {bookingDetails?.listingData?.listingEventType ===
                            "V"
                              ? "Meet link"
                              : "Map Location"}
                          </div>
                          <div className="link-box virtual_spacing">
                            <div className="link_text">
                              {bookingDetails?.listingData?.listingEventType ===
                              "V"
                                ? bookingDetails?.listingData?.teamVirtualLink
                                : `https://www.google.com/maps/place/
                    ${Number(eventsDetails?.latitude) || ""},
                    ${Number(eventsDetails?.longitude) || ""}
                   `}
                            </div>
                            <div>
                              <CopyToClipboard
                                onCopy={() =>
                                  showToast("copied to clipboard", "success")
                                }
                                text={
                                  bookingDetails?.listingData
                                    ?.listingEventType === "V"
                                    ? bookingDetails?.listingData
                                        ?.teamVirtualLink
                                    : `https://www.google.com/maps/place/
                     ${Number(eventsDetails?.latitude) || ""},
                    ${Number(eventsDetails?.longitude) || ""}
                   `
                                }
                              >
                                <img
                                  alt=""
                                  src="/static/copyblue.svg"
                                  style={{ cursor: "pointer" }}
                                />
                              </CopyToClipboard>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="info-box-booking-head">
                        <img
                          alt=""
                          src="/static/info.svg"
                          width="16px"
                          height="16px"
                        />
                        <span>Click on this link to join the team</span>
                      </div>
                    </>
                  )}

                  <div className="attendees_container">
                    <div className="attendees_header">
                      Total No. of Attendees :{" "}
                      {bookingDetails?.slotsRequested ||
                        bookingDetails?.attendees?.length + 2}
                    </div>
                    <div className="attendees_list">
                      <div className="list_heading">Attendee Details</div>
                      <div className="d-none d-md-block">
                        <Table
                          columns={tableHeader}
                          rows={bookingDetails?.attendees?.map((item) => {
                            return {
                              name: `${item.name}`,
                              age: `${item.age ? item.age + "Yr" : "--"}`,
                              gender: `${item.gender}`,
                              email: `${item.email}`,
                              phone: `${
                                item.contactNumber ? item.contactNumber : "--"
                              }`,
                              description: `${
                                item.description ? item.description : "--"
                              }`,
                            };
                          })}
                        />
                      </div>
                      <div className="d-md-none d-block">
                        <TableResponsive
                          rows={bookingDetails?.attendees?.map((item) => {
                            return {
                              name: `${item.name}`,
                              age: `${item.age ? item.age + "Yr" : "--"}`,
                              gender: `${item.gender}`,
                              email: `${item.email}`,
                              phone: `${
                                item.contactNumber ? item.contactNumber : "--"
                              }`,
                              description: `${
                                item.description ? item.description : "--"
                              }`,
                            };
                          })}
                        />
                      </div>
                    </div>
                  </div>
                  {bookingDetails?.status !== "D" ? (
                    <div className="btn_footer">
                      <button
                        className="common_btn auto-paycut-btn-global"
                        style={{ maxWidth: "546px" }}
                        onClick={() => {
                          setShowPaycutPopup(true);
                          setListingId(bookingDetails.id);
                        }}
                      >
                        Cancel Request
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showPaycutPopup ? (
        <div className="modal show">
          <div
            className="modal-section"
            style={{ gap: "20px", maxWidth: "500px" }}
          >
            <div className="modal-top-part">
              {/* <button className="close">
                <img
                  src="/static/dismiss-cross-round-black.svg"
                  alt=""
                  onClick={() => setShowPaycutPopup(false)}
                />
              </button> */}
              <h5>
                  {bookingDetails?.status === "A"
                    ? "Are you sure to withdraw your accepted request?"
                    : "Are you sure to cancel your pending request?"}
              </h5>
              <p>If you proceed, you will be removed from the team</p>
                {bookingDetails?.status === "A" && 
                <div className="info-box-wrap">
                  <div className="info_box" style={{ boxShadow: "none" }}>
                    <img src="/static/greyinfo.svg" alt="" /> No refunds will be
                    processed by the system in case of membership cancellation.
                  </div>
                </div>}
                <div className="purchase-popup-links-wrap">
                  By tapping below, I also agree to the{" "}
                  <Link to="/policies">Terms of Service,</Link>{" "}
                  <Link to="/policies">Payments</Link> and I acknowledge the{" "}
                  <Link to="/policies">Privacy Policy</Link>.
                </div>
            </div>

            <Button
              btnTxt="Confirm cancellation"
              className={"backtologin"}
              onClick={() => handleNext()}
            />
            <Button
              btnTxt="Go back"
              className={"gobackmodalbtn backtologin"}
              onClick={() => {
                setListingId(null);
                setShowPaycutPopup(false);
              }}
            />
          </div>
        </div>
      ) : null}
    </>
  );
}
