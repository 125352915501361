import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import DashboardWrapper from "./DashboardWrapper";
import Button from "../../global/Button";
import BlockDetailsCard from "./BlockDetailsCard";
import { BlackPlusIcon, VisaCardImg } from "../../utils/Icons/svgs";
import { makeGetCall, makePatchCall, makePostCall } from "../../utils/Requests";
import { setLocalStorage, showToast } from "../../utils";
import { useForm } from "react-hook-form";
import { BlockListingValidation } from "../Profile/ProfileValidations";
import { yupResolver } from "@hookform/resolvers/yup";
import endpoints from "../../utils/endpoints";
import { apiConstants } from "../../utils/Constants";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import InputField from "../../global/InputField";
import AddCardPopup from "./Popups/AddCardPopup";
import useCheckDeviceScreen from "../../hooks/useCheckScreen";
import ApiLoader from "../../global/ApiLoader";

const PurchaseListing = () => {
  const [showPaycutPopup, setShowPaycutPopup] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [showBlocks, setShowBlocks] = useState<boolean>(false);
  const [blocksOption, setBlocksOption] = useState<any>([]);
  const [showAddCardPopup, setShowAddCardPopup] = useState<boolean>(false);
  const [myCards, setMyCards] = useState([]);
  const [paymentId, setPaymentId] = useState<any>(null);
  const [listingBlockList, setListingBlockList] = useState<any>([]);
  const [showPurchaseButton, setShowPurchaseButton] = useState<boolean>(true);
  const { isMobile } = useCheckDeviceScreen();
  const [isLoading, setIsLoading] = useState<any>(false);
  const [isLoadingCard, setIsLoadingCard] = useState<any>(false);

  const { handleSubmit, control, watch, setValue } = useForm({
    resolver: yupResolver(BlockListingValidation),
    mode: "onSubmit",
  });

  useEffect(() => {
    makeGetCall({ url: endpoints.listings_blocks })
      .then((res) => {
        if (res.status.code === 200) {
          setBlocksOption(res.results);
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  }, []);

  const onError = () => {
    showToast("Please select one block", "error");
  };

  const onSubmit = (data: any) => {
    if (watch(apiConstants.block_id)) {
      const formData = new FormData();
      formData.append("payment_id", paymentId);
      formData.append("block_id", data.blockId);
      makePostCall({
        url: endpoints.buy_blocks,
        apiPayload: formData,
        content_type: "multipart/form-data",
      })
        .then((res) => {
          showToast(res.status.message || "Listing Purchased", "success");
          makeGetCall({ url: endpoints.profile })
            .then((res) => {
              if (res.status.code === 200) {
                let data = {
                  ...res.data?.userDetail,
                  ...res.data?.businessDetail?.[0],
                };
                setLocalStorage("userProfile", data);
                navigate(ROUTE_CONSTANTS.create_listing);
              }
            })
            .catch((err) => {
              showToast(err, "error");
            });
        })
        .catch((err) => {
          showToast(err, "error");
        });
    }
  };

  const handleNext = () => {
    if (myCards.length) {
      handleSubmit(onSubmit, onError)();
    } else {
      setShowAddCardPopup(true);
    }
  };

  useEffect(() => {
    if (location.search) {
      setShowBlocks(true);
    }
  }, [location]);

  useEffect(() => {
    if (listingBlockList.length) {
      const activeBlocks = listingBlockList.map((obj) => obj.isActive === true);
      if (listingBlockList.length <= activeBlocks) {
        setShowPurchaseButton(false);
      } else {
        setShowPurchaseButton(true);
      }
    }
  }, [listingBlockList]);

  useEffect(() => {
    makeGetCall({ url: endpoints.manage_card, params:{device_type:"web"} })
      .then((res) => {
        if (res.status.code === 200) {
          setMyCards(res.data);
          const tempId = res.data.filter((i) => i.default === true)[0]?.id;
          setPaymentId(tempId);
        }
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    setIsLoading(true);
    makeGetCall({ url: endpoints.buy_blocks })
      .then((res) => {
        if (res.status.code === 200) {
          setListingBlockList(res.data);
          if (res.data.length < 1) {
            setShowBlocks(true);
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, []);

  const changeCardDefault = (id) => {
    setIsLoadingCard(true);
    makePatchCall({
      url: endpoints.manage_card,
      apiPayload: { source_id: id },
    })
      .then((res) => {
        if (res.status.code === 200) {
          setIsLoadingCard(false);
          makeGetCall({ url: endpoints.manage_card, params:{device_type:"web"} })
            .then((res) => {
              if (res.status.code === 200) {
                setMyCards(res.data);
                const tempId = res.data.filter((i) => i.default === true)[0]
                  ?.id;
                setPaymentId(tempId);
              }
            })
            .catch((err) => {});
        }
      })
      .catch((err) => {
        setIsLoadingCard(false);
      });
  };

  const renderCardImage = (card) => {
    if (card === "visa") {
      return <VisaCardImg />;
    } else {
      return (
        <div
          style={{
            width: "auto",
            height: "24px",
            border: "1px solid #f8f9fa",
            color: "rgb(30, 49, 136)",
            padding: "2px",
            fontWeight: "bold",
          }}
        >
          {card}
        </div>
      );
    }
  };

  const onSubmitCutout = () => {
    makePatchCall({
      url: `${endpoints.buy_blocks}`,
      content_type: "multipart/form-data",
    })
      .then((res) => {
        setShowPaycutPopup(false);
        showToast(res.status.message, "success");
        makeGetCall({ url: endpoints.buy_blocks }).then((res) => {
          if (res.status.code === 200) {
            setListingBlockList(res.data);
          }
        });
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };

  return (
    <DashboardWrapper showTab={true}>
      {showBlocks ? (
        <div className="purchase-listing-right-blocks">
          <div className="listing-block-page-wrap">
            <div className="listing-block-white">
              <div className="star-title-box">
                <div className="star-img">
                  <img src="/static/blockstar.svg" alt="" />
                </div>
                <div className="title">
                  <img
                    src="/static/arrow-back.svg"
                    alt=""
                    className="d-md-none"
                    onClick={() => setShowBlocks(false)}
                  />
                  Purchase Listing Blocks!
                </div>
                <div className="sub-title">
                  Want to create listings? Purchase listing blocks below and
                  reach fitness enthusiasts!
                </div>
              </div>
              <div className="listing_block_radio_wrapper">
                {blocksOption?.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <div className="gen-opt-cont">
                        <label
                          htmlFor={item.id}
                          className={
                            watch(apiConstants?.block_id) === item.id
                              ? "listing_block_radio_option active block_label"
                              : "listing_block_radio_option"
                          }
                        >
                          <InputField
                            key={index}
                            name={apiConstants?.block_id}
                            type="radio"
                            control={control}
                            id={item.id}
                            inputLabel={item.name}
                            setValue={setValue}
                          />
                          <div className="check-icon"></div>
                          <div className="default-icon"></div>
                          <div className="radio-content">
                            <div className="price">
                              AUD {item.price} <span>Inclusive of GST</span>
                            </div>
                            <div className="divider"></div>
                            <div className="listing-info">
                              <ul>
                                <li>{item.numberOfListing} listings</li>
                                <li>
                                  Per listing AUD 
                                  {item?.pricePerListing?.toFixed(2)}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </label>
                      </div>
                    </Fragment>
                  );
                })}
              </div>

              {
                <div className="listing-card-box-outer">
                  {myCards.map((mc, index) => (
                    <div
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px 24px",
                        borderRadius: "8px",
                        marginBottom: "24px",
                      }}
                      className=" ep_pay_card d-flex justify-content-between"
                      key={index}
                    >
                      <div className="d-flex first gap-3">
                        <span>{renderCardImage(mc.brand)}</span>
                        <p className="mb-0 card_num">
                          <span>&#183; &#183; &#183; &#183;</span> {mc?.last4}
                        </p>
                        <p className="mb-0 card_val">
                          {mc.expMonth}/{mc?.expYear}
                        </p>
                      </div>
                      {mc.default ? (
                        <div className="second" style={{ color: "#EE7830" }}>
                          Selected
                        </div>
                      ) : (
                        <div
                          onClick={(e) => changeCardDefault(mc.id)}
                          className="second"
                          style={{ cursor: "pointer" }}
                        >
                          {isLoadingCard ? (
                            <div
                              className="lds-ring"
                              style={{
                                margin: "0 auto",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                style={{ width: "24px" }}
                                src="/static/spinner.svg"
                                alt="loader"
                              />
                            </div>
                          ) : (
                            "Select"
                          )}
                        </div>
                      )}
                    </div>
                  ))}

                  <div
                    className="row ep_added_card gx-0  align-items-center"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px 24px",
                      borderRadius: "8px",
                      marginBottom: "24px",
                    }}
                    onClick={() => setShowAddCardPopup(true)}
                  >
                    <span className="col-1">
                      <BlackPlusIcon />
                    </span>
                    <div className="col-10">Add a new card</div>
                  </div>
                </div>
              }
              <div className="listingblock_btn">
                <Button
                  type="submit"
                  btnTxt="Purchase Listing Blocks"
                  onClick={handleNext}
                />
              </div>
              <div className="cancel-box">
                <span
                  className="cancel-btn"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setShowBlocks(false);
                  }}
                >
                  Cancel
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            maxWidth: "750px",
            background: "transparent",
            width: "100%",
          }}
        >
          <div className="purchase-listing-right">
            <div className="listing_box">
              <div className="title">
                <img
                  src="/static/arrow-back.svg"
                  alt=""
                  className="d-md-none"
                  onClick={() => navigate(-1)}
                />
                Listing Block Purchase
              </div>
              {isLoading ? (
                <ApiLoader />
              ) : (
                <>
                  {listingBlockList.length > 0
                    ? listingBlockList.map((item) => {
                        return (
                          <BlockDetailsCard
                            key={item.id}
                            isActive={item.isActive}
                            transactionData={item.transactionData}
                            totalPayment={item.totalPayment}
                            listingBlock={item.listingBlock}
                            onCancelPayCut={() => setShowPaycutPopup(true)}
                          />
                        );
                      })
                    : null}

                  {!listingBlockList.length ? (
                    <div className="no-content-wrap">
                      <img alt="" src="/static/no-purchase-content.svg" />
                    </div>
                  ) : null}

                  {showPurchaseButton ? (
                    <div className="purchase-btn-box">
                      <Button
                        btnTxt="Purchase block"
                        onClick={() => {
                          window.scrollTo(0, 0);
                          setShowBlocks(true);
                        }}
                      />
                    </div>
                  ) : null}
                </>
              )}
            </div>

            {showPaycutPopup ? (
              <div className="modal show">
                <div className="modal-section" style={{ gap: "20px" }}>
                  <div className="modal-top-part">
                    <button className="close">
                      <img
                        src="/static/dismiss-cross-round-black.svg"
                        alt=""
                        onClick={() => setShowPaycutPopup(false)}
                      />
                    </button>

                    <h5>
                      Listing Block purchase auto pay cancellation Confirmation
                    </h5>
                    <p>
                      If you proceed, you won't be charged for automatic
                      payments for listing blocks
                    </p>
                    <div className="info-box-wrap">
                      <div className="info_box" style={{ boxShadow: "none" }}>
                        <img src="/static/greyinfo.svg" alt="" /> Set
                        participant limit. Listing closes when maximum
                        participants is reached.
                      </div>
                    </div>
                    <div className="purchase-popup-links-wrap">
                      By tapping below, I also agree to the{" "}
                      <Link to="/policies">Terms of Service,</Link>{" "}
                      <Link to="/policies">Payments</Link> and I acknowledge the{" "}
                      <Link to="/policies">Privacy Policy</Link>.
                    </div>
                  </div>

                  <Button
                    btnTxt="Confirm"
                    className={"backtologin"}
                    onClick={() => onSubmitCutout()}
                  />
                  <Button
                    btnTxt="Go back"
                    className={`goback backtologin ${isMobile ? "mt-3" : ""}`}
                    onClick={() => setShowPaycutPopup(false)}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      )}

      {showAddCardPopup ? (
        <AddCardPopup
          show={showAddCardPopup}
          onHide={() => setShowAddCardPopup(false)}
        />
      ) : null}
    </DashboardWrapper>
  );
};

export default PurchaseListing;
