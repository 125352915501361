import { Avatar, Skeleton } from "@mui/material";

const ChatCardShimmer = () => {
  return (
    <div
      style={{ display: "flex", flexDirection: "row" }}
      className="px-3 gap-2"
    >
      <Skeleton
        animation="wave"
        variant="rounded"
        width={58}
        height={48}
        sx={{ borderRadius: "100%" }}
      />
      <div className="d-flex flex-column w-100 gap-2">
        <Skeleton
          animation="wave"
          variant="rounded"
          width={"100%"}
          height={20}
        />
        <Skeleton
          animation="wave"
          variant="rounded"
          width={"100%"}
          height={20}
        />
      </div>
    </div>
  );
};

export default ChatCardShimmer;
