const apiConfig = {
  API_BASE_URL:
    process.env.REACT_APP_IS_DEV === "true"
      ? "https://dev-api.fitnessmates.com.au/api/v1/"
      : process.env.REACT_APP_IS_LOCAL === "true"
      ? "https://e90ae1560deda8.lhr.life/api/v1/"
      : ["fitnessmates.com.au", "fitnessmates.com"].includes( window.location.hostname)
      ? "https://services.fitnessmates.com.au/api/v1/"
      : "https://staging-api.fitnessmates.com.au/api/v1/",
  webSocketUrl:
    process.env.REACT_APP_IS_DEV === "true"
      ? "wss://dev-api.fitnessmates.com.au/"
      : process.env.REACT_APP_IS_LOCAL === "true"
      ? "wss://e90ae1560deda8.lhr.life/api/v1/"
      : ["fitnessmates.com.au", "fitnessmates.com"].includes( window.location.hostname)
      ? "wss://services.fitnessmates.com.au/"
      : "wss://staging-api.fitnessmates.com.au/",
  profile: "auth/profile/",
  listing_my_earning_breakdown: "listings/my-earning-breakdown/",
  interest_list: "auth/interest/",
  login: "auth/login/",
  interest_experience: "auth/trainer-experience-document/",
  document_upload: "auth/trainer-document-upload/",
  change_password: "auth/change-password/",
  faq: "frequently-asked-question/",
  terms: "tnc",
  privacy: "privacy-policy",
  trainer_docs: "auth/trainer-document-upload/",
  logout: "auth/logout/",
  forgot_password: "auth/forgot-password/",
  delete_account: "auth/account-delete/",
  users: "auth/user-list/",
  convert_token: "auth/convert-token/",
  apple_signin: "auth/apple-sign-in/",
  unlink_account: "auth/unlink-social-account/",
  payment_methods: "auth/payment-methods/",
  favourite: "auth/favourite/",
  flag: "auth/flag/",
  listing: "/listings/",
  draft: "/listings/drafts/",
  mylisting: "auth/mylisting/",
  listingSchedule: "/listings/my-schedules/",
  draft_listing: "/listings/drafts/",
  mylisting_new: "/listings/",
  dashboard_listing_new: "/listings/dashboard-listings/",
  dashboard_listing_map: "/listings/dashboard-mapview/",
  search_users_listings: "/auth/search-users-listings/",
  listing_detail: "auth/listing/",
  manage_card: "auth/payment-methods/",
  listings_intermediate: "/listings/intermediate/",
  listings_promotion: "/auth/promotions/",
  listings_earning: "listings/my-earnings/",
  listings_earning_csv: "/listings/my-earnings-csv/",
  listings_blocks: "/listing-blocks/",
  buy_blocks: "/buy-blocks/",
  blocks_faq: "/blocks-faq/",
  get_user_type: "/user-types/",
  get_subscription_types: "auth/subscription/",
  create_subscription: "auth/subscription/",
  get_payment_method: "auth/payment-methods/",
  review: "/listings/event-reviews/",
  my_team_memberships: "/listings/my-team-listings",
  listing_monthly_earning: "/listings/monthly-earnings/",
  user_profile: "auth/profiles/",
  listings: "listings/users/",
  subscription: "/auth/subscription/",
  subscription_details: "/auth/subscription-details/",
  manage_subscription: "/auth/manage-subscription/",
  user_types: "/user-types/",
  profiles: "auth/profiles/",
  remove_connection: "auth/connections",
  user_by_id: "auth/profiles/",
  accept_connection_request: "auth/connections",
  email_verification: "/auth/email-verification/",
  chat_rooms: "/chat/rooms/",
  chat_room: "/chat/room/",
  chat_room_messages: "/chat/room-messages/",
  listings_waitlist: "/listings/waitlist/",
  listings_booking_csv: "/listings/my-bookings-csv/",
  listings_popup: "/listings/pop-up/",
  enquiry_form: "/enquiry-form/",
  pending_connection: "auth/pending-connection/",
  search_connections: "/auth/search-connections/",
  create_chat: "/chat/room/",
  chat_connections: "/auth/search-connections/",
  room_details: "/chat/room-detail",
  exit_chat_room: "/chat/exit-room/",
  connect_stripe: "/auth/stripe-connect/",
  ws_chat: "/ws/chat/",
  send_fcm: "/auth/device-token/",
  notifications: "/auth/user-notifications/",
  reset_badge: "/auth/reset-badge-count/",
  readNotification: "/auth/user-notifications/",
  listing_single_booking_details: "/listings/single-booking-details/",
  listing_package_booking_details: "/listings/package-booking-details/",
  commission_amounts: "/auth/commission-amounts/",
  saved_payments: "/listings/get-existing-account-details/",
  signed_url: "/auth/presigned-url",
  id_signed_url: "/auth/listing-presigned-url",
  affiliate: "/affiliate-program/",
  ambassador: "/ambassador/",
  career: "/career-with-fitnessmates/",
  add_interest: "/auth/new-intrest-request/",
  favourite_listing: "/listings/favourite-listing/",
  team_listing: "/listings/team-interest-requests/",
  mark_all_read: "/auth/user-notifications/",
  draft_count: "/listings/draft-listing/",
};
export default apiConfig;
