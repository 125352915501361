import {Skeleton} from "@mui/material";
import useCheckDeviceScreen from "../../../hooks/useCheckScreen";

const PromotionCardShimmer = () => {
  const {isMobile} = useCheckDeviceScreen();

  return (
    <div style={{display: "flex", flexDirection: "column", minHeight: '400px', justifyContent: 'space-between'}}>
      <Skeleton
        animation="wave"
        variant="rounded"
        width={isMobile ? 295 : "100%"}
        height={194}
        sx={{borderRadius: "12px 12px 0px 0px"}}
      />
      <div style={{marginTop: "12px"}}>
        <Skeleton
          animation="wave"
          variant="rounded"
          height={22.5}
        />
      </div>
      <div style={{display: "flex", marginTop: "8px", gap: "10px"}}>
        <Skeleton variant="rounded" animation="wave" width={16} height={16} />
        <Skeleton
          animation="wave"
          variant="rounded"
          width={"100%"}
          height={16.5}
        />
      </div>
      <div style={{marginTop: "10px"}}>
        <Skeleton
          animation="wave"
          variant="rounded"
          height={50}
        />
      </div>
      <div style={{marginTop: "35px"}}>
        <Skeleton
          animation="wave"
          variant="rounded"
          width={isMobile ? 295 : "100%"}
          height={45}
          sx={{borderRadius: "0px 0px 12px 12px"}}
        />
      </div>
    </div>
  );
};

export default PromotionCardShimmer;
