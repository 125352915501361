import React from "react";
import { Modal } from "react-bootstrap";

type Props = {
  [key: string]: any;
};

export default function EarningsModal(props: Props) {
  const { show, handleClose, totalAmount = 0, platformFee = 0, bookingFee= 0, totalEarnings=0 } = props;

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="earnings_modal_layout m-2">
          <div className="d-flex justify-content-between align-items-center">
            <h4 style={{ fontWeight: 600 }} className="mb-0">Earnings composition</h4>
            <img
              src="/static/close.svg"
              alt="Close"
              style={{ cursor: "pointer" }}
              onClick={handleClose}
              className="earnings_modal_close_btn"
            />
          </div>
          <hr className="my-2" style={{ color: "#DDDDDD", opacity: 1}} />
          <div className="d-flex flex-column gap-2 my-3">
            <div className="earnings-amount-wrap">
              <div>Total amount paid</div>
              <div style={{ fontWeight: 600 }}>AUD {totalAmount}</div>
            </div>
            <div className="earnings-amount-wrap">
              <div>Platform Fee</div>
              <div style={{ fontWeight: 600 }}>AUD {platformFee}</div>
            </div>
            <div className="earnings-amount-wrap">
              <div>Merchant Fee</div>
              <div style={{ fontWeight: 600 }}>AUD {bookingFee}</div>
            </div>
          </div>
          <hr className="my-2" style={{ color: "#DDDDDD", opacity: 1}} />
          <div>
            <div className="earnings-amount-wrap">
              <div style={{ fontWeight: 600 }}>Total Earnings</div>
              <div style={{ fontWeight: 700 }}>AUD {totalEarnings}</div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
