import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeDeleteCall } from "../../../utils/Requests";
import apiConfig from "../../../utils/endpoints";
import { showToast } from "../../../utils";
import { ROUTE_CONSTANTS } from "../../../utils/RouteConstants";
import { STRING_CONSTANTS } from "../../../utils/StringConstant";
import PrimaryButton from "../../Button/PrimaryButton";
import SecondaryButton from "../../Button/SecondaryButton";

interface DeleteAccountModalProps {
  onClose?: () => void;
  onSuccess?: any;
}
const DeleteAccountModal = ({ onClose }: DeleteAccountModalProps) => {
  const navigate = useNavigate();
  const [activeLoading, setActiveLoading] = useState(false);

  const deleteAccount = () => {
    setActiveLoading(true);
    makeDeleteCall({ url: apiConfig.delete_account })
      .then((res) => {
        if (res.status.code === 200) {
          showToast(res.status.message, "success");
          localStorage.clear();
          setActiveLoading(false);
          navigate(ROUTE_CONSTANTS.login);
        } else {
          showToast(res.status.message, "error");
          setActiveLoading(false);
        }
      })
      .catch((err) => {
        showToast(err, "error");
        setActiveLoading(false);
      });
  };

  return (
    <div className={"modal show"}>
      <div className="modal-section gap-3">
        <div className="modal-top-part">
          <h5>Are you sure you want to delete your account?</h5>
          <p>
            This action is permanent and cannot be undone. All your data,
            including preferences, saved items, earnings (if applicable), and
            any other information linked to your account, will be permanently
            deleted.
          </p>
        </div>

        <div className="d-flex justify-content-center gap-4">
          <SecondaryButton onClick={onClose} minWidth={"150px"}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={deleteAccount}
            minWidth={"150px"}
            loading={activeLoading}
          >
            Yes
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountModal;
