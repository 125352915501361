import { Modal } from "react-bootstrap";
import Button from "../../../global/Button";
import OutlineButton from "../../../global/OutlineButton";
import Contact from "../../../assets/img/icons/Contact";
import RibbonStar from "../../../assets/img/icons/RibbonStar";
import Time from "../../../assets/img/icons/Time";
import PinnedLocation from "../../../assets/img/icons/PinnedLocation";
import {
  BookingTable,
  BookingTableResponsive,
} from "../../../global/BookingsDetailsTable";
import Community from "../../../assets/img/icons/Community";
import Dismiss from "../../../assets/img/icons/Dismiss";
import moment from "moment";
import { downloadTicket, showToast } from "../../../utils";

const AllBookings = (props) => {
  const { currentBookingData, bookingDetails, setMapCenter, setMapSidebar } =
    props;

  const tableHeader = [
    {
      id: "name",
      label: "Name",
      highlight: true,
    },
    { id: "age", label: "Age" },
    { id: "gender", label: "Gender", align: "center" },
    { id: "email", label: "Email" },
    { id: "contactNumber", label: "Phone Number" },
  ];

  return (
    <Modal {...props} centered dialogClassName="all_bookings_modal">
      <Modal.Body
        style={{ height: "fit-content" }}
        className="all_bookings_modal_body"
      >
        <div
          className="emodal_wrapper mx-auto"
          style={{ height: "fit-content" }}
        >
          <div className="heading_bookings_container">
            <div className="heading_bookings">All booking dates/Attendees</div>
            <div className="dismiss_container" onClick={props?.onHide}>
              <Dismiss />
            </div>
          </div>
          <div className="sub_heading_bookings">
            The event dates with all the events and attendee details
          </div>
          <div className="d-flex justify-content-between gap-3 bookings_stamp">
            <div className=" d-flex align-items-center ">
              <RibbonStar />
              <span className="ml-2 booking_text">
                Booking date:{" "}
                <span className="highlight">
                  {moment(bookingDetails?.bookingDate).format("Do MMMM YYYY")}
                </span>
              </span>
            </div>
            <div>
              {
                bookingDetails?.bookingPaymentStatus === "Succeeded" && 
                  <button
                    className="btn_ticket_download"
                    onClick={() => {
                      if (bookingDetails?.ticketUrl) {
                        downloadTicket(bookingDetails?.ticketUrl);
                      }
                    }}
                  >
                    <img src="/static/booking/bookingTicketDownload.svg" alt="" />
                    <span>Download Ticket</span>
                  </button>
              }
            </div>
          </div>
          <div className="max_modal_container">
            <div className="d-flex flex-column gap-3">
              {bookingDetails?.eventsBookingDetails?.map((item, index) => (
                <div className="booking_sections" key={"section" + index}>
                  <div className="booking_section_header d-none d-md-block ">
                    <div className="booking_date">
                      {moment(item?.eventData?.date).format("ddd, D MMMM") ||
                        null}
                    </div>
                    <div className="booking_time_stamp">
                      <div className="d-flex gap-3 ">
                        <div className="time_container">
                          <div>
                            <Time />
                          </div>
                          {moment(
                            item?.eventData?.startTime,
                            "HH:mm:ss.SSSSSS"
                          ).format("h:mm A")}{" "}
                          -{" "}
                          {moment(
                            item?.eventData?.endTime,
                            "HH:mm:ss.SSSSSS"
                          ).format("h:mm A")}
                        </div>

                        {bookingDetails?.listingEventType === "P" ? (
                          <div className="location_container">
                            <div>
                              <PinnedLocation />
                            </div>
                            <span className="location_container_add">
                              {item?.eventData?.address}
                            </span>
                            {/* Bella Vista */}
                            <span
                              className="location_link"
                              onClick={(e) => {
                                setMapCenter({
                                  lat: Number(item?.eventData?.location?.[1]),
                                  lng: Number(item?.eventData?.location?.[0]),
                                });
                                setMapSidebar(true);
                                props?.onHide();
                              }}
                            >
                              {" "}
                              Open maps
                            </span>
                          </div>
                        ) : (
                          <div className="location_container">
                            <div>
                              <img
                                src="/static/createListingIcon/linkGrey.svg"
                                alt=""
                              />
                            </div>
                            <span>
                              <a
                                target="_blank"
                                style={{
                                  color: "#25272D",
                                }}
                                href={item?.eventData?.virtualLink || ""}
                                rel="noreferrer"
                                className="meet_link_navigate"
                              >
                                Meet Link
                              </a>
                              <span
                                style={{
                                  color: "#0099FF",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    item?.eventData?.virtualLink || ""
                                  );
                                  showToast("copied to clipboard", "success");
                                }}
                              >
                                <img
                                  style={{
                                    marginRight: "4px",
                                    marginLeft: "6px",
                                  }}
                                  src="/static/createListingIcon/copyBlue.svg"
                                  alt=""
                                />
                                <span>Copy</span>
                              </span>
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="booking_attendee">
                        Attendee: {item?.attendees?.length}
                      </div>
                    </div>
                  </div>
                  <div className="booking_section_header d-md-none d-flex justify-content-between gap-1 ">
                    <div>
                      <div className="d-flex gap-3 justify-content-between mb-1">
                        <div className="booking_date">
                          {moment(item?.eventData?.date).format(
                            "ddd, D MMMM"
                          ) || null}
                        </div>
                        <div className="time_container">
                          <div>
                            <Time />
                          </div>
                          {moment(
                            item?.eventData?.startTime,
                            "HH:mm:ss.SSSSSS"
                          ).format("h:mm A")}{" "}
                          -{" "}
                          {moment(
                            item?.eventData?.endTime,
                            "HH:mm:ss.SSSSSS"
                          ).format("h:mm A")}
                        </div>
                      </div>
                      {item?.listingEventType === "P" ? (
                        <div className="booking_time_stamp">
                          <div className="d-flex gap-3 ">
                            <div className="location_container">
                              <div>
                                <PinnedLocation />
                              </div>
                              <span className="location_container_add">
                                {item?.eventData?.address}
                              </span>
                              <span className="location_link"> Open maps</span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="booking_time_stamp">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "14px",
                            }}
                          >
                            <div>
                              <img
                                src="/static/createListingIcon/linkGrey.svg"
                                alt=""
                                style={{ marginRight: "4px" }}
                              />
                            </div>
                            <span>
                              <a
                                target="_blank"
                                style={{
                                  color: "#25272D",
                                }}
                                href={item?.eventData?.virtualLink || ""}
                                rel="noreferrer"
                                className="meet_link_navigate"
                              >
                                Meet Link
                              </a>
                              <span
                                style={{
                                  color: "#0099FF",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    item?.eventData?.virtualLink || ""
                                  );
                                  showToast("copied to clipboard", "success");
                                }}
                              >
                                <img
                                  style={{
                                    marginRight: "4px",
                                    marginLeft: "6px",
                                  }}
                                  src="/static/createListingIcon/copyBlue.svg"
                                  alt=""
                                />
                                <span>Copy</span>
                              </span>
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="booking_attendee ">
                      <Community />{" "}
                      <span className="ml-1">{item?.attendees?.length}</span>
                    </div>
                  </div>
                  <div className="booking_section_footer">
                    <div className="d-none d-md-block">
                      <BookingTable
                        columns={tableHeader}
                        rows={item?.attendees}
                      />
                    </div>
                    <div className="d-md-none d-block">
                      <div className="booking_attendee_heading">
                        Attendee Details
                      </div>
                      <BookingTableResponsive
                        rows={item?.attendees?.map((item) => {
                          return {
                            name: `${item.name}`,
                            age: `${item.age}Yr`,
                            gender: `${item.gender}`,
                            email: `${item.email}`,
                            phone: `${
                              item.contactNumber ? item.contactNumber : "--"
                            }`,
                          };
                        })}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* <div>
            <div className="button_wrapper_modal">
              <Button btnTxt="Done" onClick={props?.onHide} />
            </div>
          </div> */}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default AllBookings;
