import {Skeleton} from "@mui/material";
import useCheckDeviceScreen from "../../../hooks/useCheckScreen";

const BookingCardShimmer = () => {
  const {isMobile} = useCheckDeviceScreen();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: isMobile ? "0px 20px" : "0px 45px",
      }}
    >
      <Skeleton
        animation="wave"
        variant="rectangular"
        width={120}
        height={20}
        style={{ marginTop: "16px" }}
      />
      <div style={{ marginTop: "16px" }}>
        <Skeleton
          animation="wave"
          variant="rounded"
          // width={}
          height={120}
        />
      </div>
      <div style={{ marginTop: "16px" }}>
        <Skeleton
          animation="wave"
          variant="rounded"
          // width={}
          height={120}
        />
      </div>
    </div>
  );
};

export default BookingCardShimmer;
