import { LISTING_TYPE } from "../../../types";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { formatAmount, getItemFromStore } from "../../../utils";
const Vector2 = require("../../../assets/img/Physical.svg").default;

const EarningCard = ({ item }) => {
  const { title, photos, createdAt, listingType, earnings = 0, id } = item;
  const [media = ""] = photos;
  const navigate = useNavigate();
  return (
    <div className="myearning-card-wrap">
      <div className="left">
        {/\.(mp4|mov|avi)$/.test(media?.uploadDocument?.toLowerCase()) ||
        media?.documentFileType?.startsWith("video") ? (
          <>
            <video
              style={{
                borderRadius: "100%",
                cursor: "pointer",
                width: "53px",
                height: "53px",
                objectFit: "cover",
                verticalAlign: "middle",
              }}
              src={media?.uploadDocument || ""}
              autoPlay
              playsInline
              loop
              muted
              id={`booking_video${id}`}
            >
              <source src={media} />
            </video>
          </>
        ) : (
          <img
            src={media?.uploadDocument || "/static/expertise2.png"}
            className="user-media"
            alt=""
          />
        )}
        <div>
          <div className="title">{title}</div>
          <div className="date">
            <span>Created:</span>
            <span style={{ color: "#6C6D6F" }}>
              {moment(createdAt)?.format("ll")}{" "}
            </span>
          </div>
          <div
            style={{
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "16px",
              textAlign: "left",
            }}
          >
            {item?.listingEventType === "V" ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "4px",
                }}
              >
                <span
                  style={{
                    color: "#6C6D6F",
                    marginRight: "4px",
                  }}
                >
                  {"Event Type: "}
                </span>

                <span style={{ color: "#0099FF", marginRight: "4px" }}>
                  {" "}
                  Virtual{" "}
                </span>
                <img
                  src="/static/Internet.svg"
                  alt="Internet Icon"
                  style={{ width: "16px" }}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "4px",
                }}
              >
                <span
                  style={{
                    color: "#6C6D6F",
                    marginRight: "4px",
                  }}
                >
                  {"Event Type: "}
                </span>
                <span
                  className="event-type-orange"
                  style={{ color: "#ee762f", marginRight: "4px" }}
                >
                  Physical
                </span>
                <img
                  src={Vector2}
                  alt="Internet Icon"
                  style={{ width: "16px" }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="right">
        {listingType === LISTING_TYPE.M ? (
          <img alt="" src="/static/package34.svg" />
        ) : (
          <img alt="" src="/static/session34.svg" />
        )}
        {/* <div className="list-chip">
          {listingType === LISTING_TYPE.M ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <path
                d="M6.16074 4.69658L8.1145 3.91507L3.27633 1.9798L1.60826 2.64703C1.52264 2.68128 1.44389 2.72687 1.37346 2.78167L6.16074 4.69658ZM1.02285 3.33619C1.00784 3.40399 1 3.47407 1 3.54545V8.47769C1 8.87336 1.24089 9.22916 1.60826 9.37611L5.32213 10.8617C5.48939 10.9286 5.66266 10.9747 5.8382 11V5.26233L1.02285 3.33619ZM6.48328 11C6.65876 10.9746 6.83196 10.9285 6.99917 10.8617L10.713 9.37611C11.0804 9.22916 11.3213 8.87336 11.3213 8.47769V3.54545C11.3213 3.47409 11.3135 3.40403 11.2985 3.33626L6.48328 5.26233V11ZM10.9479 2.78172L8.98297 3.56769L4.1448 1.63242L5.32213 1.16148C5.86041 0.946172 6.46089 0.946172 6.99917 1.16148L10.713 2.64703C10.7987 2.68129 10.8775 2.7269 10.9479 2.78172Z"
                fill="url(#paint0_linear_3359_67361)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_3359_67361"
                  x1="-0.879005"
                  y1="-0.486967"
                  x2="3.41921"
                  y2="18.8339"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#EE7830" />
                  <stop offset="1" stop-color="#EE762F" />
                </linearGradient>
              </defs>
            </svg>
          ) : listingType === LISTING_TYPE.C ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <path
                d="M4.82143 4.61111C5.10165 4.2718 5.52557 4.05556 6 4.05556C6.47443 4.05556 6.89835 4.2718 7.17857 4.61111C7.39672 4.87526 7.52778 5.21399 7.52778 5.58333C7.52778 6.41407 6.86474 7.08994 6.03899 7.11062C6.02603 7.11095 6.01303 7.11111 6 7.11111C5.98697 7.11111 5.97397 7.11095 5.96101 7.11062C5.13526 7.08994 4.47222 6.41407 4.47222 5.58333C4.47222 5.21399 4.60328 4.87526 4.82143 4.61111ZM8.08333 5.58333C8.08333 5.2322 7.99646 4.90135 7.84304 4.61111H10.1667C10.6269 4.61111 11 4.98421 11 5.44444V5.72222C11 6.60412 10.3302 7.5066 9.15841 7.82472C8.92111 7.39904 8.4664 7.11111 7.94444 7.11111H7.41641C7.82662 6.73063 8.08333 6.18696 8.08333 5.58333ZM7.94444 7.66667C8.18446 7.66667 8.40078 7.76814 8.55284 7.93052C8.69236 8.07951 8.77778 8.27978 8.77778 8.5V8.77778C8.77778 9.87302 7.74472 11 6 11C4.25528 11 3.22222 9.87302 3.22222 8.77778V8.5C3.22222 8.27978 3.30764 8.07951 3.44716 7.93052C3.59922 7.76814 3.81554 7.66667 4.05556 7.66667H7.94444ZM1 5.72222C1 6.60412 1.6698 7.5066 2.84159 7.82472C3.07889 7.39904 3.5336 7.11111 4.05556 7.11111H4.58359C4.17338 6.73063 3.91667 6.18696 3.91667 5.58333C3.91667 5.2322 4.00354 4.90135 4.15696 4.61111H1.83333C1.3731 4.61111 1 4.98421 1 5.44444V5.72222ZM5.30556 2.52778C5.30556 1.68401 4.62155 1 3.77778 1C2.93401 1 2.25 1.68401 2.25 2.52778C2.25 3.37155 2.93401 4.05556 3.77778 4.05556C4.62155 4.05556 5.30556 3.37155 5.30556 2.52778ZM9.75 2.52778C9.75 1.68401 9.06599 1 8.22222 1C7.37845 1 6.69444 1.68401 6.69444 2.52778C6.69444 3.37155 7.37845 4.05556 8.22222 4.05556C9.06599 4.05556 9.75 3.37155 9.75 2.52778Z"
                fill="url(#paint0_linear_3359_67303)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_3359_67303"
                  x1="-0.820513"
                  y1="-0.486967"
                  x2="3.60218"
                  y2="18.7746"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#EE7830" />
                  <stop offset="1" stop-color="#EE762F" />
                </linearGradient>
              </defs>
            </svg>
          ) : listingType === LISTING_TYPE.S ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="11"
              viewBox="0 0 12 11"
              fill="none"
            >
              <path
                d="M5.10203 0.400326C5.36546 -0.133444 6.1266 -0.13344 6.39003 0.400326L7.77639 3.2094L10.8764 3.65986C11.4654 3.74545 11.7006 4.46934 11.2744 4.88481L9.03122 7.07137L9.56076 10.1588C9.66139 10.7455 9.04561 11.1929 8.51875 10.9159L5.74603 9.45821L2.97331 10.9159C2.44645 11.1929 1.83068 10.7455 1.9313 10.1588L2.46084 7.07137L0.21766 4.88481C-0.20858 4.46933 0.0266273 3.74545 0.615674 3.65986L3.71567 3.2094L5.10203 0.400326Z"
                fill="url(#paint0_linear_3359_67848)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_3359_67848"
                  x1="-2.09214"
                  y1="-1.63566"
                  x2="2.58414"
                  y2="19.6414"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#EE7830" />
                  <stop offset="1" stop-color="#EE762F" />
                </linearGradient>
              </defs>
            </svg>
          ) : null}

          {listingType === LISTING_TYPE.M
            ? "Package"
            : listingType === LISTING_TYPE.S
            ? "Session"
            : listingType === LISTING_TYPE.C
            ? "Team"
            : null}
        </div> */}
          <div
            className="earning-btn"
            onClick={() => navigate(`/earn-booking/${id}`)}
          >
            Earning: AUD {earnings ? formatAmount(earnings) : 0}
          </div>
      </div>
    </div>
  );
};

export default EarningCard;
