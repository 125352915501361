import React from "react";
import moment from "moment";
import { LISTING_TYPE, PAYOUT_METHOD, SCHEDULE_TYPE, SESSION_END_TYPE, SESSION_SCHEDULE, USER_TYPE } from "../types";
import { MESSAGE, STRING_CONSTANTS } from "../utils/StringConstant";
import { ACTIVITY_TYPE, MAX_PARTICIPANTS, PRICING_TYPE, VIRTUAL_LINK_REGEX, apiConstants } from "../utils/Constants";
import { DATE_FORMAT, formatDatePayload, formatDateResponse } from "./helperScheduleDates";

export const initialScheduleData = {
  scheduleType: SCHEDULE_TYPE.O, // O/R
  recurringDetails: {
    recurringType: "customday", // customday/recurring
    customDaysDetails: {
      customRecurDates: "",
      startTime: "",
      endTime: "",
    },
    repeatDetails: {
      repeatEvery: 1,
      frequencyType: "", // "Day", "Week", "Month"
      weekFrequency: "", // for week type
      monthFrequency: "",
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
    },
  },
  oneTimeDetails: {
    eventDate: "",
    startTime: "",
    endTime: "",
  },
  virtualLinksMultiple: [],
};

export const handleShowScheduleDate = (sessionScheduleData) => {

  if (
    sessionScheduleData?.eventType === "O" &&
    sessionScheduleData?.eventDate?.length > 0
  ) {
    return (
      <>
        <div>
          {`One-time Event: ${sessionScheduleData?.eventDate?.length} date(s) selected`}
        </div>
        <small>
          {`Total Events : ${sessionScheduleData?.payloadSchedule?.length}`}
        </small>
        
      </>
    );
  } else if (
    sessionScheduleData?.eventType === "R" &&
    sessionScheduleData?.eventDate?.length > 0
  ) {

    if (
      sessionScheduleData?.eventType === "R" &&
      sessionScheduleData?.eventDate?.length > 1
    ){
      return (
        <>
          <div>
            {`Recurring Every Month Event: ${sessionScheduleData?.eventDate?.length} date(s) selected`}
          </div>

          {sessionScheduleData?.endEventType === "end-after" ? (
            <small>
              {`End After : ${sessionScheduleData?.endAfterVal} occurrence(s)`}
            </small>
          ) : (
            <small>{`End By : ${moment(
              sessionScheduleData?.endByVal,
              DATE_FORMAT.DATE_MONTH_FORMAT
            ).format(DATE_FORMAT.DATE_MONTH_FORMAT)}`}</small>
          )}
          <br />
          <small>
            {`Total Events : ${sessionScheduleData?.payloadSchedule?.length}`}
          </small>
        </>
      );
    }else if (
      sessionScheduleData?.eventType === "R" &&
      sessionScheduleData?.eventDate?.length < 2
    ) {
      return (
        <>
          <div>
            {`Recurring Event: ${sessionScheduleData?.repeatType} type selected`}
          </div>
          {sessionScheduleData?.endEventType === "end-after" ? (
            <small>
              {`End After : ${sessionScheduleData?.endAfterVal} occurrence(s)`}
            </small>
          ) : (
            <small>{`End By : ${moment(
              sessionScheduleData?.endByVal,
              DATE_FORMAT.DATE_MONTH_FORMAT
            ).format(DATE_FORMAT.DATE_MONTH_FORMAT)}`}</small>
          )}
          <br />
          <small>
            {`Total Events : ${sessionScheduleData?.payloadSchedule?.length}`}
          </small>
        </>
      );
    }
      
  }else {
     return (
       <div style={{ color: "#8F939A" }}>
         {STRING_CONSTANTS.schedule_date_placeholder}
       </div>
     );
  }


   
};

const getDateFormat = (date) => {
  return date;
};

// reset schedule data

const customTimeDisplayData = (time) => {
  const datetime = time;
  const isoDatetime = datetime.replace(" ", "T"); // "2024-10-26T09:00 AM"

  // Now parse with moment using custom format
  const formattedTime = moment(isoDatetime, "YYYY-MM-DDThh:mm A");

  return formattedTime;
};


export const resetScheduleData = (scheduleData, handleSetValue) => {
  handleSetValue(
    apiConstants.schedule_type,
    scheduleData?.type || SCHEDULE_TYPE.O
  );
  if (
    scheduleData?.type === SCHEDULE_TYPE.O &&
    scheduleData?.oneTimeDate &&
    scheduleData?.startTime &&
    scheduleData?.endTime
  ) {
    handleSetValue(apiConstants.event_date, scheduleData?.oneTimeDate);
    handleSetValue(
      apiConstants.event_start_time,
      customTimeDisplayData(scheduleData?.startTime)
    );
    handleSetValue(
      apiConstants.event_end_time,
      customTimeDisplayData(scheduleData?.endTime)
    );
  } else {
    handleSetValue(
      apiConstants.recurring_type,
      scheduleData?.recurringType || "customday"
    );

    if (
      scheduleData?.recurringType === "customday" &&
      scheduleData?.startTime &&
      scheduleData?.endTime &&
      scheduleData?.customDates?.length
    ) {
      handleSetValue(apiConstants.event_custom_date, scheduleData?.customDates);
      handleSetValue(
        apiConstants.event_start_time,
        customTimeDisplayData(scheduleData?.startTime)
      );
      handleSetValue(
        apiConstants.event_end_time,
        customTimeDisplayData(scheduleData?.endTime)
      );
    } else if (scheduleData?.recurringType === "recurring") {
      handleSetValue(
        apiConstants.repeat_every,
        scheduleData?.repeatNumber || 1
      );

      handleSetValue(
        apiConstants.recur_frequency,
        scheduleData?.recurringRepeatType
      );

      if (scheduleData?.recurringRepeatType === "Week") {
        handleSetValue(
          apiConstants.selected_days,
          scheduleData?.weekDays || []
        );
      }
      if (scheduleData?.recurringRepeatType === "Month") {
        handleSetValue(
          apiConstants.monthly_frequency,
          scheduleData?.monthlyFrequencyData
        );
      }

      handleSetValue(
        apiConstants.event_start_date,
        getDateFormat(scheduleData?.startDate)
      );
      handleSetValue(
        apiConstants.event_end_date,
        getDateFormat(scheduleData?.endDate)
      );

      handleSetValue(
        apiConstants.event_start_time,
        customTimeDisplayData(scheduleData?.startTime)
      );
      handleSetValue(
        apiConstants.event_end_time,
        customTimeDisplayData(scheduleData?.endTime)
      );
    }
  }
};

export const timeDayjsFormatInner = (time) => moment(time, "YYYY-MM-DD hh:mm A");
export const timeDayjsFormatApi = (time) =>
  moment(time, "YYYY-MM-DD hh:mm A").format("HH:mm:ss.SSSSSS");

export const timeDayjsFormatApiReverse = (date, time) => {
  if (!date || !time) {
    return null;
  } else {
    const combinedDateTime = moment(
      `${date} ${time}`,
      "YYYY-MM-DD HH:mm:ss.SSSSSS"
    );
    return combinedDateTime.format("YYYY-MM-DD hh:mm A");
  }
};

export const dateDayjsFormat = (time) => moment(time).format("YYYY-MM-DD");
export const timeDayjsFormat = (time) => moment(time).format("YYYY-MM-DD hh:mm A");

export const compareDayjsTime = (start, end) => {
  const startTime = moment(start);
  const endTime = moment(end);
  return endTime.isAfter(startTime);
};

export const compareDayjsDate = (start, end) => {
  const startTime = moment(start, "YYYY-MM-DD").format("YYYY-MM-DD hh:mm A");
  const endTime = moment(end, "YYYY-MM-DD").format("YYYY-MM-DD hh:mm A");
  const startTime2 = timeDayjsFormatInner(startTime);
  const endTime2 = timeDayjsFormatInner(endTime);
  return endTime2.isAfter(startTime2);
};

export function generateEventObject(watch, getValues, virtual) {
  let eventObject:any={};
  eventObject = {
    id: new Date().getTime(),
    title: watch(apiConstants?.event_title),
    date: moment(
      getValues(apiConstants.event_date),
      DATE_FORMAT.DATE_MONTH_FORMAT
    ).format(DATE_FORMAT.YYYYMMDD_FORMAT),
    start_time:
      moment(watch(apiConstants.event_start_time)).format(
        DATE_FORMAT.TZ_TIME
      ) || null,
    end_time:
      moment(watch(apiConstants.event_end_time)).format(DATE_FORMAT.TZ_TIME) ||
      null,
  };

  if (virtual) {
    eventObject.virtual_links_package = watch(
      apiConstants?.virtual_links_package
    );

  } else {
    eventObject.address = {
      location:
        watch(apiConstants?.listing_location) ||
        `POINT (${watch(apiConstants.lng)} ${watch(apiConstants.lat)})`,
      address: watch(apiConstants?.address),
      city: watch(apiConstants?.city),
      state: watch(apiConstants?.state),
      country: watch(apiConstants?.country),
      post_code: watch(apiConstants?.postCode),
    };
  }

  return eventObject;
}


export const validateMeetingLink = (link) => {
  return VIRTUAL_LINK_REGEX.test(link);
};


export function getValidations({
  watch,
  locationData,
  sessionScheduleData,
  currUserInfo,
}) {
  return [
    {
      condition: () => watch(apiConstants.media).length < 1,
      message: MESSAGE.MEDIA_REQUIRED,
    },
    {
      condition: () => !watch(apiConstants.listing_name),
      message: MESSAGE.LISTING_NAME_REQUIRED,
    },
    {
      condition: () => watch(apiConstants.listing_name)?.length < 3,
      message: MESSAGE.LISTING_NAME_MIN_LENGTH,
    },
    {
      condition: () => !watch(apiConstants.listing_description),
      message: MESSAGE.DESCRIPTION_REQUIRED,
    },
    {
      condition: () => watch(apiConstants.listing_description)?.length < 10,
      message: MESSAGE.DESCRIPTION_MIN_LENGTH,
    },
    {
      condition: () =>
        !watch(apiConstants.selectedInterest) ||
        watch(apiConstants.selectedInterest)?.length < 1,
      message: MESSAGE.INTEREST_REQUIRED,
    },
    {
      condition: () =>
        watch(apiConstants.listing_event_type) === ACTIVITY_TYPE.VIRTUAL &&
        watch(apiConstants.multiple_virtual_links_allowed) === true &&
        !watch(apiConstants.virtual_links_single),
      message: MESSAGE.VIRTUAL_LINK_REQUIRED,
    },
    {
      condition: () =>
        watch(apiConstants.listing_event_type) === ACTIVITY_TYPE.VIRTUAL &&
        watch(apiConstants.multiple_virtual_links_allowed) === true &&
        watch(apiConstants.virtual_links_single) &&
        !VIRTUAL_LINK_REGEX.test(watch(apiConstants.virtual_links_single)),
      message: MESSAGE.INVALID_VIRTUAL_LINK,
    },
    {
      condition: () =>
        watch(apiConstants.listing_event_type) === ACTIVITY_TYPE.PHYSICAL &&
        watch(apiConstants.listingType) !== LISTING_TYPE.M &&
        !watch(apiConstants.address),
      message: MESSAGE.LOCATION_REQUIRED,
    },
    {
      condition: () =>
        watch(apiConstants.listing_event_type) === ACTIVITY_TYPE.PHYSICAL &&
        watch(apiConstants.listingType) !== LISTING_TYPE.M &&
        watch(apiConstants.address) !== locationData,
      message: MESSAGE.LOCATION_FROM_SUGGESTION,
    },
    {
      condition: () =>
        watch(apiConstants.listing_event_type) === ACTIVITY_TYPE.PHYSICAL &&
        watch(apiConstants.listingType) === LISTING_TYPE.M &&
        !watch(apiConstants.multipleEvents)?.every(
          (event) =>
            event.hasOwnProperty(apiConstants.address) &&
            event?.address
        ),
      message: MESSAGE.LOCATION_PHYSICAL,
    },
    {
      condition: () =>
        watch(apiConstants.listing_event_type) === ACTIVITY_TYPE.VIRTUAL &&
        watch(apiConstants.multiple_virtual_links_allowed) === false &&
        watch(apiConstants.listingType) === LISTING_TYPE.M &&
        !watch(apiConstants.multipleEvents)?.every(
          (event) =>
            event.hasOwnProperty("virtual_links_package") &&
            event.virtual_links_package &&
            event.virtual_links_package.trim() !== ""
        ),
      message: MESSAGE.ALL_MEET_LINK_PHYSICAL,
    },
    {
      condition: () =>
        !watch(apiConstants.max_participants) ||
        watch(apiConstants.max_participants) < 1,
      message: MESSAGE.PARTICIPANTS_REQUIRED,
    },
    {
      condition: () =>
        watch(apiConstants.listing_event_type) === ACTIVITY_TYPE.PHYSICAL &&
        watch(apiConstants.max_participants) > MAX_PARTICIPANTS.PHYSICAL,
      message: MESSAGE.MAX_PHYSICAL_PARTICIPANTS,
    },
    {
      condition: () =>
        watch(apiConstants.listing_event_type) === ACTIVITY_TYPE.VIRTUAL &&
        watch(apiConstants.max_participants) > MAX_PARTICIPANTS.VIRTUAL,
      message: MESSAGE.MAX_VIRTUAL_PARTICIPANTS,
    },
    {
      condition: () =>
        watch(apiConstants.max_participants) > 4 &&
        currUserInfo?.userType === USER_TYPE.GUEST,
      message: MESSAGE.MAX_GUEST_PARTICIPANTS,
    },
    {
      condition: () =>
        (!watch(apiConstants.listing_cost) ||
          watch(apiConstants.listing_cost) < 6) &&
        watch(apiConstants.listing_price_type) === PRICING_TYPE.PAID &&
        currUserInfo?.userType !== USER_TYPE.GUEST &&
        watch(apiConstants.listingType) !== LISTING_TYPE.C,
      message: MESSAGE.MINIMUM_COST,
    },
    {
      condition: () =>
        watch(apiConstants.multipleEvents)?.length < 2 &&
        watch(apiConstants.listingType) === LISTING_TYPE.M,
      message: MESSAGE.MULTIPLE_EVENTS_REQUIRED,
    },
    {
      condition: () =>
        !sessionScheduleData?.payloadSchedule?.length &&
        watch(apiConstants.listingType) === LISTING_TYPE.S,
      message: MESSAGE.SCHEDULE_DATES_REQUIRED,
    },
    {
      condition: () =>
        watch(apiConstants.listingType) === LISTING_TYPE.S &&
        watch(apiConstants.listing_event_type) === ACTIVITY_TYPE.VIRTUAL &&
        watch(apiConstants.multiple_virtual_links_allowed) === false &&
        !sessionScheduleData?.payloadSchedule[0]?.virtual_link,
      message: MESSAGE.WEB_URLS_REQUIRED,
    },
    {
      condition: () =>
        !watch(apiConstants.payment_mode) &&
        watch(apiConstants.listing_price_type) === PRICING_TYPE.PAID &&
        currUserInfo?.userType !== USER_TYPE.GUEST &&
        watch(apiConstants.listingType) !== LISTING_TYPE.C,
      message: MESSAGE.PAYMENT_METHOD_REQUIRED,
    },
    {
      condition: () =>
        watch(apiConstants.isCancellationAllowed) &&
        !watch(apiConstants.cancellation_allowed_till_hours) &&
        watch(apiConstants.listingType) !== LISTING_TYPE.C,
      message: MESSAGE.CANCELLATION_RANGE_REQUIRED,
    },
    {
      condition: () =>
        watch(apiConstants.is_specific_age) &&
        (!watch(apiConstants.min_age) || !watch(apiConstants.max_age)),
      message: MESSAGE.AGE_RANGE_REQUIRED,
    },
    {
      condition: () =>
        !watch(apiConstants.selectedGender) ||
        watch(apiConstants.selectedGender)?.length < 1,
      message: MESSAGE.GENDER_REQUIRED,
    },
    {
      condition: () =>
        !watch(apiConstants.selecteddifficulty) ||
        watch(apiConstants.selecteddifficulty)?.length < 1,
      message: MESSAGE.DIFFICULTY_LEVEL_REQUIRED,
    },
  ];
}


export function validateDataForDisableCase(
  getValues,
  watch,
  sessionScheduleData,
  currUserInfo,
): boolean {
  const listingAll: any = getValues();
  const { title, description } = listingAll;

  const hasTitle = !!title?.trim();
  const hasDescription = !!description?.trim();
  const hasSpecialInstructions = !!watch(
    apiConstants.selectedInstruction
  )?.some((val: { instruction: string }) => val?.instruction?.trim());
  const hasMedia = !!watch(apiConstants.media)?.length;
  const hasSchedule =
    sessionScheduleData?.payloadSchedule?.length > 0 &&
    watch(apiConstants.listingType) === LISTING_TYPE.S;
  const hasAgeDetails =
    watch(apiConstants.is_specific_age) &&
    watch(apiConstants.min_age) &&
    watch(apiConstants.max_age);
  const hasPaymentMode =
    watch(apiConstants.payment_mode) &&
    currUserInfo?.userType !== USER_TYPE.GUEST;
  const hasParticipants = !!watch(apiConstants.max_participants);
  const hasListingCost =
    watch(apiConstants.listing_cost) &&
    currUserInfo?.userType !== USER_TYPE.GUEST &&
    watch(apiConstants.listingType) !== LISTING_TYPE.C;
  const hasSelectedInterest = !!watch(apiConstants.selectedInterest)?.length;
  const hasPackageEvents = !!watch(apiConstants.multipleEvents)?.length;

  const isDisable = !(
    hasTitle ||
    hasDescription ||
    hasSpecialInstructions ||
    hasMedia ||
    hasSchedule ||
    hasAgeDetails ||
    hasPaymentMode ||
    hasParticipants ||
    hasListingCost ||
    hasSelectedInterest ||
    hasPackageEvents
  );

  return isDisable;
}

export const mapDraftResponseToTemp = (
  data: any = {},
  scheduleDetails: any = {},
  address: any,
  userType: any,
  setSessionScheduleData: any = ()=> null
) => {
  const temp = {};

  const coordinates = address?.location
    ?.match(/-?\d+\.\d+/g)
    ?.map(parseFloat) || ["-25.363", "131.044"];
  const [lng, lat] = coordinates;

  temp[apiConstants.listing_event_type] =
    data?.listingEventType || ACTIVITY_TYPE.VIRTUAL;

  if (data.listingType) {
    temp[apiConstants.listingType] = data.listingType;
  }
  temp[apiConstants.listingDetails] = {
    type: data.listingType,
    id: data?.id,
    singleListingType: scheduleDetails.singleListingType,
    hasDraft: data.isDraft,
  };
  temp[apiConstants.listing_name] = data?.title;
  temp[apiConstants.listing_description] = data?.description;
  temp[apiConstants.selectedInterest] = data?.interests;

  if (data?.specialInstruction?.length > 0) {
    temp[apiConstants.selectedInstruction] = data?.specialInstruction?.map(
      (instruction) => ({ instruction: instruction || "" })
    );
  } else {
    temp[apiConstants.selectedInstruction] = [
      {
        instruction: "",
      },
    ];
  }
  temp[apiConstants.listing_location] = address?.location;

  // payment bank details
  temp[apiConstants.payment_mode] = data?.paymentMode;
  temp[apiConstants.stripe_account_id] = data?.stripeConnectBank;
  temp[apiConstants.existing_or_new] = data?.existingOrNew;
  temp[apiConstants.bank_name] = data?.directBankAccountName;
  temp[apiConstants.bank_name] = data?.directBankAccountName;
  temp[apiConstants.bsb_number] = data?.directBankBsbNumber;
  temp[apiConstants.account_number] = data?.directBankAccountNumber;

  temp[apiConstants.max_participants] = data.maxParticipants;

  temp[apiConstants.listing_cost] =
    data?.listingType !== LISTING_TYPE.C && userType !== USER_TYPE.GUEST
      ? data?.price
      : data?.clubScheduleDetails?.monthlyCost;
  
  temp[apiConstants.listing_price_type] =
  data?.listingType !== LISTING_TYPE.C && userType !== USER_TYPE.GUEST &&
  data?.price > 0 ? PRICING_TYPE.PAID : PRICING_TYPE.FREE;

  if (data?.listingType === LISTING_TYPE.M) {
    temp[apiConstants.multipleEvents] = data?.scheduleDetails?.map(
      ({ title, date, endTime, startTime, virtualLink, address, ...others }) => {
        return {
          title,
          date,
          end_time: endTime,
          start_time: startTime,
          virtual_links_package: virtualLink,
          address: address,
        };
      }
    );
  }
  // address and location
  temp[apiConstants.lat] = lat;
  temp[apiConstants.lng] = lng;
  temp[apiConstants.city] = address?.city;
  temp[apiConstants.state] = address?.state;
  temp[apiConstants.country] = address?.country;
  temp[apiConstants.postCode] = address?.postCode;
  temp[apiConstants.listing_location] = address?.location;
  temp[apiConstants.address] = address?.address;
  // gender details
  const userGender = data?.gender;
  const DEFAULT_GENDER_ARRAY = ["M", "F", "N"];
  if (userGender) {
    let length = userGender.length;
    temp[apiConstants.gender_preference] =
      length > 0 ? userGender : DEFAULT_GENDER_ARRAY;
    temp[apiConstants.selectedGender] =
      length > 0 ? userGender : DEFAULT_GENDER_ARRAY;
  }
  // event dificulty details
  temp[apiConstants.difficulty_level] = data.difficultyLevel || [];
  temp[apiConstants.selecteddifficulty] = data.difficultyLevel || [];
  // media images or videos details
  temp[apiConstants.upload_image] = data?.media || [];
  temp[apiConstants.image] = data?.media || [];
  // age details
  temp[apiConstants.min_age] = data?.minAge;
  temp[apiConstants.max_age] = data?.maxAge;
  temp[apiConstants.is_specific_age] =
    data?.minAge || data?.maxAge ? true : false;
  // cancellation details
  temp[apiConstants.isCancellationAllowed] = data?.isCancellationAllowed;
  temp[apiConstants.cancellation_allowed_till_hours] =
    data?.cancellationAllowedTillHours;

  // virtual link
  temp[apiConstants.multiple_virtual_links_allowed] =
    !data?.multipleVirtualLinksAllowed;

  // common virtual link
  if (data?.listingType === LISTING_TYPE.S) {
    temp[apiConstants.virtual_links_single] =
      data?.scheduleDetails?.virtualLink || "";
  } else if (data?.listingType === LISTING_TYPE.M) {
    temp[apiConstants.virtual_links_single] =
      data?.scheduleDetails?.length > 0
        ? data?.scheduleDetails[0]?.virtualLink
        : "";
  } else {
    temp[apiConstants.virtual_links_single] =
      data?.teamVirtualLink || "";
  }

  // extract session schedule dates key
  if (data?.listingType === LISTING_TYPE.S) {
    const {
      eventDates = [],
      events = [],
      singleListingType,
      endType = "",
      endAfter = 1,
      endBy = null,
      recurringType = "Day",
      repeatInEvery = 1,
      weekDays = [],
      monthWeekNo = [],
      monthWeekDays = [],
    } = scheduleDetails;

    if (eventDates.length > 0) {
      temp[apiConstants.schedule_type] = SCHEDULE_TYPE.O;

      const formattedEvents = events.map((event) => ({
        date: moment(event.date, DATE_FORMAT.YYYYMMDD_FORMAT).format(
          DATE_FORMAT.YYYYMMDD_FORMAT
        ),
        start_time: event.startTime,
        end_time: event.endTime,
        ...(data.listingEventType === ACTIVITY_TYPE.VIRTUAL &&
          data.multipleVirtualLinksAllowed === true &&
          event?.virtualLink && { virtual_link: event.virtualLink }),
      }));

      const convertedDates = formatDateResponse(eventDates);

      setSessionScheduleData({
        eventDate: convertedDates || [],
        eventType: singleListingType || SCHEDULE_TYPE.O,
        repeatInEvery: repeatInEvery || 1,
        repeatType: recurringType || "Day",
        weekDays: weekDays || [],
        monthOnEvery: monthWeekNo || [],
        monthOnDays: monthWeekDays || [],
        endEventType: endType || "",
        endAfterVal: endType === SESSION_END_TYPE.END_AFTER ? endAfter : null,
        endByVal:
          endType === SESSION_END_TYPE.END_BY_DATE
            ? moment(endBy, DATE_FORMAT.YYYYMMDD_FORMAT).format(
                DATE_FORMAT.DATE_MONTH_FORMAT
              )
            : "",
        timeSlotsData: [],
        finalScheduleData: formattedEvents || null,
        payloadSchedule: formattedEvents || null,
        respSchedule: [],
      });
    }
    temp[apiConstants.isDraft] = false;
  }

  if (data.listingType !== "C" && data.multipleVirtualLinksAllowed === true) {
    const virtual_links = scheduleDetails.virtualLinks?.map((event) => {
      return {
        date: event.date,
        start: event.date + " " + scheduleDetails.startTime,
        end: event.date + " " + scheduleDetails.endTime,
        virtual_link: event.virtualLink,
      };
    });

    temp[apiConstants.virtual_links_multiple] = virtual_links || [];
  }

  return temp;
};


// payload data for create listing
export function generateListingPayload({
  draft = false,
  getValues,
  watch,
  mediaUploadPayload,
  sessionScheduleData,
  userType,
}: {
  draft: boolean;
  getValues: (key: string) => any;
  watch: (key: string) => any;
  mediaUploadPayload?: any[];
  sessionScheduleData?: any;
  userType: string;
}) {
  let reqData: any = {};
  let addressObject: any = {};
  let scheduleDetails: any = {};

  // common payload
  const listingType = getValues(apiConstants.listingType);
  const listingPresenceType = watch(apiConstants.listing_event_type);

  reqData.isActive = !draft;
  reqData.listingType = listingType;
  reqData.isPrivate = false;
  reqData.isDraft = draft;
  reqData.title = watch(apiConstants.listing_name);
  reqData.description = watch(apiConstants.description);
  reqData.interests = watch(apiConstants.interests);

  if (watch(apiConstants.selectedInstruction)?.length > 0) {
    reqData.special_instruction = watch(apiConstants.selectedInstruction)
      ?.filter((val: any) => val?.instruction?.trim())
      ?.map((val: any) => val.instruction.trim());
  }

  if (watch(apiConstants.selectedGender)?.length) {
    reqData.gender = getValues(apiConstants.selectedGender);
  }

  if (watch(apiConstants.selecteddifficulty)?.length) {
    reqData.difficultyLevel = watch(apiConstants.selecteddifficulty)?.map(
      ([key]: any) => key
    );
  }

  reqData.is_age_limit = watch(apiConstants.is_specific_age) || false;
  if (reqData.is_age_limit) {
    reqData.minAge = parseInt(watch(apiConstants.min_age));
    reqData.maxAge = parseInt(watch(apiConstants.max_age));
  }

  if (listingPresenceType) {
    reqData.listing_event_type = listingPresenceType;
  }

  // media files
  reqData.document_details = mediaUploadPayload?.map(
    ({ tempId, ...rest }) => rest
  );

  // payout method
  const paidPricing = getValues(apiConstants.listing_price_type) === PRICING_TYPE.PAID;
  const paymentMode = getValues(apiConstants.payment_mode);
  const existingOrNew = getValues(apiConstants.existing_or_new);
  if (paymentMode && listingType !== LISTING_TYPE.C && paidPricing) {
    reqData.payment_mode = paymentMode;

    if (paymentMode === PAYOUT_METHOD.STRIPE) {
      const stripeAccountId = getValues(apiConstants.stripe_account_id);
      if (stripeAccountId) {
        reqData.stripe_connect_bank = stripeAccountId;
        if (existingOrNew) {
          reqData.existing_or_new = existingOrNew;
        }
      }
    } else if (paymentMode === PAYOUT_METHOD.BANK) {
      reqData = {
        ...reqData,
        direct_bank_account_name: getValues(apiConstants.bank_name),
        direct_bank_bsb_number: getValues(apiConstants.bsb_number),
        direct_bank_account_number: getValues(apiConstants.account_number),
        existing_or_new: existingOrNew || null,
      };
    }
  }

  reqData.is_cancellation_allowed = getValues(
    apiConstants.isCancellationAllowed
  );
  if (reqData.is_cancellation_allowed) {
    reqData.cancellation_allowed_till_hours = getValues(
      apiConstants.cancellation_allowed_till_hours
    );
  }

  // address
  if (
    listingPresenceType === ACTIVITY_TYPE.PHYSICAL &&
    listingType !== LISTING_TYPE.M
  ) {
    const lat = watch(apiConstants.lat);
    const lng = watch(apiConstants.lng);
    addressObject = {
      city: watch(apiConstants.city) || "",
      state: watch(apiConstants.state) || "",
      country: watch(apiConstants.country) || "",
      postCode: watch(apiConstants.postCode) || "",
      address: watch(apiConstants.address) || "",
      location: lat && lng ? `POINT (${lng} ${lat})` : undefined,
    };
    scheduleDetails.address = addressObject;
  }


  if (
    userType !== USER_TYPE.GUEST &&
    listingType !== LISTING_TYPE.C
  ) {
    reqData.price = paidPricing ? getValues(apiConstants.listing_cost): 0;
  }

  if (watch(apiConstants.max_participants)) {
    reqData.max_participants = getValues(apiConstants.max_participants);
  }

  if ([LISTING_TYPE.S, LISTING_TYPE.SPECIAL].includes(listingType)) {
    // session schedule logic
    const {
      eventType,
      eventDate,
      payloadSchedule,
      repeatType,
      repeatInEvery,
      endEventType,
      endAfterVal,
      endByVal,
      monthOnEvery,
      monthOnDays,
      weekDays,
    } = sessionScheduleData || {};

    const isVirtual =
      watch(apiConstants.listing_event_type) === ACTIVITY_TYPE.VIRTUAL;
    const allowMultipleLinks = watch(
      apiConstants.multiple_virtual_links_allowed
    );

    if (eventType && eventDate?.length > 0) {
      scheduleDetails.single_listing_type = eventType;
      scheduleDetails.event_dates = formatDatePayload(eventDate);

      if (eventType === SESSION_SCHEDULE.RECURRING) {
        scheduleDetails.repeat_in_every = repeatInEvery;
        scheduleDetails.end_type = endEventType;

        if (endEventType === SESSION_END_TYPE.END_AFTER) {
          scheduleDetails.end_after = endAfterVal;
        } else if (endEventType === SESSION_END_TYPE.END_BY_DATE) {
          scheduleDetails.end_by = moment(
            endByVal,
            DATE_FORMAT.DATE_MONTH_FORMAT
          ).format(DATE_FORMAT.YYYYMMDD_FORMAT);
        }
      }

      const events = payloadSchedule?.map(
        ({ date = "", start_time = "", end_time = "", virtual_link }: any) => ({
          date,
          start_time,
          end_time,
          virtual_link: isVirtual
            ? allowMultipleLinks
              ? getValues(apiConstants.virtual_links_single)
              : virtual_link
            : undefined,
        })
      );

      scheduleDetails.events = events;

      if (eventType === SESSION_SCHEDULE.RECURRING) {
        scheduleDetails.recurring_type = repeatType;

        if (repeatType === SESSION_SCHEDULE.R_MONTH && eventDate.length < 2) {
          scheduleDetails.month_week_no = monthOnEvery;
          scheduleDetails.month_week_days = monthOnDays;
        } else if (repeatType === SESSION_SCHEDULE.R_WEEK) {
          scheduleDetails.week_days = weekDays;
          scheduleDetails.end_by = moment(
            endByVal,
            DATE_FORMAT.DATE_MONTH_FORMAT
          ).format(DATE_FORMAT.YYYYMMDD_FORMAT);
        }
      }
    }
    reqData.schedule_details = scheduleDetails;

    if (listingPresenceType === ACTIVITY_TYPE.VIRTUAL) {
      const multipleLinksAllowed = watch(
        apiConstants.multiple_virtual_links_allowed
      );
      const virtualLink = getValues(apiConstants.virtual_links_single);

      if (multipleLinksAllowed) {
        reqData.multiple_virtual_links_allowed = false;
        reqData.schedule_details.virtual_link = virtualLink;
      } else {
        reqData.multiple_virtual_links_allowed = true;
      }
    }
  } else if (listingType === LISTING_TYPE.M) {
    const multipleLinksAllowed = watch(
      apiConstants.multiple_virtual_links_allowed
    );
    const virtualLink = getValues(apiConstants.virtual_links_single);

    const physicalEvents = getValues(apiConstants.multipleEvents)?.map(
      ({
        title,
        date,
        end_time: endTime,
        start_time: startTime,
        virtual_links_package,
        address,
        ...others
      }) =>
        listingPresenceType === ACTIVITY_TYPE.VIRTUAL
          ? {
              title,
              date,
              end_time: endTime,
              start_time: startTime,
              virtual_link: multipleLinksAllowed
                ? virtualLink
                : virtual_links_package,
            }
          : { title, date, end_time: endTime, start_time: startTime, address }
    );

    if (physicalEvents?.length === 0) {
      reqData.schedule_details = [{ virtual_link: virtualLink }];
    } else {
      reqData.schedule_details = physicalEvents;
    }

    if (multipleLinksAllowed) {
      reqData.multiple_virtual_links_allowed = false;
    } else {
      reqData.multiple_virtual_links_allowed = true;
    }
  } else if (listingType === LISTING_TYPE.C) {
    const virtualLink = getValues(apiConstants.virtual_links_single);
    reqData.schedule_details = scheduleDetails;
    reqData.multiple_virtual_links_allowed = false;
    reqData.team_virtual_link = virtualLink;
  }

  return reqData;
}