import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";

export default function PhoneNumberInput({
  parentClass = "",
  label = "",
  name = "",
  watch = () => {},
  setValue = {},
  setCountryCode = {},
  errors = {},
}) {
  const handleChange = (phone, country) => {
    setValue(name, phone, { shouldDirty: true });
    setCountryCode(country);
  };

  return (
    <div className={parentClass} style={{marginBottom:"8px"}}>
      <PhoneInput
        name
        country={"au"}
        value={watch(name) || null}
        onChange={(phone, country) => handleChange(phone, country)}
        placeholder="Enter contact number"
        specialLabel={label}
        autoFormat={false}
      />
      {errors && errors[name] && (
        <span className="text-danger">{errors[name]?.message}</span>
      )}
    </div>
  );
}
