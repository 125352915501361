import React, { useMemo } from "react";
import { Box } from "@mui/material";
import { LISTING_TYPE } from "../../../types";
import { HEADINGS } from "../../../utils/StringConstant";
import { apiConstants } from "../../../utils/Constants";

const ListingHeading = ({
  watch,
  handleMainBack,
  handleSaveAsDraft,
  published = false,
  resetListing,
}) => {
  const headingContent = useMemo(() => {
    return (
      <>
        <img
          src="/static/leftarrow.svg"
          alt=""
          className="back_arrow"
          style={{ zIndex: 1 }}
          onClick={handleMainBack}
        />
        {!published && (
          <div className="d-flex justify-content-between d-md-none mb-1">
            <div
              className="mb-1 position-absolute pe-3"
              style={{ cursor: "pointer", right: "0px", zIndex: 1 }}
            >
              <img
                src="/static/saveResDraft.png"
                alt=""
                onClick={handleSaveAsDraft}
              />
            </div>
          </div>
        )}
        <img
          src="/static/leftarrow.svg"
          alt=""
          className="back_arrow d-none d-md-block"
          onClick={handleMainBack}
        />
        <div className="d-flex justify-content-end">
          <Box
            component="span"
            sx={{
              cursor: "pointer",
              color: "red",
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            mr={{ xs: 5, md: 0 }}
            onClick={resetListing}
          >
            Reset Listing
          </Box>
        </div>
        <div className="listing_heading_main">
          {watch(apiConstants.listingType) === LISTING_TYPE.M
            ? HEADINGS.PACKAGE
            : watch(apiConstants.listingType) === LISTING_TYPE.C
            ? HEADINGS.TEAM
            : HEADINGS.SESSION}
        </div>
      </>
    );
  }, [watch(apiConstants.listingType)]); // Dependencies for useMemo

  return headingContent;
};

export default ListingHeading;
