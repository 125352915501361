import {Skeleton} from "@mui/material";
import useCheckDeviceScreen from "../../../hooks/useCheckScreen";

const EventShimmer = () => {
  const {isMobile} = useCheckDeviceScreen();

  return (
    <div style={{display: "flex", flexDirection: "column"}}>
      <Skeleton
        animation="wave"
        variant="rounded"
        width={isMobile ? 350 : 304}
        height={320}
        sx={{borderRadius: "12px"}}
      />
      <div style={{marginTop: "16px"}}>
        <Skeleton
          animation="wave"
          variant="rounded"
          width={180}
          height={22.5}
        />
      </div>
      <div style={{display: "flex", marginTop: "8px", gap: "10px"}}>
        <Skeleton variant="circular" animation="wave" width={16} height={16} />
        <Skeleton
          animation="wave"
          variant="rounded"
          width={250}
          height={16.5}
        />
      </div>
      <div style={{display: "flex", marginTop: "8px", gap: "10px"}}>
        <Skeleton variant="circular" animation="wave" width={16} height={16} />
        <Skeleton
          animation="wave"
          variant="rounded"
          width={250}
          height={16.5}
        />
      </div>
      <div style={{marginTop: "14px"}}>
        <Skeleton
          animation="wave"
          variant="rounded"
          width={isMobile ? 350 : 304}
          height={30}
          sx={{borderRadius: "24px"}}
        />
      </div>
    </div>
  );
};

export default EventShimmer;
