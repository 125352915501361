import React, { useMemo } from "react";
import { ACTIVITY_TYPE, apiConstants } from "../../../utils/Constants";
import { LISTING_TYPE } from "../../../types";
import MeetingLink from "../MeetingLink/MeetingLink";

interface FieldListingTypeProps {
  watch?: (field: string) => any;
  handleSetValue?: (field: string, value: any) => void;
  control?: any;
  setValue?: (field: string, value: any) => void;
}

const ListingTypeForm: React.FC<FieldListingTypeProps> = React.memo(
  ({ watch, handleSetValue, control, setValue }) => {
    const eventListingType = watch(apiConstants.listing_event_type);
    const listingType = watch(apiConstants.listingType);
    const listingEventType = watch(apiConstants.listing_event_type);

    const listingTypeContent = useMemo(() => {
      return (
        <>
          <div className="listing_type_radio">
            <img
              src={
                eventListingType === ACTIVITY_TYPE.PHYSICAL
                  ? "/static/radiocheck.svg"
                  : "/static/radiouncheck.svg"
              }
              alt=""
              onClick={() =>
                handleSetValue(
                  apiConstants.listing_event_type,
                  ACTIVITY_TYPE.PHYSICAL
                )
              }
            />
            <label
              className={
                eventListingType === ACTIVITY_TYPE.PHYSICAL
                  ? "listing_ype_label active"
                  : "listing_ype_label"
              }
              onClick={() =>
                handleSetValue(
                  apiConstants.listing_event_type,
                  ACTIVITY_TYPE.PHYSICAL
                )
              }
              style={{ cursor: "pointer" }}
            >
              Physical
            </label>
            <img
              src={"/static/createListingIcon/physicalListingType.svg"}
              alt=""
            />
          </div>
          <div className="listing_type_radio mt-1">
            <img
              src={
                eventListingType === ACTIVITY_TYPE.VIRTUAL
                  ? "/static/radiocheck.svg"
                  : "/static/radiouncheck.svg"
              }
              alt=""
              onClick={() =>
                handleSetValue(
                  apiConstants.listing_event_type,
                  ACTIVITY_TYPE.VIRTUAL
                )
              }
            />
            <label
              className={
                eventListingType === ACTIVITY_TYPE.VIRTUAL
                  ? "listing_ype_label active"
                  : "listing_ype_label"
              }
              onClick={() =>
                handleSetValue(
                  apiConstants.listing_event_type,
                  ACTIVITY_TYPE.VIRTUAL
                )
              }
              style={{ cursor: "pointer" }}
            >
              Virtual
            </label>
            <img
              src={"/static/createListingIcon/virtualListingType.svg"}
              alt=""
            />
          </div>
        </>
      );
    }, [eventListingType, handleSetValue]);

    return (
      <>
        <div className="listing_type_container">
          <div className="listing_type_heading">Listing Type</div>
          <div>{listingTypeContent}</div>
        </div>
        {listingEventType === ACTIVITY_TYPE.VIRTUAL && (
          <MeetingLink
            watch={watch}
            handleSetValue={handleSetValue}
            control={control}
            errors={{}}
            setValue={setValue}
            isTeam={listingType === LISTING_TYPE.C}
          />
        )}
      </>
    );
  }
);

export default ListingTypeForm;
