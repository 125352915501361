import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../store/store";

type Props = {
  [key: string]: any;
};

const StickyBookingButtonMobile = ({
  detailsData = {},
  handleBookingError,
  isTeamListingFull = false,
}: Props) => {
  const auth = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  const getEventTypeDetails = (type) => {
    switch (type) {
      case "P":
        return "/Package";
      case "S" || "SPECIAL":
        return "/Per Class";
      case "C":
        return null;

      default:
        break;
    }
  };

  return (
    <div
      style={{
        borderRadius: "0px",
        width: "100%",
        maxHeight: "355px",
        position: "fixed",
        zIndex: 5,
        left: "0px",
        padding: "22px 20px",
        maxWidth: "100%",
        backgroundColor: "#FEF7F3",
        boxShadow: "0px 4px 50px 0px #0000003D",
        border: "none",
        bottom: "0px",
      }}
    >
      <>
        <div
          style={{
            display: "flex",
            gap: "14px",
            flexDirection: "row",
          }}
        >
          {detailsData?.listingType !== "C" && (
            <div
              style={{
                width: "fit-content",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div>
                {detailsData?.price && (
                  <div
                    style={{
                      display: "inline",
                      width: "fit-content",
                      fontFamily: "Poppins",
                      fontSize: "24px",
                      fontWeight: 500,
                      lineHeight: "28px",
                      color: "#25272D",
                    }}
                  >
                    {Number(detailsData?.price) === 0 ? (
                      "Free"
                    ) : (
                      <>
                        AUD {detailsData?.price || 0}
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "24px",
                          }}
                        >
                          {getEventTypeDetails(detailsData?.listingType)}
                        </span>
                      </>
                    )}
                  </div>
                )}
              </div>
              {detailsData?.events?.length > 1 &&
                detailsData?.listingType === "P" && (
                  <div
                    style={{
                      width: "100%",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "20px",
                      color: "#EE7830",
                    }}
                  >
                    {detailsData?.events?.length} Sessions Included
                  </div>
                )}
            </div>
          )}
          <div
            style={{
              flexGrow: 1,
            }}
          >
            <div
              style={{
                width: "100%",
              }}
            >
              {detailsData?.createdBy?.id === auth?.moreUserDetails?.id ? (
                <button
                  className={"backtologin"}
                  onClick={() => navigate(`/earn-booking/${detailsData?.id}`)}
                  style={{
                    width: "100%",
                    margin: 0,
                    padding: "16px",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    backgroundColor: "#EE7830",
                    border: "1px solid #EE7830",
                    borderRadius: "12px",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  See all bookings
                </button>
              ) : detailsData?.listingType !== "C" ? (
                <button
                  onClick={handleBookingError}
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    width: "100%",
                    margin: 0,
                    padding: "16px",
                    backgroundColor: "#EE7830",
                    border: "1px solid #EE7830",
                    borderRadius: "12px",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  {detailsData?.isListingBookedByMe
                    ? "Reserve more events"
                    : "Book Now"}
                </button>
              ) : (
                <button
                  onClick={handleBookingError}
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    width: "100%",
                    margin: 0,
                    padding: "16px 20px",
                    backgroundColor: "#EE7830",
                    border: "1px solid #EE7830",
                    borderRadius: "12px",
                    color: "white",
                    textAlign: "center",
                  }}
                  disabled={isTeamListingFull}
                >
                  {!isTeamListingFull ? " Register Interest" : "Booking full"}
                </button>
              )}
            </div>
          </div>
        </div>
        {detailsData?.listingType !== "C" && (
          <div
            style={{
              marginTop: "12px",
            }}
          >
            <div
              style={{
                marginBottom: "8px",
                width: "100%",
                height: "4px",
                background: "#FFFFFF",
              }}
            />
            <div>
              <div
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  color: "#25272D",
                  width: "100%",
                }}
              >
                Cancellation Policy
              </div>
              <div
                style={{
                  display: "inline",
                }}
              >
                {detailsData?.isCancellationAllowed === true ? (
                  <div
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "24px",
                      color: "#25272D",
                      width: "100%",
                      display: "inline",
                    }}
                  >
                    You can cancel your booking up until {"{"}
                    {detailsData?.cancellationAllowedTillHours}
                    {"}"}hours of your event{" "}
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        lineHeight: "24px",
                        cursor: "pointer",
                        fontWeight: 600,
                        color: "#EE7830",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        window.open("/policies", "_new");
                      }}
                    >
                      Click here
                    </span>{" "}
                    to know more
                  </div>
                ) : (
                  <div
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "24px",
                      color: "#25272D",
                      width: "100%",
                      display: "inline",
                    }}
                  >
                    No cancellation allowed after booking
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default StickyBookingButtonMobile;
